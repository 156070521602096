import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { CubejsClientModule } from "@cubejs-client/ngx";
import { MatCardModule } from "@angular/material/card";


import { AppComponent } from "./app.component";
import { ChartsModule } from "ng2-charts";
import { BarChartComponent } from "./bar-chart/bar-chart.component";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { LayoutModule } from "@angular/cdk/layout";
import { DashboardPageComponent } from "./dashboard-page/dashboard-page.component";
import { KpiCardComponent } from "./kpi-card/kpi-card.component";
import { CountUpModule } from "ngx-countup";
import { DoughnutChartComponent } from "./doughnut-chart/doughnut-chart.component";
import { AppRoutingModule } from "./app-routing.module";
import { MatListModule } from "@angular/material/list";
import { TablePageComponent } from "./table-page/table-page.component";
import { MaterialTableComponent } from "./material-table/material-table.component";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TableFiltersComponent } from "./table-filters/table-filters.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import {MatSliderModule} from "@angular/material/slider";
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { environment } from 'src/environments/environment';
import { LineChartComponent } from './line-chart/line-chart.component';
import { ActivityComponent } from './activity/activity.component';
import { MaterialTableActivityComponent } from './material-table-activity/material-table-activity.component';
import { MaterialTableActivityIdentifierComponent } from './material-table-activity-identifier/material-table-activity-identifier.component';
import { MaterialTableActivitySourceComponent } from './material-table-activity-source/material-table-activity-source.component';
import { MaterialTableActivityTriggerComponent } from './material-table-activity-trigger/material-table-activity-trigger.component';
import { MaterialTableActivityTriggerConditionComponent } from './material-table-activity-trigger-condition/material-table-activity-trigger-condition.component';
import { CallAlertsComponent } from './call-alerts/call-alerts.component';
import { CallAlertsPriorityComponent } from './call-alerts-priority/call-alerts-priority.component';
import { CallAlertsIdentifierComponent } from './call-alerts-identifier/call-alerts-identifier.component';
import { CallAlertsIdentifierTypeComponent } from './call-alerts-identifier-type/call-alerts-identifier-type.component';
import { CallAlertsActionreasonComponent } from './call-alerts-actionreason/call-alerts-actionreason.component';
import { CallAlertsResolvedbyuseremailComponent } from './call-alerts-resolvedbyuseremail/call-alerts-resolvedbyuseremail.component';
import { CallAlertsSourceComponent } from './call-alerts-source/call-alerts-source.component';
import { CallAlertsResolvedreasonComponent } from './call-alerts-resolvedreason/call-alerts-resolvedreason.component';
import { CallAlertsTypeComponent } from './call-alerts-type/call-alerts-type.component';
import { CallAlertsPieChartComponent } from './call-alerts-pie-chart/call-alerts-pie-chart.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { VehiclesMakeChartComponent } from './vehicles-make-chart/vehicles-make-chart.component';
import { VehiclesFuelTypeComponent } from './vehicles-fuel-type/vehicles-fuel-type.component';
import { VehiclesFuelTypeTaxStatusComponent } from './vehicles-fuel-type-tax-status/vehicles-fuel-type-tax-status.component';
import { VehiclesFuelTypeMotComponent } from './vehicles-fuel-type-mot/vehicles-fuel-type-mot.component';
import { VehiclesTypeComponent } from './vehicles-type/vehicles-type.component';
import { VehiclesManufacturerComponent } from './vehicles-manufacturer/vehicles-manufacturer.component';
import { VehiclesEuroStatusComponent } from './vehicles-euro-status/vehicles-euro-status.component';
import { VehiclesTrafficFlowComponent } from './vehicles-traffic-flow/vehicles-traffic-flow.component';
import { VehiclesColourComponent } from './vehicles-colour/vehicles-colour.component';
import { PartnersComponent } from './partners/partners.component';
import { PartnersAlertsComponent } from './partners-alerts/partners-alerts.component';
import { PartnersAlertsExitComponent } from './partners-alerts-exit/partners-alerts-exit.component';
import { PartnersVehiclesActivityComponent } from './partners-vehicles-activity/partners-vehicles-activity.component';
import { TasksSiteDropdownComponent } from './tasks-site-dropdown/tasks-site-dropdown.component';
import { ActivityDeviceComponent } from './activity-device/activity-device.component';
import { ActivityVehiclesComponent } from './activity-vehicles/activity-vehicles.component';
import { ActivityPeopleComponent } from './activity-people/activity-people.component';
import { DevicesComponent } from './devices/devices.component';
import { DevicesManufacturerComponent } from './devices-manufacturer/devices-manufacturer.component';
import { PeopleComponent } from './people/people.component';
import { PeopleMessagingComponent } from './people-messaging/people-messaging.component';
import { BespokeComponent } from './bespoke/bespoke.component';
import { InsightsComponent } from './insights/insights.component';
import { InsightVehicleComponent } from './insight-vehicle/insight-vehicle.component';
import { InsightsDashboardComponent } from './insights-dashboard/insights-dashboard.component';
import { CallAlertsCardsComponent } from './call-alerts-cards/call-alerts-cards.component';
import { GraphsComponent } from './graphs/graphs.component';
import { GraphsChartComponent } from './graphs-chart/graphs-chart.component';
import { ActivityMobileComponent } from './activity-mobile/activity-mobile.component';
import { AlertsMobileComponent } from './alerts-mobile/alerts-mobile.component';
import { AlertCardComponent } from './alert-card/alert-card.component';
import { AlertsCardMobileComponent } from './alerts-card-mobile/alerts-card-mobile.component';
import { InsightMobileComponent } from './insight-mobile/insight-mobile.component';
import { VehiclesMobileComponent } from './vehicles-mobile/vehicles-mobile.component';
import { DevicesMobileComponent } from './devices-mobile/devices-mobile.component';
import { PeopleMobileComponent } from './people-mobile/people-mobile.component';
import { BespokeMobileComponent } from './bespoke-mobile/bespoke-mobile.component';
import { VolumnFuelComponent } from './volumn-fuel/volumn-fuel.component';
import { EuroFuelTypeComponent } from './euro-fuel-type/euro-fuel-type.component';
import { EuroFuelTypeDoughnutComponent } from './euro-fuel-type-doughnut/euro-fuel-type-doughnut.component';
import { EuroFuelType6Component } from './euro-fuel-type6/euro-fuel-type6.component';
import { EuroFuelType6DoughnutComponent } from './euro-fuel-type4-doughnut/euro-fuel-type4-doughnut.component';
import { EuroFuelPercentComponent } from './euro-fuel-percent/euro-fuel-percent.component';
import { EuroFuelTypeListComponent } from './euro-fuel-type-list/euro-fuel-type-list.component';
import { EuroFuelEuro4CountComponent } from './euro-fuel-euro4-count/euro-fuel-euro4-count.component';
import { EuroFuelEuro5PercentComponent } from './euro-fuel-euro5-percent/euro-fuel-euro5-percent.component';
import { EuroFuelEuro5CountComponent } from './euro-fuel-euro5-count/euro-fuel-euro5-count.component';
import { EuroFuelEuro6PercentComponent } from './euro-fuel-euro6-percent/euro-fuel-euro6-percent.component';
import { EuroFuelEuro6CountComponent } from './euro-fuel-euro6-count/euro-fuel-euro6-count.component';
import { VehiclesPercentageComponent } from './vehicles-percentage/vehicles-percentage.component';
import { VehiclesCountComponent } from './vehicles-count/vehicles-count.component';
import { VehiclesMobileGraphComponent } from './vehicles-mobile-graph/vehicles-mobile-graph.component';
import { FuelTypeHeaderComponent } from './fuel-type-header/fuel-type-header.component';
import { EuroTypeMobileComponent } from './euro-type-mobile/euro-type-mobile.component';
import { EuroTypeMobileCardsComponent } from './euro-type-mobile-cards/euro-type-mobile-cards.component';
import { VahiclesMakeMobileGraphComponent } from './vahicles-make-mobile-graph/vahicles-make-mobile-graph.component';
import { BespokeFuelComponent } from './bespoke-fuel/bespoke-fuel.component';
import { BespokeWebComponent } from './bespoke-web/bespoke-web.component';
import { RevokeComponent } from './revoke/revoke.component';
import { CountingComponent } from './counting/counting.component';
import { GlobalStatsComponent } from './global-stats/global-stats.component';

import { HttpClientModule } from '@angular/common/http';
const API_URL = environment.apiUrl;
const API_TOKEN = environment.apiToken;

const cubejsOptions = {
  token: API_TOKEN,
  options: { apiUrl: API_URL }
};

@NgModule({
  declarations: [
    AppComponent,
    BarChartComponent,
    DashboardPageComponent,
    KpiCardComponent,
    DoughnutChartComponent,
    TablePageComponent,
    MaterialTableComponent,
    TableFiltersComponent,
    UserAvatarComponent,
    LineChartComponent,
    ActivityComponent,
    MaterialTableActivityComponent,
    MaterialTableActivityIdentifierComponent,
    MaterialTableActivitySourceComponent,
    MaterialTableActivityTriggerComponent,
    MaterialTableActivityTriggerConditionComponent,
    CallAlertsComponent,
    CallAlertsPriorityComponent,
    CallAlertsIdentifierComponent,
    CallAlertsIdentifierTypeComponent,
    CallAlertsActionreasonComponent,
    CallAlertsResolvedbyuseremailComponent,
    CallAlertsSourceComponent,
    CallAlertsResolvedreasonComponent,
    CallAlertsTypeComponent,
    CallAlertsPieChartComponent,
    VehiclesComponent,
    VehiclesMakeChartComponent,
    VehiclesFuelTypeComponent,
    VehiclesFuelTypeTaxStatusComponent,
    VehiclesFuelTypeMotComponent,
    VehiclesTypeComponent,
    VehiclesManufacturerComponent,
    VehiclesEuroStatusComponent,
    VehiclesTrafficFlowComponent,
    VehiclesColourComponent,
    PartnersComponent,
    PartnersAlertsComponent,
    PartnersAlertsExitComponent,
    PartnersVehiclesActivityComponent,
    TasksSiteDropdownComponent,
    ActivityDeviceComponent,
    ActivityVehiclesComponent,
    ActivityPeopleComponent,
    DevicesComponent,
    DevicesManufacturerComponent,
    PeopleComponent,
    PeopleMessagingComponent,
    BespokeComponent,
    InsightsComponent,
    InsightVehicleComponent,
    InsightsDashboardComponent,
    CallAlertsCardsComponent,
    GraphsComponent,
    GraphsChartComponent,
    ActivityMobileComponent,
    AlertsMobileComponent,
    AlertCardComponent,
    AlertsCardMobileComponent,
    InsightMobileComponent,
    VehiclesMobileComponent,
    DevicesMobileComponent,
    PeopleMobileComponent,
    BespokeMobileComponent,
    VolumnFuelComponent,
    EuroFuelTypeComponent,
    EuroFuelTypeDoughnutComponent,
    EuroFuelType6Component,
    EuroFuelType6DoughnutComponent,
    EuroFuelPercentComponent,
    EuroFuelTypeListComponent,
    EuroFuelEuro4CountComponent,
    EuroFuelEuro5PercentComponent,
    EuroFuelEuro5CountComponent,
    EuroFuelEuro6PercentComponent,
    EuroFuelEuro6CountComponent,
    VehiclesPercentageComponent,
    VehiclesCountComponent,
    VehiclesMobileGraphComponent,
    FuelTypeHeaderComponent,
    EuroTypeMobileComponent,
    EuroTypeMobileCardsComponent,
    VahiclesMakeMobileGraphComponent,
    BespokeFuelComponent,
    BespokeWebComponent,
    RevokeComponent,
    CountingComponent,
    GlobalStatsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ChartsModule,
    CubejsClientModule.forRoot(cubejsOptions),
    MatCardModule,
    MatGridListModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    CountUpModule,
    MatProgressBarModule,
    AppRoutingModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatSliderModule,
    HttpClientModule
  ],
  providers: [MatDatepickerModule],
  bootstrap: [AppComponent]
})
export class AppModule {
}
