
<mat-grid-list [cols]="6" rowHeight="90">
    <div class="col-12">
        <div class="insights-cards-wrapper">
          <div class="row narrow-row">
    <div>
        <mat-grid-tile class="other-cards">
            <div class="kpi-card" style="width: 100%;padding-right: 15px;">
                <mat-card class="dashboard-card not-normal-card" style="background-color: #0f1b30;height: 90px;border-radius: 5px;">
                    <mat-card-header class="dashboard-card__header" style="display:block;text-align: center;">
                    <mat-card-title>
                        <h5 style="color: #FFF;font-weight: 100;">Total Volume</h5>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result" style="text-align: center;">
                    <h3 style="font-size: 39px;margin-top: 15px;font-weight: 100;">{{totalVolumn}}</h3>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
        <mat-grid-tile class="other-cards">
            <div class="kpi-card" style="width: 100%;padding-right: 15px;">
                <mat-card class="dashboard-card not-normal-card" style="background-color: #0f1b30;height: 90px;border-radius: 5px;">
                    <mat-card-header class="dashboard-card__header" style="display:block;text-align: center;">
                    <mat-card-title>
                        <h5 style="color: #FFF;font-weight: 100;">Petrol</h5>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result" style="text-align: center;">
                    <h3 style="font-size: 39px;margin-top: 15px;font-weight: 100;">
                        {{euro4Total}}
                    </h3>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
        <mat-grid-tile class="other-cards">
            <div class="kpi-card" style="width: 100%;padding-right: 15px;">
                <mat-card class="dashboard-card not-normal-card" style="background-color: #0f1b30;height: 90px;border-radius: 5px;">
                    <mat-card-header class="dashboard-card__header" style="display:block;text-align: center;">
                    <mat-card-title>
                        <h5 style="color: #FFF;font-weight: 100;">Diesel</h5>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result" style="text-align: center;">
                    <h3 style="font-size: 39px;margin-top: 15px;font-weight: 100;">
                        {{euro6Total}}
                    </h3>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
        
        <mat-grid-tile class="other-cards">
            <div class="kpi-card" style="width: 100%;padding-right: 15px;">
                <mat-card class="dashboard-card not-normal-card" style="background-color: #0f1b30;height: 90px;border-radius: 5px;">
                    <mat-card-header class="dashboard-card__header" style="display:block;text-align: center;">
                    <mat-card-title>
                        <h5 style="color: #FFF;font-weight: 100;">Electric</h5>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result" style="text-align: center;">
                    <h3 style="font-size: 39px;margin-top: 15px;font-weight: 100;">{{elctCount}}</h3>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
        <mat-grid-tile class="other-cards">
            <div class="kpi-card" style="width: 100%;padding-right: 15px;">
                <mat-card class="dashboard-card not-normal-card" style="background-color: #0f1b30;height: 90px;border-radius: 5px;">
                    <mat-card-header class="dashboard-card__header" style="display:block;text-align: center;">
                    <mat-card-title>
                        <h5 style="color: #FFF;font-weight: 100;">Hybrid</h5>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result" style="text-align: center;">
                    <h3 style="font-size: 39px;margin-top: 15px;font-weight: 100;">{{hybridCount}}</h3>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
        <mat-grid-tile class="other-cards">
            <div class="kpi-card" style="width: 100%;padding-right: 15px;">
                <mat-card class="dashboard-card not-normal-card" style="background-color: #0f1b30;height: 90px;border-radius: 5px;">
                    <mat-card-header class="dashboard-card__header" style="display:block;text-align: center;">
                    <mat-card-title>
                        <h5 style="color: #FFF;font-weight: 100;">Gas BI-FUEL</h5>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result" style="text-align: center;">
                    <h3 style="font-size: 39px;margin-top: 15px;font-weight: 100;">{{GasCount}}</h3>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
    </div>
</div>
</div>
</div>
</mat-grid-list>
