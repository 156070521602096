import { Component, Input, Output } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';


@Component({
  selector: 'app-vehicles-traffic-flow',
  templateUrl: './vehicles-traffic-flow.component.html',
  styleUrls: ['./vehicles-traffic-flow.component.scss']
})
export class VehiclesTrafficFlowComponent{
  constructor(private cubejs: CubejsClient) {}
  @Input() set query(query: object) {
    this.loading = true;
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = resultSet.tablePivot();
        for( var i=0; i<this.dataSource.length;i++ ){
          var ndata = this.dataSource[i];
          if( ndata['History.triggercondition'] == null ){
            this.dataSource[i]['History.triggercondition'] = "Other/Unknown";
          }
          if( ndata['History.triggercondition'] == "" ){
            this.dataSource[i]['History.triggercondition'] = "Other/Unknown1";
          }
        }

        this.dataSource.forEach((element,index)=>{
          if( element['History.triggercondition']=="Other/Unknown1" ){
            this.count = element['History.count'] + this.count;
            this.dataSource.splice(index,1)
          }
          if( element['History.triggercondition']=="Other/Unknown" ){
            this.count = element['History.count'] + this.count;
            element['History.count'] = this.count;
          }
       });
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
  };
  @Input() limit: number;
  @Output() pageEvent = new EventEmitter();
  @Output() sortingChanged = new EventEmitter();
  loading = true;
  length = 0;
  count = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25];
  dataSource = [];
  displayedColumns = ['Site', 'Count'];
  changeSorting(value) {
    this.sortingChanged.emit(value)
  }
}
