<div class="component-header d-flex align-items-center justify-content-between">
  <h4><i class="fas fa-car"></i>Vehicle Dashboard</h4>
  <div class="date-Picker-wrap d-flex align-items-center ">
    <select class="date-Picker" (change)="selectOption($event.target.value)">
      <option [value]="date.id" *ngFor="let date of dates">{{date.name}}</option>
    </select>
    <select class="date-Picker" (change)="selectOption1($event.target.value)">
      <option value="All">All</option>
      <option value="{{element['Layers.name']}}" [ngValue]="siteSelected" *ngFor="let element of dataSource">{{element['Layers.name']}}</option>
    </select>
  </div>
</div>

<div class="table-warp">
  <div class="row narrow-row">
    <div class="col-6">

      <app-vehicles-traffic-flow [query]="queryVehicelsTrafficFlow"
      (sortingChanged)="sortingChangedVehicelsTrafficFlow($event)"></app-vehicles-traffic-flow>
      <app-vehicles-colour [query]="queryVehicelsColour"
                          (sortingChanged)="sortingChangedVehicelsColour($event)"></app-vehicles-colour>
      <app-vehicles-fuel-type [query]="queryVehicelsFuelType" (sortingChanged)="sortingChangedVehicelsFuelType($event)"></app-vehicles-fuel-type>
      <app-vehicles-euro-status [query]="queryVehicelsEuroStatus"
                          (sortingChanged)="sortingChangedVehicelsEuroStatus($event)"></app-vehicles-euro-status>
      <app-vehicles-fuel-type-tax-status [query]="queryVehicelsFuelTypeTaxStatus" 
                          (sortingChanged)="sortingChangedVehicelsFuelTypeTaxStatus($event)"></app-vehicles-fuel-type-tax-status>
      <app-vehicles-fuel-type-mot [query]="queryVehicelsFuelTypeMot" 
                          (sortingChanged)="sortingChangedVehicelsFuelTypeMot($event)"></app-vehicles-fuel-type-mot>
    </div>
    <div class="col-6">
        <app-vehicles-type [query]="queryVehicelsType"
                            (sortingChanged)="sortingChangedVehicelsType($event)"></app-vehicles-type>
        <app-vehicles-manufacturer [query]="queryVehicelsManufacturer"
                          (sortingChanged)="sortingChangedVehicelsManufacturer($event)"></app-vehicles-manufacturer>
    </div> 
  </div>
</div>
