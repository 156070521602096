import { Component, Input, Output } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-fuel-type-header',
  templateUrl: './fuel-type-header.component.html',
  styleUrls: ['./fuel-type-header.component.scss']
})
export class FuelTypeHeaderComponent {
  constructor(private cubejs: CubejsClient) {}
  @Input() set dataSource(dataSource: object) {
    this.dataSourceList;
    this.gasCount = 0;
    this.newDataList = [];
    this.elctCount = 0;
    this.GasCount = 0;
    this.hybridCount = 0;
    this.euro4Total = 0;
    this.euro6Total = 0;

    if( dataSource != undefined || typeof dataSource != undefined ){
      this.dataSourceList = dataSource;
      this.dataSourceList = this.dataSourceList;
      for(var i in this.dataSourceList ){
        if( this.dataSourceList[i]['History.vehiclefueltype'].includes("GAS") || this.dataSourceList[i]['History.vehiclefueltype'].includes("gas") ){
          this.gasCount = this.gasCount + this.dataSourceList[i]['History.count'];
        }
        if( this.dataSourceList[i]['History.vehiclefueltype'].includes("PETROL") && !this.dataSourceList[i]['History.vehiclefueltype'].includes("PETROL/") ){
          this.euro4Total = this.euro4Total + this.dataSourceList[i]['History.count'];
          // this.euro4Total = Number(this.euro4Total).toLocaleString('en-GB');
        }
        if( this.dataSourceList[i]['History.vehiclefueltype'].includes("DIESEL") && !this.dataSourceList[i]['History.vehiclefueltype'].includes("ELECTRIC") && !this.dataSourceList[i]['History.vehiclefueltype'].includes("GAS") ){
          this.euro6Total = this.euro6Total + this.dataSourceList[i]['History.count'];
          // this.euro4Total = Number(this.euro4Total).toLocaleString('en-GB');
        }
      }

      for(var l in this.dataSourceList ){
        if( this.dataSourceList[l]['History.vehiclefueltype'].includes("GAS") || this.dataSourceList[l]['History.vehiclefueltype'].includes("gas") ){
          this.GasCount = this.gasCount;
          this.newDataList.push( { 'History.vehiclefueltype': this.dataSourceList[l]['History.vehiclefueltype'], "History.count":  this.gasCount } )
        }else if( this.dataSourceList[l]['History.vehiclefueltype'].includes("HYBRID") || this.dataSourceList[l]['History.vehiclefueltype'] == "ELECTRIC DIESEL" ){
          this.hybridCount = this.hybridCount + this.dataSourceList[l]['History.count'];
          this.newDataList.push( { 'History.vehiclefueltype': this.dataSourceList[l]['History.vehiclefueltype'], "History.count":  this.dataSourceList[l]['History.count'] } )
        }else if( this.dataSourceList[l]['History.vehiclefueltype'] == "ELECTRICITY"){
          this.elctCount = this.elctCount + this.dataSourceList[l]['History.count'];
          this.newDataList.push( { 'History.vehiclefueltype': this.dataSourceList[l]['History.vehiclefueltype'], "History.count":  this.dataSourceList[l]['History.count'] } )
        }
      }
      this.totalVolumn = this.GasCount + this.euro4Total + this.euro6Total + this.hybridCount + this.elctCount;
    }
  }

  @Input() set hybridElecData(hybridElecData: object) {
    /*this.dataSourceList;
    this.gasCount = 0;
    this.newDataList = [];
    this.elctCount = 0;
    this.GasCount = 0;
    this.hybridCount = 0;

    if( hybridElecData != undefined || typeof hybridElecData != undefined ){
      this.dataSourceList = hybridElecData;
      this.dataSourceList = this.dataSourceList;
      for(var i in this.dataSourceList ){
        if( this.dataSourceList[i]['History.vehiclefueltype'].includes("GAS") || this.dataSourceList[i]['History.vehiclefueltype'].includes("gas") ){
          this.gasCount = this.gasCount + this.dataSourceList[i]['History.count'];
        }
      }

      for(var l in this.dataSourceList ){
        if( this.dataSourceList[l]['History.vehiclefueltype'].includes("GAS") || this.dataSourceList[l]['History.vehiclefueltype'].includes("gas") ){
          this.GasCount = this.gasCount;
          this.newDataList.push( { 'History.vehiclefueltype': this.dataSourceList[l]['History.vehiclefueltype'], "History.count":  this.gasCount } )
        }else if( this.dataSourceList[l]['History.vehiclefueltype'].includes("HYBRID") || this.dataSourceList[l]['History.vehiclefueltype'] == "ELECTRIC DIESEL" ){
          this.hybridCount = this.hybridCount + this.dataSourceList[l]['History.count'];
          this.newDataList.push( { 'History.vehiclefueltype': this.dataSourceList[l]['History.vehiclefueltype'], "History.count":  this.dataSourceList[l]['History.count'] } )
        }else if( this.dataSourceList[l]['History.vehiclefueltype'] == "ELECTRICITY"){
          this.elctCount = this.elctCount + this.dataSourceList[l]['History.count'];
          this.newDataList.push( { 'History.vehiclefueltype': this.dataSourceList[l]['History.vehiclefueltype'], "History.count":  this.dataSourceList[l]['History.count'] } )
        }  
      }
      
    }*/
  }
  @Input() set euro6Plus(euro6Plus: object) {
    this.euro6PlusCount = 0;
    setTimeout(() => {
      this.euro6PlusCount = euro6Plus;
      // this.euro6PlusCount = Number(this.euro6PlusCount).toLocaleString('en-GB');
      // this.totalEuro6();
    }, 2000);
  }
  @Input() set upToEuro6Count(upToEuro6Count: object) {
    this.totalUpToEuro6Count = 0;
    setTimeout(() => {
      this.totalUpToEuro6Count = upToEuro6Count;
      // this.totalUpToEuro6Count = Number(this.totalUpToEuro6Count).toLocaleString('en-GB');
      // this.totalEuro6();
    }, 2000);
  }
  @Input() set euro4Plus(euro4Plus: object) {
    this.euro4PlusCount = 0;
    setTimeout(() => {
      this.euro4PlusCount = euro4Plus;
      // this.totalEuro4();
    }, 2000);
  }
  @Input() set upToEuro4Count(upToEuro4Count: object) {
    this.totalUpToEuro4Count = 0;
    setTimeout(() => {
      // this.totalUpToEuro4Count = upToEuro4Count;
      // this.totalEuro4();
    }, 2000);
  }
  @Input() set volumnCount(volumnCount: object) {
    this.totalVolumn = 0;
    setTimeout(() => {
      // this.totalVolumn = this.euro6PlusCount + this.totalUpToEuro6Count + this.euro4PlusCount + this.totalUpToEuro4Count + this.GasCount + this.hybridCount + this.elctCount;
      // this.totalVolumn = Number(this.totalVolumn).toLocaleString('en-GB');
    }, 2000);
  }

  totalEuro4(){
    setTimeout(() => {
      // this.euro4Total = this.euro4PlusCount + this.totalUpToEuro4Count;
      // this.euro4Total = Number(this.euro4Total).toLocaleString('en-GB');
    }, 2000);
  }

  totalEuro6(){
    setTimeout(() => {
      // this.euro6Total = this.euro6PlusCount + this.totalUpToEuro6Count;
      // this.euro6Total = Number(this.euro6Total).toLocaleString('en-GB');
    }, 2000);
  }
  
  euro6Total = 0;
  euro4Total = 0;
  totalVolumn;
  totalUpToEuro4Count;
  euro4PlusCount;
  totalUpToEuro6Count;
  euro6PlusCount;
  dataSourceList;
  gasCount = 0;
  newDataList = [];
  elctCount = 0;
  GasCount = 0;
  hybridCount = 0;
}
