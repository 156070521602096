<div class="table-warp mobile-nav mobile-view-table" style="background-color: #051f2c;padding: 0px 10px 0 10px;">
  <div class="row narrow-row">
    <div class="col-12">
      <div class="insights-cards-wrapper">
        <div class="row narrow-row">
          <div class="col-mobile-2">
            <a [routerLink]="[redirectLinkActivity, href]">
              <div class="insights-card"> <i class="fas fa-exchange"></i>
                <!-- <h5>Activity</h5> -->
              </div>
            </a>
          </div>
          <div class="col-mobile-2">
            <a [routerLink]="[redirectLinkAlerts, href]">
              <div class="insights-card"> <i class="fas fa-bell"></i>
                <!-- <h5>Alerts</h5> -->
              </div>
            </a>
          </div>
          <div class="col-mobile-2">
            <a [routerLink]="[redirectLinkVehicles, href]">
              <div class="insights-card"> <i class="fas fa-car"></i>
                <!-- <h5>Vehicles</h5> -->
              </div>
            </a>
          </div>
          <div class="col-mobile-2">
            <a [routerLink]="[redirectLinkDevices, href]">
              <div class="insights-card"> <i class="fas fa-laptop"></i>
                <!-- <h5>Devices</h5> -->
              </div>
            </a>
          </div>
          <div class="col-mobile-2">
            <a [routerLink]="[redirectLinkPeople, href]">
              <div class="insights-card active"> <i class="fas fa-user"></i>
                <!-- <h5>People</h5> -->
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="component-header align-items-center justify-content-between" style="padding: 0px 0 10px 0;margin: 0 10px;margin-top: 55px;">
    <!-- <h4><i class="fas fa-users"></i>People Dashboard</h4> -->
    <div class="date-Picker-wrap align-items-center mobile-view-select">
      <select class="date-Picker" (change)="selectOption($event.target.value)" style="width:100%;margin: 10px 0 0 0px;">
        <option [value]="date.id" *ngFor="let date of dates">{{date.name}}</option>
      </select>
      <select class="date-Picker" (change)="selectOption1($event.target.value)" style="width:100%;margin: 10px 0 0 0px;">
        <option value="All">All</option>
        <option value="{{element['Layers.name']}}" [ngValue]="siteSelected" *ngFor="let element of dataSource">{{element['Layers.name']}}</option>
      </select>
    </div>
  </div>
  
    
    
    <div class="table-warp mobile-view-table" style="padding: 10px;">
      <app-people-messaging  [href]="href" [dateRange]="selected" [selectedSite]="siteSelected"></app-people-messaging>
                          
    </div>
    <div class="reload-svg" (click)="reloadIframe()">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sync-alt" class="svg-inline--fa fa-sync-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="#FFF" d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z"></path>
      </svg>
    </div>
    <div style="padding-bottom: 200px;"></div>