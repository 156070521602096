import { Component, OnInit, Input } from "@angular/core";
import { CubejsClient } from '@cubejs-client/ngx';
import {formatDate, registerLocaleData} from "@angular/common"
import localeEn from '@angular/common/locales/en';
// import { getRelativePosition } from 'chart.js/helpers';
import { Chart } from 'chart.js';
import * as annotations from 'chartjs-plugin-annotation';

@Component({
  selector: 'app-euro-fuel-type-list',
  templateUrl: './euro-fuel-type-list.component.html',
  styleUrls: ['./euro-fuel-type-list.component.scss']
})
export class EuroFuelTypeListComponent implements OnInit {
  constructor(private cubejs:CubejsClient){}
  dataSource = [];
  @Input() set query(query: object) {
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = [];
        this.dataSource = resultSet.tablePivot();
      });
  }
  ngOnInit() {
    
  }
}
