<div class="example-loading-shade fuel-loader"
  *ngIf="loading">
  <mat-spinner></mat-spinner>
  </div>
  <div style="display: block;">
    <canvas baseChart  width="1320" height="500"
            [datasets]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [legend]="barChartLegend"
            [chartType]="barChartType">
    </canvas>
  </div>