<div class="table-warp mobile-view-table">
    <div class="row narrow-row">
        <div class="col-12">
            <div style="background-color: #2F3145;border-radius: 5px;padding: 10px;">
                <app-euro-fuel-type [query]="query"></app-euro-fuel-type>
            </div>
        </div>
        <div class="col-12" style="margin-top: 10px;">
            <div style="background-color: #2F3145;border-radius: 5px;padding: 10px;">
                <app-euro-fuel-type6 [query]="euro6"></app-euro-fuel-type6>
            </div>
        </div>
        <div class="col-12" style="margin-top: 20px;background-color: #2F3145;border-radius: 5px;padding: 10px;">
            <div class="col-12" style="float: left;">
                <div class="col-6" style="color: #FFF;float: left;">Fuel Type</div>
                <div class="col-6" style="color: #FFF;float: left;text-align: center;">Euro 4</div>
            </div>
            <div class="col-12">
                <div class="col-12" style="float: left;">
                    <!-- <div class="col-12" style="float: left;" *ngFor="let element of dataSource">
                        <div style="color:#FFF;padding: 60px 0 3px 0px;" *ngIf="element['History.vehiclefueltype'] == 'DIESEL'">{{element['History.vehiclefueltype']}}</div>
                        <div style="color:#FFF;padding: 0px 0 3px 0px;" *ngIf="element['History.vehiclefueltype'] != 'DIESEL'">{{element['History.vehiclefueltype']}}</div>
                    </div>
                </div> -->
                <div class="col-12">
                    <div style="float: left;width: 100%;">
                        <app-euro-fuel-percent class="col-12" [dataValue]="dataSource" [query]="queryVehicelsFuelTypeList"></app-euro-fuel-percent>
                    </div>
                </div>
                
            </div>
        </div>

        <div class="col-12" style="margin-top: 20px;background-color: #2F3145;border-radius: 5px;padding: 10px;">
            <div class="col-12" style="float: left;">
                <div class="col-6" style="color: #FFF;float: left;">Fuel Type</div>
                <div class="col-6" style="color: #FFF;float: left;text-align: center;">Euro 4</div>
            </div>
            <div class="col-12">
                <div class="col-12" style="float: left;">
                    <div class="col-12" style="float: left;" *ngFor="let element of dataSource">
                        <div style="color:#FFF;padding: 60px 0 3px 0px;" *ngIf="element['History.vehiclefueltype'] == 'DIESEL'">{{element['History.vehiclefueltype']}}</div>
                        <div style="color:#FFF;padding: 0px 0 3px 0px;" *ngIf="element['History.vehiclefueltype'] != 'DIESEL'">{{element['History.vehiclefueltype']}}</div>
                    </div>
                </div>
                <div class="col-12">
                    <div style="float: left;width: 100%;">
                        <app-euro-fuel-euro4-count class="col-12" [dataValue]="dataSource"  [query]="queryVehicelsFuelTypeList"></app-euro-fuel-euro4-count>
                    </div>
                </div>
                
            </div>
        </div>
        <!--<div class="col-12" style="margin-top: 20px;background-color: #2F3145;border-radius: 5px;padding: 10px;">
            <div class="col-12" style="float: left;">
                <div class="col-2" style="color: #FFF;float: left;">Fuel Type</div>
                <div class="col-4" style="color: #FFF;float: left;text-align: center;margin-left: -100px;">Euro 4</div>
                <div class="col-3" style="color: #FFF;float: left;text-align: center;">Euro 5</div>
                <div class="col-3" style="color: #FFF;float: left;text-align: center;">Euro 6</div>
            </div>
            <div class="col-12">
                <div class="col-2" style="float: left;">
                    <div class="col-12" style="float: left;" *ngFor="let element of dataSource">
                        <div style="color:#FFF;padding: 60px 0 3px 0px;" *ngIf="element['History.vehiclefueltype'] == 'DIESEL'">{{element['History.vehiclefueltype']}}</div>
                        <div style="color:#FFF;padding: 0px 0 3px 0px;" *ngIf="element['History.vehiclefueltype'] != 'DIESEL'">{{element['History.vehiclefueltype']}}</div>
                    </div>
                </div>
                <div class="col-12">
                    <div style="float: left;width: 13%;">
                        <app-euro-fuel-percent class="col-12" [dataValue]="dataSource" [query]="queryVehicelsFuelTypeList"></app-euro-fuel-percent>
                    </div>
                    <div style="float: left;width: 13%;">
                        <app-euro-fuel-euro4-count class="col-12" [dataValue]="dataSource"  [query]="queryVehicelsFuelTypeList"></app-euro-fuel-euro4-count>
                    </div>
                    <div style="float: left;width: 13%;">
                        <app-euro-fuel-euro5-percent class="col-12" [dataValue]="dataSource"  [query]="queryVehicelsFuelTypeEuro5List"></app-euro-fuel-euro5-percent>
                    </div>
                    <div style="float: left;width: 13%;">
                        <app-euro-fuel-euro5-count class="col-12" [dataValue]="dataSource"  [query]="queryVehicelsFuelTypeEuro5List"></app-euro-fuel-euro5-count>
                    </div>
                    <div style="float: left;width: 13%;">
                        <app-euro-fuel-euro6-percent class="col-12" [dataValue]="dataSource"  [query]="queryVehicelsFuelTypeEuro6List"></app-euro-fuel-euro6-percent>
                    </div>
                    <div style="float: left;width: 13%;">
                        <app-euro-fuel-euro6-count class="col-12" [dataValue]="dataSource" [query]="queryVehicelsFuelTypeEuro6List"></app-euro-fuel-euro6-count>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="col-12" style="margin-top: 20px;background-color: #2F3145;border-radius: 5px;padding: 10px;">
            <div class="col-12" style="float: left;">
                <div class="col-2" style="color: #FFF;float: left;">Make</div>
                <div class="col-5" style="color: #FFF;float: left;text-align: center;margin-left: -100px;">% of Total Cars</div>
                <div class="col-5" style="color: #FFF;float: left;text-align: center;"># of Cars</div>
            </div>
            <div class="col-12">
                <div class="col-2" style="float: left;">
                    <div class="col-12" style="float: left;" *ngFor="let element of dataSource1">
                        <div style="color:#FFF;padding: 88px 0 3px 0px;" *ngIf="element['History.make'] == 'Unknown/Others'">{{element['History.make']}}</div>
                        <div style="color:#FFF;padding: 0px 0 3px 0px;" *ngIf="element['History.make'] != 'Unknown/Others'">{{element['History.make']}}</div>
                    </div>
                </div>
                <div class="col-12">
                    <div style="float: left;width: 40%;">
                        <app-vehicles-percentage class="col-12" [dataValue]="dataSource1" [query]="makePercentage"></app-vehicles-percentage>
                    </div>
                    <div style="float: left;width: 40%;">
                        <app-vehicles-count class="col-12" [dataValue]="dataSource1" [query]="makePercentage"></app-vehicles-count>
                    </div>
                </div>
                
            </div>
        </div>-->
    </div>
</div>
  