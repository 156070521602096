<div class="component-header d-flex align-items-center justify-content-between">
  <h4><i class="fas fa-bell"></i>Alerts Dashboard</h4>
  <div class="date-Picker-wrap d-flex align-items-center ">
    <select class="date-Picker" (change)="selectOption($event.target.value)">
      <option [value]="date.id" *ngFor="let date of dates">{{date.name}}</option>
    </select>
    <select class="date-Picker" (change)="selectOption1($event.target.value)">
      <option value="All">All</option>
      <option value="{{element['Layers.name']}}" [ngValue]="siteSelected" *ngFor="let element of dataSource">{{element['Layers.name']}}</option>
    </select>
  </div>
</div>

<div class="table-warp">
  <div class="row narrow-row">
    <div class="col-6">
      <div class="kpi-wrap">
            <app-call-alerts-cards
                        [query]="alertsCards" 
                        [queryVehicles]="queryVehicles"
                        [queryDevice]="queryDevice"
                        [queryPeople]="queryPeople"
          ></app-call-alerts-cards>
      </div>
      <app-call-alerts-identifier-type [query]="query2"></app-call-alerts-identifier-type>
    </div>
    <div class="col-6">
      <app-call-alerts-priority [query]="query"></app-call-alerts-priority>
      <app-call-alerts-identifier [query]="query1"></app-call-alerts-identifier>
      <app-call-alerts-actionreason [query]="query3"></app-call-alerts-actionreason>
      <app-call-alerts-resolvedbyuseremail [query]="query4"></app-call-alerts-resolvedbyuseremail>
      <app-call-alerts-source [query]="query5"></app-call-alerts-source>
      <app-call-alerts-resolvedreason [query]="query6"></app-call-alerts-resolvedreason>
      <app-call-alerts-type [query]="query0"></app-call-alerts-type>
    </div>
  </div>
</div>
