import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { TablePageComponent } from './table-page/table-page.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { CallAlertsComponent } from './call-alerts/call-alerts.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { PartnersComponent } from './partners/partners.component';
import { DevicesComponent } from './devices/devices.component';
import { PeopleComponent } from './people/people.component';
import { InsightsComponent } from './insights/insights.component';
import { BespokeComponent } from './bespoke/bespoke.component';
import { InsightsDashboardComponent } from './insights-dashboard/insights-dashboard.component';
import { GraphsComponent } from './graphs/graphs.component';
import { ActivityMobileComponent } from './activity-mobile/activity-mobile.component';
import { AlertsMobileComponent } from './alerts-mobile/alerts-mobile.component';
import { AlertCardComponent } from './alert-card/alert-card.component';
import { InsightMobileComponent } from './insight-mobile/insight-mobile.component';
import { VehiclesMobileComponent } from './vehicles-mobile/vehicles-mobile.component';
import { DevicesMobileComponent } from './devices-mobile/devices-mobile.component';
import { PeopleMobileComponent } from './people-mobile/people-mobile.component';
import { BespokeMobileComponent } from './bespoke-mobile/bespoke-mobile.component';
import { VolumnFuelComponent } from './volumn-fuel/volumn-fuel.component';
import { VehiclesMobileGraphComponent } from './vehicles-mobile-graph/vehicles-mobile-graph.component';
import { EuroTypeMobileComponent } from './euro-type-mobile/euro-type-mobile.component';
import { BespokeFuelComponent } from './bespoke-fuel/bespoke-fuel.component';
import { BespokeWebComponent } from './bespoke-web/bespoke-web.component';
import { RevokeComponent } from "./revoke/revoke.component";
import { CountingComponent } from "./counting/counting.component";
import { GlobalStatsComponent } from "./global-stats/global-stats.component";


const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'dashboard/:id', component: InsightsComponent },
  { path: '', redirectTo: 'activity', pathMatch: 'full' },
  { path: 'activity/:id', component: TablePageComponent },
  { path: '', redirectTo: 'alerts', pathMatch: 'full' },
  { path: 'alerts/:id', component: CallAlertsComponent },
  { path: '', redirectTo: 'vehicles', pathMatch: 'full' },
  { path: 'vehicles/:id', component: VehiclesComponent },
  { path: '', redirectTo: 'vehicles-mobile', pathMatch: 'full' },
  { path: 'vehicles-mobile/:id', component: VehiclesMobileComponent },
  { path: '', redirectTo: 'partners', pathMatch: 'full' },
  { path: 'partners/:id', component: PartnersComponent },
  { path: '', redirectTo: 'devices', pathMatch: 'full' },
  { path: 'devices/:id', component: DevicesComponent },
  { path: '', redirectTo: 'devices-mobile', pathMatch: 'full' },
  { path: 'devices-mobile/:id', component: DevicesMobileComponent },
  { path: '', redirectTo: 'people', pathMatch: 'full' },
  { path: 'people/:id', component: PeopleComponent },
  { path: '', redirectTo: 'people-mobile', pathMatch: 'full' },
  { path: 'people-mobile/:id', component: PeopleMobileComponent },
  { path: '', redirectTo: 'insights', pathMatch: 'full' },
  { path: 'insights/:id', component: InsightsComponent },
  { path: '', redirectTo: 'insights-mobile', pathMatch: 'full' },
  { path: 'insights-mobile/:id', component: InsightMobileComponent },
  { path: '', redirectTo: 'bespoke', pathMatch: 'full' },
  { path: 'bespoke/:id', component: BespokeWebComponent },
  { path: '', redirectTo: 'bespoke-mobile', pathMatch: 'full' },
  { path: 'bespoke-mobile/:id', component: BespokeFuelComponent },
  { path: '', redirectTo: 'insights-dashboard', pathMatch: 'full' },
  { path: 'insights-dashboard/:id/:link', component: InsightsDashboardComponent },
  { path: '', redirectTo: 'graphs', pathMatch: 'full' },
  { path: 'graphs/:id', component: GraphsComponent },
  { path: '', redirectTo: 'activity-mobile', pathMatch: 'full' },
  { path: 'activity-mobile/:id', component: ActivityMobileComponent },
  { path: '', redirectTo: 'alerts-mobile', pathMatch: 'full' },
  { path: 'alerts-mobile/:id', component: AlertsMobileComponent },
  { path: '', redirectTo: 'alerts-card', pathMatch: 'full' },
  { path: 'alerts-card/:id', component: AlertCardComponent },
  { path: '', redirectTo: 'fuel-type', pathMatch: 'full' },
  { path: 'fuel-type/:id', component: VolumnFuelComponent },
  { path: '', redirectTo: 'fuel-type-mobile-s', pathMatch: 'full' },
  { path: 'fuel-type-mobile/:id', component: EuroTypeMobileComponent },
  { path: '', redirectTo: 'bespoke-m-s', pathMatch: 'full' },
  { path: '', redirectTo: 'counting', pathMatch: 'full' },
  { path: 'counting/:id', component: CountingComponent },
  { path: '', redirectTo: 'export-csv-s', pathMatch: 'full' },
  { path: 'global/:id', component: GlobalStatsComponent },
  { path: 'revoke', component: RevokeComponent },

  { path: 'line-chart', component: LineChartComponent },
  { path: '**', component: DashboardPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
