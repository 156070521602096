<div class="component-header d-flex align-items-center justify-content-between">
  <h4><i class="fas fa-exchange"></i>Activity Dashboard</h4>
  <div class="date-Picker-wrap d-flex align-items-center ">
    <select class="date-Picker" (change)="selectOption($event.target.value)">
      <!-- <option value="Today">Date Range</option> -->
      <option [value]="date.id" *ngFor="let date of dates">{{date.name}}</option>
    </select>
    <select class="date-Picker" (change)="selectOption1($event.target.value)">
      <!-- <option value="All">Site</option> -->
      <option value="All">All</option>
      <option value="{{element['Layers.name']}}" [ngValue]="siteSelected" *ngFor="let element of dataSource">{{element['Layers.name']}}</option>
      
    </select>
  </div>
</div>

<div class="table-warp">
  <div class="row narrow-row">
    <div class="col-6">
      <app-material-table-activity [query]="query"></app-material-table-activity>
      <app-activity-vehicles [href]="href" [dateRange]="selected" [selectedSite]="siteSelected"></app-activity-vehicles>
      <app-activity-device [href]="href" [dateRange]="selected" [selectedSite]="siteSelected"></app-activity-device>
      <app-activity-people [href]="href" [dateRange]="selected" [selectedSite]="siteSelected"></app-activity-people>
      <app-material-table-activity-source [query]="query2"></app-material-table-activity-source>
      <app-material-table-activity-trigger [query]="query3"></app-material-table-activity-trigger>
  <app-material-table-activity-trigger-condition [query]="query4"></app-material-table-activity-trigger-condition> 
      
    </div>
    <div class="col-6">
      <app-material-table-activity-identifier [query]="query1"></app-material-table-activity-identifier>
    </div>
  </div>
</div>
