<div>
      <canvas
        height="320"
          width="100%"
        baseChart
        legend="true"
        chartType="pie"
        [datasets]="chartData"
        [labels]="chartLabels"
        [options]="chartOptions"
      >
      </canvas>
  </div>
  