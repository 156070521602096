<div class="example-loading-shade" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="component-header d-flex align-items-center justify-content-between">
  <h4><i class="fas fa-bring-forward"></i>Partner Dashboard</h4>
  <div class="date-Picker-wrap d-flex align-items-center ">
    <select class="date-Picker" (change)="selectOption1($event.target.value)">
      <option [value]="org['Organisations.tenantkey']" [ngValue]="orgSelected" *ngFor="let org of Organisation">{{org["Organisations.name"]}}</option>
    </select>
    <select class="date-Picker" (change)="selectOption($event.target.value)">
      <option [value]="date.id" *ngFor="let date of dates">{{date.name}}</option>
    </select>
  </div>
</div>
<div class="table-warp">
  <div class="custom-table">
	<div class="table-header">{{orgName}}</div>
    <div class="table-header-row">
      <div class="cell-wrap flex-fill"></div>
      <div class="cell-wrap" style="width: 210px">Vehicles</div>
      <div class="cell-wrap" style="width: 220px">Devices</div>
      <div class="cell-wrap" style="width: 210px">People</div>
    </div>
    <div class="table-header-row">
      <div class="cell-wrap flex-fill"></div>
      <div class="cell-wrap" style="width: 70px;">Enter</div>
      <div class="cell-wrap" style="width: 70px;">Exit</div>
      <div class="cell-wrap" style="width: 70px;">Total</div>
      <div class="cell-wrap" style="width: 70px;">Online</div>
      <div class="cell-wrap" style="width: 70px;">Offline</div>
      <div class="cell-wrap" style="width: 80px;">Total</div>
      <div class="cell-wrap" style="width: 70px;">Enter</div>
      <div class="cell-wrap" style="width: 70px;">Exit</div>
      <div class="cell-wrap" style="width: 70px;">Total</div>
    </div>
    <div class="table-inner-wrapper">
      <div class="table-row-wrapper">
        <div class="table-row">
          <div class="cell-wrap flex-fill">Activity</div>
          <div class="cell-wrap" style="width: 70px;">{{VehiclesActivityEnterCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{VehiclesActivityExitCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{VehiclesActivityTotalCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{DevicesActivityOnlineCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{DevicesActivityOfflineCount}}</div>
          <div class="cell-wrap" style="width: 80px;">{{DevicesActivityTotalCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{PeopleActivityEnterCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{PeopleActivityExitCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{PeopleActivityTotalCount}}</div>
        </div>
      </div>
      <div class="table-row-wrapper">
        <div class="table-row">
          <div class="cell-wrap flex-fill">Alerts</div>
          <div class="cell-wrap" style="width: 70px;">{{VehiclesEnterCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{VehiclesExitCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{VehiclesTotalCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{DevicesAlertsOnlineCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{DevicesAlertsOfflineCount}}</div>
          <div class="cell-wrap" style="width: 80px;">{{DevicesAlertsTotalCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{PeopleAlertsEnterCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{PeopleAlertsExitCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{PeopleAlertsTotalCount}}</div>
        </div>
      </div>
    </div>
    <div class="table-header-row" style="margin-top: 20px">
      <div class="cell-wrap flex-fill"></div>
      <div class="cell-wrap" style="width: 70px;">SMS</div>
      <div class="cell-wrap" style="width: 70px;">Phone</div>
      <div class="cell-wrap" style="width: 70px;">Email</div>
      <div class="cell-wrap" style="width: 70px;">Actions</div>
      <div class="cell-wrap" style="width: 70px;">Users</div>
      <div class="cell-wrap" style="width: 70px;">People</div>
      <div class="cell-wrap" style="width: 80px;">Sites</div>
      <div class="cell-wrap" style="width: 70px;">Areas</div>
      <div class="cell-wrap" style="width: 70px;">Zones</div>
      <div class="cell-wrap" style="width: 70px;">Sources</div>
    </div>
    <div class="table-inner-wrapper">
      <div class="table-row-wrapper">
        <div class="table-row">
          <div class="cell-wrap flex-fill">System</div>
          <div class="cell-wrap" style="width: 70px;">{{SystemTotalSMSCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{SystemTotalPhoneCallCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{SystemTotalEmailCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{SystemTotalActionsCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{SystemTotalUsersCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{SystemTotalPeopleCount}}</div>
          <div class="cell-wrap" style="width: 80px;">{{SystemTotalSiteCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{SystemTotalAreaCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{SystemTotalZoneCount}}</div>
          <div class="cell-wrap" style="width: 70px;">{{SystemTotalSourceCount}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="table-warp">
  <div class="custom-table" *ngFor="let site of dataOrgList">
	<div class="table-header">{{site["Layers.name"]}}</div>
    <div class="table-header-row">
      <div class="cell-wrap flex-fill"></div>
      <div class="cell-wrap" style="width: 210px">Vehicles</div>
      <div class="cell-wrap" style="width: 220px">Devices</div>
      <div class="cell-wrap" style="width: 210px">People</div>
    </div>
    <div class="table-header-row">
      <div class="cell-wrap flex-fill"></div>
      <div class="cell-wrap" style="width: 70px;">Enter</div>
      <div class="cell-wrap" style="width: 70px;">Exit</div>
      <div class="cell-wrap" style="width: 70px;">Total</div>
      <div class="cell-wrap" style="width: 70px;">Online</div>
      <div class="cell-wrap" style="width: 70px;">Offline</div>
      <div class="cell-wrap" style="width: 80px;">Total</div>
      <div class="cell-wrap" style="width: 70px;">Enter</div>
      <div class="cell-wrap" style="width: 70px;">Exit</div>
      <div class="cell-wrap" style="width: 70px;">Total</div>
    </div>
    <div class="table-inner-wrapper">
      <div class="table-row-wrapper">
        <div class="table-row">
          <div class="cell-wrap flex-fill">Activity</div>
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Activity'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'VehiclesActivityEnter'">{{nitem.value}}</div>
            </div>
          </div>
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Activity'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'VehiclesActivityExit'">{{nitem.value}}</div>
            </div>
          </div>
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Activity'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'VehiclesActivityTotal'">{{nitem.value}}</div>
            </div>
          </div>
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Activity'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'DevicesActivityOnline'">{{nitem.value}}</div>
            </div>
          </div>
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Activity'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'DevicesActivityOffline'">{{nitem.value}}</div>
            </div>
          </div>
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Activity'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'DevicesActivityTotal'">{{nitem.value}}</div>
            </div>
          </div>
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Activity'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'PeopleActivityEnter'">{{nitem.value}}</div>
            </div>
          </div>
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Activity'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'PeopleActivityExit'">{{nitem.value}}</div>
            </div>
          </div>
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Activity'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'PeopleActivityTotal'">{{nitem.value}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-row-wrapper">
        <div class="table-row">
          <div class="cell-wrap flex-fill">Alerts</div>
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Alerts'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'VehiclesEnter'">{{nitem.value}}</div>
            </div>
          </div>
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Alerts'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'VehiclesExit'">{{nitem.value}}</div>
            </div>
          </div>
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Alerts'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'VehiclesTotal'">{{nitem.value}}</div>
            </div>
          </div>
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Alerts'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'DevicesAlertsOnline'">{{nitem.value}}</div>
            </div>
          </div>
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Alerts'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'DevicesAlertsOffline'">{{nitem.value}}</div>
            </div>
          </div>
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Alerts'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'DevicesAlertsTotal'">{{nitem.value}}</div>
            </div>
          </div>
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Alerts'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'PeopleAlertsEnter'">{{nitem.value}}</div>
            </div>
          </div>
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Alerts'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'PeopleAlertsExit'">{{nitem.value}}</div>
            </div>
          </div> 
          <div *ngFor="let item of siteObject[site['Layers.name']] | keyvalue">
            <div *ngFor="let nitem of item.value['Alerts'] | keyvalue">
              <div class="cell-wrap" style="width: 70px;" *ngIf="nitem.key == 'PeopleAlertsTotal'">{{nitem.value}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</div>
