
import { Component, Input, Output, OnInit } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Component({
  selector: 'app-insight-vehicle',
  templateUrl: './insight-vehicle.component.html',
  styleUrls: ['./insight-vehicle.component.scss']
})
export class InsightVehicleComponent {
  constructor(private cubejs: CubejsClient) {}
  selected: string;
  @Input() href: string
  
  nData = [];
  public VehiclesAlertsTotal = null;
  public _VehiclesAlertsTotal = null;
  public VehiclesActivityTotal = null;
  public _VehiclesActivityTotal = null;
  public VehiclesAlertsExitTotal = null;
  public _VehiclesAlertsExitTotal = null;
  public VehiclesActivityExitTotal = null;
  public _VehiclesActivityExitTotal = null;
  public DeviceAlertsEnterTotal = null;
  public _DeviceAlertsEnterTotal = null;
  public DeviceActivityEnterTotal = null;
  public _DeviceActivityEnterTotal = null;
  public DeviceAlertsExitTotal = null;
  public _DeviceAlertsExitTotal = null;
  public DeviceActivityExitTotal = null;
  public _DeviceActivityExitTotal = null;
  public PeopleAlertsEnterTotal = null;
  public _PeopleAlertsEnterTotal = null;
  public PeopleActivityEnterTotal = null;
  public _PeopleActivityEnterTotal = null;
  public PeopleAlertsExitTotal = null;
  public _PeopleAlertsExitTotal = null;
  public PeopleActivityExitTotal = null;
  public _PeopleActivityExitTotal = null;

  public VehiclesAlertsTotalCount = null;
  public VehiclesActivityTotalCount = null;
  public VehiclesAlertsExitTotalCount = null;
  public VehiclesActivityExitTotalCount = null;
  public DeviceAlertsEnterTotalCount = null;
  public DeviceActivityEnterTotalCount = null;
  public DeviceAltersExitTotalCount = null;
  public DeviceActivityExitTotalCount = null;
  public PeopleAlertsEnterTotalCount = null;
  public PeopleActivityEnterTotalCount = null;
  public PeopleAltersExitTotalCount = null;
  public PeopleActivityExitTotalCount = null;

  public data = true;
  @Input() set dateRange( dateRange: string ){
    this.selected= dateRange;
    this.loading = false;
    this.dataSource = [];
    this.data = false;
    this.nonSelectedSiteFunction();
  }
  @Input() set selectedSite( selectedSite: string){
    this.dataSource = [];
    if(selectedSite == "All" && this.selected != "Today"){
      this.nonSelectedSiteFunction();
    }else{
      if( selectedSite != "All" )
        this.SelectedSiteFunction(selectedSite);
    }
  }
  SelectedSiteFunction(selectedSite){
    let id = selectedSite;
    this.VehiclesAlertsTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "Tasks.sourcetype",
          "operator": "contains",
          "values": [
            "enter"
          ]
        },
        {
          "member": "Tasks.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.VehiclesAlertsTotal.subscribe(querySite => {
      this._VehiclesAlertsTotal = querySite;
      this.cubejs.load(this._VehiclesAlertsTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.VehiclesAlertsTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.VehiclesActivityTotal = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "contains",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "equals",
          "values": [
            "enter"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.VehiclesActivityTotal.subscribe(querySite => {
      this._VehiclesActivityTotal = querySite;
      this.cubejs.load(this._VehiclesActivityTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.VehiclesActivityTotalCount = this.nData[i]["History.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.VehiclesAlertsExitTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "Tasks.sourcetype",
          "operator": "contains",
          "values": [
            "exit"
          ]
        },
        {
          "member": "Tasks.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.VehiclesAlertsExitTotal.subscribe(querySite => {
      this._VehiclesAlertsExitTotal = querySite;
      this.cubejs.load(this._VehiclesAlertsExitTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.VehiclesAlertsExitTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.VehiclesActivityExitTotal = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "contains",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "equals",
          "values": [
            "exit"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.VehiclesActivityExitTotal.subscribe(querySite => {
      this._VehiclesActivityExitTotal = querySite;
      this.cubejs.load(this._VehiclesActivityExitTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.VehiclesActivityExitTotalCount = this.nData[i]["History.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.DeviceActivityEnterTotal = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "device"
          ]
        },
        {
          "member": "History.metadata",
          "operator": "contains",
          "values": [
            "online"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.DeviceActivityEnterTotal.subscribe(querySite => {
      this._DeviceActivityEnterTotal = querySite;
      this.cubejs.load(this._DeviceActivityEnterTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.DeviceActivityEnterTotalCount = this.nData[i]["History.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.DeviceAlertsEnterTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "device"
          ]
        },
        {
          "member": "Tasks.metadata",
          "operator": "contains",
          "values": [
            "online"
          ]
        },
        {
          "member": "Tasks.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.DeviceAlertsEnterTotal.subscribe(querySite => {
      this._DeviceAlertsEnterTotal = querySite;
      this.cubejs.load(this._DeviceAlertsEnterTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.DeviceAlertsEnterTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.DeviceAlertsExitTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "device"
          ]
        },
        {
          "member": "Tasks.metadata",
          "operator": "contains",
          "values": [
            "offline"
          ]
        },
        {
          "member": "Tasks.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.DeviceAlertsExitTotal.subscribe(querySite => {
      this._DeviceAlertsExitTotal = querySite;
      this.cubejs.load(this._DeviceAlertsExitTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.DeviceAltersExitTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.DeviceActivityExitTotal = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "device"
          ]
        },
        {
          "member": "History.metadata",
          "operator": "contains",
          "values": [
            "offline"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.DeviceActivityExitTotal.subscribe(querySite => {
      this._DeviceActivityExitTotal = querySite;
      this.cubejs.load(this._DeviceActivityExitTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.DeviceActivityExitTotalCount = this.nData[i]["History.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.PeopleAlertsEnterTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.peopleid",
          "operator": "set",
          "values": []
        },
        {
          "member": "Tasks.tenantkey",
          "operator": "equals",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.sourcetype",
          "operator": "contains",
          "values": [
            "enter"
          ]
        },
        {
          "member": "Tasks.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.PeopleAlertsEnterTotal.subscribe(querySite => {
      this._PeopleAlertsEnterTotal = querySite;
      this.cubejs.load(this._PeopleAlertsEnterTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.PeopleAlertsEnterTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.PeopleAlertsExitTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.peopleid",
          "operator": "set",
          "values": []
        },
        {
          "member": "Tasks.tenantkey",
          "operator": "equals",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.sourcetype",
          "operator": "contains",
          "values": [
            "exit"
          ]
        },
        {
          "member": "Tasks.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.PeopleAlertsExitTotal.subscribe(querySite => {
      this._PeopleAlertsExitTotal = querySite;
      this.cubejs.load(this._PeopleAlertsExitTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.PeopleAltersExitTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.PeopleActivityEnterTotal = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.peopleid",
          "operator": "notContains",
          "values": [
            "00000"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "equals",
          "values": [
            "enter"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.PeopleActivityEnterTotal.subscribe(querySite => {
      this._PeopleActivityEnterTotal = querySite;
      this.cubejs.load(this._PeopleActivityEnterTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.PeopleActivityEnterTotalCount = this.nData[i]["History.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.PeopleActivityExitTotal = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.peopleid",
          "operator": "notContains",
          "values": [
            "00000"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "equals",
          "values": [
            "exit"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.PeopleActivityExitTotal.subscribe(querySite => {
      this._PeopleActivityExitTotal = querySite;
      this.cubejs.load(this._PeopleActivityExitTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.PeopleActivityExitTotalCount = this.nData[i]["History.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });











  }
  nonSelectedSiteFunction(){
    this.VehiclesAlertsTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "Tasks.sourcetype",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });
    this.VehiclesAlertsTotal.subscribe(querySite => {
      this._VehiclesAlertsTotal = querySite;
      this.cubejs.load(this._VehiclesAlertsTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.VehiclesAlertsTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.VehiclesActivityTotal = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "contains",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "equals",
          "values": [
            "enter"
          ]
        }
      ]
    });
    this.VehiclesActivityTotal.subscribe(querySite => {
      this._VehiclesActivityTotal = querySite;
      this.cubejs.load(this._VehiclesActivityTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.VehiclesActivityTotalCount = this.nData[i]["History.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.VehiclesAlertsExitTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "Tasks.sourcetype",
          "operator": "contains",
          "values": [
            "exit"
          ]
        }
      ]
    });
    this.VehiclesAlertsExitTotal.subscribe(querySite => {
      this._VehiclesAlertsExitTotal = querySite;
      this.cubejs.load(this._VehiclesAlertsExitTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.VehiclesAlertsExitTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.VehiclesActivityExitTotal = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "contains",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "equals",
          "values": [
            "exit"
          ]
        }
      ]
    });
    this.VehiclesActivityExitTotal.subscribe(querySite => {
      this._VehiclesActivityExitTotal = querySite;
      this.cubejs.load(this._VehiclesActivityExitTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.VehiclesActivityExitTotalCount = this.nData[i]["History.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.DeviceAlertsEnterTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "device"
          ]
        },
        {
          "member": "Tasks.metadata",
          "operator": "contains",
          "values": [
            "online"
          ]
        }
      ]
    });
    this.DeviceAlertsEnterTotal.subscribe(querySite => {
      this._DeviceAlertsEnterTotal = querySite;
      this.cubejs.load(this._DeviceAlertsEnterTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.DeviceAlertsEnterTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.DeviceActivityEnterTotal = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "device"
          ]
        },
        {
          "member": "History.metadata",
          "operator": "contains",
          "values": [
            "online"
          ]
        }
      ]
    });
    this.DeviceActivityEnterTotal.subscribe(querySite => {
      this._DeviceActivityEnterTotal = querySite;
      this.cubejs.load(this._DeviceActivityEnterTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.DeviceActivityEnterTotalCount = this.nData[i]["History.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.DeviceAlertsExitTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "device"
          ]
        },
        {
          "member": "Tasks.metadata",
          "operator": "contains",
          "values": [
            "offline"
          ]
        }
      ]
    });
    this.DeviceAlertsExitTotal.subscribe(querySite => {
      this._DeviceAlertsExitTotal = querySite;
      this.cubejs.load(this._DeviceAlertsExitTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.DeviceAltersExitTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.DeviceActivityExitTotal = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "device"
          ]
        },
        {
          "member": "History.metadata",
          "operator": "contains",
          "values": [
            "offline"
          ]
        }
      ]
    });
    this.DeviceActivityExitTotal.subscribe(querySite => {
      this._DeviceActivityExitTotal = querySite;
      this.cubejs.load(this._DeviceActivityExitTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.DeviceActivityExitTotalCount = this.nData[i]["History.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });



    this.PeopleAlertsEnterTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.peopleid",
          "operator": "set",
          "values": []
        },
        {
          "member": "Tasks.tenantkey",
          "operator": "equals",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.sourcetype",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });
    this.PeopleAlertsEnterTotal.subscribe(querySite => {
      this._PeopleAlertsEnterTotal = querySite;
      this.cubejs.load(this._PeopleAlertsEnterTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.PeopleAlertsEnterTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.PeopleAlertsExitTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.peopleid",
          "operator": "set",
          "values": []
        },
        {
          "member": "Tasks.tenantkey",
          "operator": "equals",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.sourcetype",
          "operator": "contains",
          "values": [
            "exit"
          ]
        }
      ]
    });
    this.PeopleAlertsExitTotal.subscribe(querySite => {
      this._PeopleAlertsExitTotal = querySite;
      this.cubejs.load(this._PeopleAlertsExitTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.PeopleAltersExitTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.PeopleActivityEnterTotal = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.peopleid",
          "operator": "notContains",
          "values": [
            "00000"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "equals",
          "values": [
            "enter"
          ]
        }
      ]
    });
    this.PeopleActivityEnterTotal.subscribe(querySite => {
      this._PeopleActivityEnterTotal = querySite;
      this.cubejs.load(this._PeopleActivityEnterTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.PeopleActivityEnterTotalCount = this.nData[i]["History.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.PeopleActivityExitTotal = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.peopleid",
          "operator": "notContains",
          "values": [
            "00000"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "equals",
          "values": [
            "exit"
          ]
        }
      ]
    });
    this.PeopleActivityExitTotal.subscribe(querySite => {
      this._PeopleActivityExitTotal = querySite;
      this.cubejs.load(this._PeopleActivityExitTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.PeopleActivityExitTotalCount = this.nData[i]["History.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
  }
  @Input() limit: number;
  @Output() pageEvent = new EventEmitter();
  @Output() sortingChanged = new EventEmitter();
  loading = true;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25];
  dataSource = [];
  displayedColumns = ['Site', 'Count'];
  changeSorting(value) {
    this.sortingChanged.emit(value)
  }
}
