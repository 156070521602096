import { Component, Input, Output, OnInit } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Component({
  selector: 'app-call-alerts-identifier',
  templateUrl: './call-alerts-identifier.component.html',
  styleUrls: ['./call-alerts-identifier.component.scss']
})
export class CallAlertsIdentifierComponent{
  constructor(private cubejs: CubejsClient) {}
  @Input() set query(query: object) {
    this.loading = true;
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = [];
        this.dataSource = resultSet.tablePivot();
        this.dataSource.push({"Tasks.identifiertype": "Total", "Tasks.count": 0});
        for( var i=0; i<this.dataSource.length;i++ ){
          var ndata = this.dataSource[i];
          this.count = ndata['Tasks.count'] + this.count;
        }
        this.dataSource.forEach((element,index)=>{
          if( element["Tasks.identifiertype"] == "Total" ){
            element["Tasks.count"] = this.count;
          }
        });
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
  };
  @Input() limit: number;
  @Output() pageEvent = new EventEmitter();
  @Output() sortingChanged = new EventEmitter();
  loading = true;
  length = 0;
  count = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25];
  dataSource = [];
  displayedColumns = ['Identifier','Site', 'Count'];
  changeSorting(value) {
    this.sortingChanged.emit(value)
  }
}
