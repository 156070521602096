import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tasks-site-dropdown',
  templateUrl: './tasks-site-dropdown.component.html',
  styleUrls: ['./tasks-site-dropdown.component.scss']
})
export class TasksSiteDropdownComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
