import { Component, Input, Output, OnInit  } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";

@Component({
  selector: 'app-partners-alerts',
  templateUrl: './partners-alerts.component.html',
  styleUrls: ['./partners-alerts.component.scss']
})
export class PartnersAlertsComponent implements OnInit{
  @Input() query: object;
  @Input() title: string;
  @Input() change: string;
  @Input() href: string;
  @Input() duration: number;
  @Input() progress: boolean;
  @Input() difference: string;

  constructor(private cubejs:CubejsClient){}
  public result = 0;
  public postfix = null;
  public prefix = null;
  public diffValue = null;

  ngOnInit(): void {
    if( this.change == "changeKey"){
      if( this.href == "partners-s"){
        this.cubejs = new CubejsClient(
          {
            "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MTY2MDA4NDF9.q8o5JjVALy2YjmE31D_nIxteAm5gFYbM6p9JBJegSLA',
            "options": {
                "apiUrl": 'https://one-stage-m.advancedstudio.co.uk/cubejs-api/v1'
            }
        });
      }else{
        this.cubejs = new CubejsClient(
          {
            "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MTY2MDA4NDF9.q8o5JjVALy2YjmE31D_nIxteAm5gFYbM6p9JBJegSLA',
            "options": {
                "apiUrl": 'https://one-stage-m.advancedstudio.co.uk/cubejs-api/v1'
            }
        });
      }
    }
    
    this.cubejs.load(this.query).subscribe(
      resultSet => {
        resultSet.series().map((s) => {
          this.result = s['series'][0]['value'].toFixed(1);
          const measureKey = resultSet.seriesNames()[0].key;
          const annotations = resultSet.tableColumns().find((tableColumn) => tableColumn.key === measureKey);
          if(annotations){
            const format = annotations.format || (annotations.meta && annotations.meta.format);
            if (format === 'percent') {
              this.postfix = '%';
            } else if (format === 'currency') {
              this.prefix = '$';
            }
          }
        })
      },
      err => console.log('HTTP Error', err)
    );
    if (this.difference) {
      this.cubejs.load({...this.query, timeDimensions: [
          {
            dimension: `${this.difference}.createdAt`,
            granularity: null,
            dateRange: 'This year',
          },
        ],}).subscribe(
        resultSet => {
          this.diffValue = ((parseInt(resultSet.rawData()[0][`${this.difference}.count`]) / this.result) * 100).toFixed(1);
          // this.diffValue = (resultSet.totalRow()[resultSet.measures[0]] / this.result) * 100;
        },
        err => console.log('HTTP Error', err)
      );
    }
  }

}
