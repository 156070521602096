import { Component, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { CubejsClient } from "@cubejs-client/ngx";

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  cubeQuery = new BehaviorSubject(null);
  chartType = new BehaviorSubject(null);
  pivotConfig = new BehaviorSubject(null);
  constructor(private meta: Meta, private title: Title, private router: Router, private cubejs: CubejsClient) {
    this.title.setTitle('Angular Dashboard with Material');
    this.meta.addTag({ name: 'description', content: 'How to build Angular Material Data Table with Cube.js' });
    this.meta.addTag({ name: 'keywords', content: 'Angular, Cube.js, Data Table, Table, Material UI' });
  }
  public href: string = this.router.url.replace( "/", "" ).split("/")[1];
  public hrefPartner: string = this.router.url.replace( "/", "" ).split("/")[0];
  public querySites = null;
  public queryOrganisation = null;
  public newCubeJs = new CubejsClient(
    {
      "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MTY2MDA4NDF9.q8o5JjVALy2YjmE31D_nIxteAm5gFYbM6p9JBJegSLA',
      "options": {
          "apiUrl": 'https://one-stage-m.advancedstudio.co.uk/cubejs-api/v1'
      }
  });
  public _queryOrganisation = new BehaviorSubject(
    {
      "measures": [],
      "timeDimensions": [],
      "order": {},
      "dimensions": [
        "Organisations.tenantkey",
        "Organisations.name"
      ]
    });
    
    dates: any[] = [
      { id: 'Today', name: 'Today' },
      { id: 'Yesterday', name: 'Yesterday' },
      { id: 'This week', name: 'This Week' },
      { id: 'Last week', name: 'Last Week' },
      { id: 'This month', name: 'This Month' },
      { id: 'Last month', name: 'Last Month' },
      { id: 'This Year', name: 'This Year' },
    ];
  selected: string = "Today";
  selectOption(id: string) {
    this.selected = id;
    this.SiteSelected();
    this.loading = true;
    this.ngOnInit();
  }
  orgName:string= "Default Organization";
  orgSelected: string = this.href;
  selectOption1(id: string) {
    for( var i = 0;i < this.Organisation.length; i++){
      if( this.Organisation[i]['Organisations.tenantkey'] == id ){
        this.orgName = this.Organisation[i]['Organisations.name'];
      }
    }
    this.orgSelected = id;
    this.loading = true;
    this.OrgSelected()
    this.ngOnInit();
  }
  
  public _querySites = new BehaviorSubject(
    {
      "measures": [],
      "timeDimensions": [],
      "order": {},
      "filters": [
        {
          "member": "Layers.tenantkey",
          "operator": "equals",
          "values": [
            this.selected
          ]
        },
        {
          "member": "Layers.isdeleted",
          "operator": "equals",
          "values": [
            "false"
          ]
        },
        {
          "member": "Layers.category",
          "operator": "equals",
          "values": [
            "1"
          ]
        }
      ],
      "dimensions": [
        "Layers.name",
        "Layers.tenantkey",
        "Layers.category"
      ]
    }
  );

  public VehiclesEnter = null;
  public VehiclesExit = null;
  public VehiclesTotal = null;
  public VehiclesActivityEnter = null;
  public VehiclesActivityExit = null;
  public VehiclesActivityTotal = null;
  public DevicesAlertsOnline = null;

  public DevicesAlertsOffline = null;
  public DevicesAlertsTotal = null;
  public DevicesActivityOnline = null;
  public DevicesActivityOffline = null;
  public DevicesActivityTotal = null;
  public PeopleAlertsEnter = null;
  public PeopleAlertsExit = null;
  public PeopleAlertsTotal = null; 
  public PeopleActivityEnter = null;
  public PeopleActivityExit = null;
  public PeopleActivityTotal = null;
  public SystemTotalSMS = null;
  public SystemTotalPhoneCall = null;
  public SystemTotalEmail = null;
  public SystemTotalActions = null;
  public SystemTotalUsers = null;
  
  public SystemTotalPeople = null;
  public SystemTotalSite = null;
  public SystemTotalArea = null;
  public SystemTotalZone = null;
  public SystemTotalSource = null;
  OrgSelected() {
    this._querySites = new BehaviorSubject(
      {
        "measures": [],
        "timeDimensions": [],
        "order": {},
        "filters": [
          {
            "member": "Layers.tenantkey",
            "operator": "equals",
            "values": [
              this.orgSelected
            ]
          },
          {
            "member": "Layers.isdeleted",
            "operator": "equals",
            "values": [
              "false"
            ]
          },
          {
            "member": "Layers.category",
            "operator": "equals",
            "values": [
              "1"
            ]
          }
        ],
        "dimensions": [
          "Layers.name",
          "Layers.tenantkey",
          "Layers.category"
        ]
      }
    );
    
    this.VehiclesEnter = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.orgSelected
          ]
        },
        {
          "member": "Tasks.sourcetype",
          "operator": "contains",
          "values": [
            "enter"
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        }
      ]
    }); 
    this.VehiclesExit = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
          }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.orgSelected
          ]
        },
        {
          "member": "Tasks.sourcetype",
          "operator": "contains",
          "values": [
            "exit"
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        }
      ]
  });
  this.VehiclesTotal = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  this.VehiclesActivityEnter = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "History.triggercondition",
        "operator": "equals",
        "values": [
          "enter"
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  this.VehiclesActivityExit = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "History.triggercondition",
        "operator": "equals",
        "values": [
          "exit"
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  this.VehiclesActivityTotal = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  this.DevicesAlertsOnline = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      },
      {
        "member": "Tasks.metadata",
        "operator": "contains",
        "values": [
          "online"
        ]
      }
    ]
  });
  this.DevicesAlertsOffline = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      },
      {
        "member": "Tasks.metadata",
        "operator": "contains",
        "values": [
          "offline"
        ]
      }
    ]
  });
  this.DevicesAlertsTotal = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      }
    ]
  });
  this.DevicesActivityOnline = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      },
      {
        "member": "History.metadata",
        "operator": "contains",
        "values": [
          "online"
        ]
      }
    ]
  });
  this.DevicesActivityOffline = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      },
      {
        "member": "History.metadata",
        "operator": "contains",
        "values": [
          "offline"
        ]
      }
    ]
  });
  this.DevicesActivityTotal = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      }
    ]
  });
  this.PeopleAlertsEnter = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.peopleid",
        "operator": "set",
        "values": []
      },
      {
        "member": "Tasks.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Tasks.sourcetype",
        "operator": "contains",
        "values": [
          "enter"
        ]
      }
    ]
  });
  this.PeopleAlertsExit = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.peopleid",
        "operator": "set",
        "values": []
      },
      {
        "member": "Tasks.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Tasks.sourcetype",
        "operator": "contains",
        "values": [
          "exit"
        ]
      }
    ]
  });
  this.PeopleAlertsTotal = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "dimensions": [
      "Tasks.peopleid"
    ],
    "filters": [
      {
        "member": "Tasks.peopleid",
        "operator": "set",
        "values": []
      },
      {
        "member": "Tasks.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      }
    ]
  });
  this.PeopleActivityEnter = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "History.peopleid",
        "operator": "notContains",
        "values": [
          "0000"
        ]
      },
      {
        "member": "History.triggercondition",
        "operator": "contains",
        "values": [
          "enter"
        ]
      }
    ]
  });
  this.PeopleActivityExit = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "History.peopleid",
        "operator": "notContains",
        "values": [
          "0000"
        ]
      },
      {
        "member": "History.triggercondition",
        "operator": "contains",
        "values": [
          "exit"
        ]
      }
    ]
  });
  this.PeopleActivityTotal = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "History.peopleid",
        "operator": "notContains",
        "values": [
          "0000"
        ]
      }
    ]
  });
  this.SystemTotalSMS = new BehaviorSubject({
    "measures": [
      "Alerts.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Alerts.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Alerts.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Alerts.type",
        "operator": "contains",
        "values": [
          "2",
          "8"
        ]
      }
    ]
  });
  this.SystemTotalPhoneCall = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Alerts.type",
        "operator": "equals",
        "values": [
          "3"
        ]
      }
    ]
  });
  this.SystemTotalEmail = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Alerts.type",
        "operator": "equals",
        "values": [
          "1"
        ]
      }
    ]
  });
  this.SystemTotalActions = new BehaviorSubject({
    "measures": [
      "Actions.count"
    ],
    "timeDimensions": [{
        "dimension": "Actions.createdat"
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      }
    ]
  });
  this.SystemTotalUsers = new BehaviorSubject({
    "measures": [
      "OrganisationUsers.count"
    ],
    "timeDimensions": [
      {
        "dimension": "OrganisationUsers.createddate"
      }
    ],
    "order": {},
    "filters": [
      {
        "member": "Organisations.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "OrganisationUsers.isdeleted",
        "operator": "equals",
        "values": [
          "0"
        ]
      },
      {
        "member": "Organisations.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ]
  });
  this.SystemTotalPeople = new BehaviorSubject({
    "measures": [
      "Peoples.count"
    ],
    "timeDimensions": [{
      "dimension": "Peoples.createddate"
    }],
    "order": {},
    "filters": [
      {
        "member": "Peoples.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Peoples.isdeleted",
        "operator": "equals",
        "values": [
          "0"
        ]
      },
      {
        "member": "Peoples.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ]
  });
  this.SystemTotalSite = new BehaviorSubject({
    "measures": [
      "Layers.count"
    ],
    "timeDimensions": [],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Layers.category",
        "operator": "equals",
        "values": [
          "1"
        ]
      },
      {
        "member": "Layers.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Layers.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ]
  });
  this.SystemTotalArea = new BehaviorSubject({
    "measures": [
      "Layers.count"
    ],
    "timeDimensions": [],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Layers.category",
        "operator": "equals",
        "values": [
          "2"
        ]
      },
      {
        "member": "Layers.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Layers.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ]
  });
  this.SystemTotalZone = new BehaviorSubject({
    "measures": [
      "Layers.count"
    ],
    "timeDimensions": [],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Layers.category",
        "operator": "equals",
        "values": [
          "3"
        ]
      },
      {
        "member": "Layers.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Layers.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ]
  });
  this.SystemTotalSource = new BehaviorSubject({
    "measures": [
      "Sources.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Sources.createddate"
      }
    ],
    "order": {},
    "filters": [
      {
        "member": "Sources.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      },
      {
        "member": "Sources.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      }
    ],
    "dimensions": []
  });
  }
  
  SiteSelected() {
    this.VehiclesEnter = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.orgSelected
          ]
        },
        {
          "member": "Tasks.sourcetype",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    }); 
    this.VehiclesExit = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.orgSelected
          ]
        },
        {
          "member": "Tasks.sourcetype",
          "operator": "contains",
          "values": [
            "exit"
          ]
        }
      ]
  });
  this.VehiclesTotal = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      }
    ]
  });
  this.VehiclesActivityEnter = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "History.triggercondition",
        "operator": "equals",
        "values": [
          "enter"
        ]
      }
    ]
  });
  this.VehiclesActivityExit = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "History.triggercondition",
        "operator": "equals",
        "values": [
          "exit"
        ]
      }
    ]
  });
  this.VehiclesActivityTotal = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      }
    ]
  });
  this.DevicesAlertsOnline = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      },
      {
        "member": "Tasks.metadata",
        "operator": "contains",
        "values": [
          "online"
        ]
      }
    ]
  });
  this.DevicesAlertsOffline = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      },
      {
        "member": "Tasks.metadata",
        "operator": "contains",
        "values": [
          "offline"
        ]
      }
    ]
  });
  this.DevicesAlertsTotal = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      }
    ]
  });
  this.DevicesActivityOnline = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      },
      {
        "member": "History.metadata",
        "operator": "contains",
        "values": [
          "online"
        ]
      }
    ]
  });
  this.DevicesActivityOffline = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      },
      {
        "member": "History.metadata",
        "operator": "contains",
        "values": [
          "offline"
        ]
      }
    ]
  });
  this.DevicesActivityTotal = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      }
    ]
  });
  this.PeopleAlertsEnter = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.peopleid",
        "operator": "set",
        "values": []
      },
      {
        "member": "Tasks.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Tasks.sourcetype",
        "operator": "contains",
        "values": [
          "enter"
        ]
      }
    ]
  });
  this.PeopleAlertsExit = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.peopleid",
        "operator": "set",
        "values": []
      },
      {
        "member": "Tasks.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Tasks.sourcetype",
        "operator": "contains",
        "values": [
          "exit"
        ]
      }
    ]
  });
  this.PeopleAlertsTotal = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "dimensions": [
      "Tasks.peopleid"
    ],
    "filters": [
      {
        "member": "Tasks.peopleid",
        "operator": "set",
        "values": []
      },
      {
        "member": "Tasks.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      }
    ]
  });
  this.PeopleActivityEnter = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "History.peopleid",
        "operator": "notContains",
        "values": [
          "0000"
        ]
      },
      {
        "member": "History.triggercondition",
        "operator": "contains",
        "values": [
          "enter"
        ]
      }
    ]
  });
  this.PeopleActivityExit = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "History.peopleid",
        "operator": "notContains",
        "values": [
          "0000"
        ]
      },
      {
        "member": "History.triggercondition",
        "operator": "contains",
        "values": [
          "exit"
        ]
      }
    ]
  });
  this.PeopleActivityTotal = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "History.peopleid",
        "operator": "notContains",
        "values": [
          "0000"
        ]
      }
    ]
  });
  this.SystemTotalSMS = new BehaviorSubject({
    "measures": [
      "Alerts.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Alerts.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Alerts.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Alerts.type",
        "operator": "contains",
        "values": [
          "2",
          "8"
        ]
      }
    ]
  });
  this.SystemTotalPhoneCall = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Alerts.type",
        "operator": "equals",
        "values": [
          "3"
        ]
      }
    ]
  });
  this.SystemTotalEmail = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Alerts.type",
        "operator": "equals",
        "values": [
          "1"
        ]
      }
    ]
  });
  this.SystemTotalActions = new BehaviorSubject({
    "measures": [
      "Actions.count"
    ],
    "timeDimensions": [{
        "dimension": "Actions.createdat"
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.orgSelected
        ]
      }
    ]
  });
  this.SystemTotalUsers = new BehaviorSubject({
    "measures": [
      "OrganisationUsers.count"
    ],
    "timeDimensions": [
      {
        "dimension": "OrganisationUsers.createddate"
      }
    ],
    "order": {},
    "filters": [
      {
        "member": "Organisations.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "OrganisationUsers.isdeleted",
        "operator": "equals",
        "values": [
          "0"
        ]
      },
      {
        "member": "Organisations.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ]
  });
  this.SystemTotalPeople = new BehaviorSubject({
    "measures": [
      "Peoples.count"
    ],
    "timeDimensions": [{
      "dimension": "Peoples.createddate"
    }],
    "order": {},
    "filters": [
      {
        "member": "Peoples.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Peoples.isdeleted",
        "operator": "equals",
        "values": [
          "0"
        ]
      },
      {
        "member": "Peoples.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ]
  });
  this.SystemTotalSite = new BehaviorSubject({
    "measures": [
      "Layers.count"
    ],
    "timeDimensions": [],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Layers.category",
        "operator": "equals",
        "values": [
          "1"
        ]
      },
      {
        "member": "Layers.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Layers.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ]
  });
  this.SystemTotalArea = new BehaviorSubject({
    "measures": [
      "Layers.count"
    ],
    "timeDimensions": [],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Layers.category",
        "operator": "equals",
        "values": [
          "2"
        ]
      },
      {
        "member": "Layers.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Layers.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ]
  });
  this.SystemTotalZone = new BehaviorSubject({
    "measures": [
      "Layers.count"
    ],
    "timeDimensions": [],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Layers.category",
        "operator": "equals",
        "values": [
          "3"
        ]
      },
      {
        "member": "Layers.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      },
      {
        "member": "Layers.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ]
  });
  this.SystemTotalSource = new BehaviorSubject({
    "measures": [
      "Sources.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Sources.createddate"
      }
    ],
    "order": {},
    "filters": [
      {
        "member": "Sources.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      },
      {
        "member": "Sources.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      }
    ],
    "dimensions": []
  });
  }
  dataOrgList= [];
  pushedOrgData = [];
  singleArrayOrgData: any;
  newSingleArrayOrgData:any = [];
  nameData;
  LayersNameArray = [];
  newDataList = [];
  siteObject = {};
  addingDataTOSites(){
    for( var l = 0; l < this.dataOrgList.length; l++ ){
      let dataList = this.dataOrgList[l];
      let Tenantkey = dataList['Layers.tenantkey'];
      let LayersName = dataList['Layers.name'];
      this.siteObject[LayersName] = [];
      this.VehiclesEnter = new BehaviorSubject({
        "measures": [
          "Tasks.count"
        ],
        "timeDimensions": [
          {
            "dimension": "Tasks.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {},
        "dimensions": [],
        "filters": [
          {
            "member":"Tasks.tenantkey",
            "operator":"contains",
            "values":[Tenantkey]
          },
          {
          "member":"Tasks.site",
          "operator":"equals",
          "values":[LayersName]
          },
          {
            "member": "Tasks.sourcetype",
            "operator": "contains",
            "values": [
              "enter"
            ]
          },
          {
            "member": "Tasks.identifiertype",
            "operator": "equals",
            "values": [
              "vehicle"
            ]
          }
        ]
      });
      this.VehiclesEnter.subscribe(VehiclesEnter => {
        this._VehiclesEnter = VehiclesEnter;
        this.cubejs.load(this._VehiclesEnter).subscribe(
          resultSet => {
            this.nData = resultSet.rawData();
            for( var i = 0; i < this.nData.length; i++ ){
              this.siteObject[LayersName].push(Object.assign({}, {["Alerts"]: {VehiclesEnter: this.nData[i]["Tasks.count"]}}));
            }
          },
          err => console.log("HTTP Error", err)
        );
      });
      this.VehiclesExit = new BehaviorSubject({
            "measures": [
              "Tasks.count"
            ],
            "timeDimensions": [
              {
                "dimension": "Tasks.createdat",
                "dateRange": this.selected
              }
            ],
            "order": {},
            "dimensions": [],
            "filters": [
              {
                "member": "Tasks.tenantkey",
                "operator": "contains",
                "values": [
                  Tenantkey
                ]
              },
              {
                "member": "Tasks.sourcetype",
                "operator": "contains",
                "values": [
                  "exit"
                ]
              },
              {
                "member": "Tasks.site",
                "operator": "equals",
                "values": [
                  LayersName
                ]
              },
              {
                "member": "Tasks.identifiertype",
                "operator": "equals",
                "values": [
                  "vehicle"
                ]
              }
            ]
        });
        this.VehiclesExit.subscribe(VehiclesExit => {
          this._VehiclesExit = VehiclesExit;
          this.cubejs.load(this._VehiclesExit).subscribe(
            resultSet => {
              this.nData = resultSet.rawData();
              for( var i = 0; i < this.nData.length; i++ ){
                this.siteObject[LayersName].push(Object.assign({}, {["Alerts"]: {VehiclesExit: this.nData[i]["Tasks.count"]}}));
              }
            },
            err => console.log("HTTP Error", err)
          );
        });
        this.VehiclesTotal = new BehaviorSubject({
            "measures": [
              "Tasks.count"
            ],
            "timeDimensions": [
              {
                "dimension": "Tasks.createdat",
                "dateRange": this.selected
              }
            ],
            "order": {},
            "dimensions": [],
            "filters": [
              {
                "member": "Tasks.tenantkey",
                "operator": "contains",
                "values": [
                  Tenantkey
                ]
              },
              {
                "member": "Tasks.site",
                "operator": "equals",
                "values": [
                  LayersName
                ]
              },
              {
                "member": "Tasks.identifiertype",
                "operator": "equals",
                "values": [
                  "vehicle"
                ]
              }
            ]
          });
          this.VehiclesTotal.subscribe(VehiclesTotal => {
            this._VehiclesTotal = VehiclesTotal;
            this.cubejs.load(this._VehiclesTotal).subscribe(
              resultSet => {
                this.nData = resultSet.rawData();
                for( var i = 0; i < this.nData.length; i++ ){
                  this.siteObject[LayersName].push(Object.assign({}, {["Alerts"]: {VehiclesTotal: this.nData[i]["Tasks.count"]}}));
                }
              },
              err => console.log("HTTP Error", err)
            );
          });
  this.VehiclesActivityEnter = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "History.triggercondition",
        "operator": "equals",
        "values": [
          "enter"
        ]
      },
      {
        "member": "History.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  this.VehiclesActivityEnter.subscribe(VehiclesActivityEnter => {
    this._VehiclesActivityEnter = VehiclesActivityEnter;
    this.cubejs.load(this._VehiclesActivityEnter).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["Activity"]: {VehiclesActivityEnter: this.nData[i]["History.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.VehiclesActivityExit = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "History.triggercondition",
        "operator": "equals",
        "values": [
          "exit"
        ]
      },
      {
        "member": "History.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  this.VehiclesActivityExit.subscribe(VehiclesActivityExit => {
    this._VehiclesActivityExit = VehiclesActivityExit;
    this.cubejs.load(this._VehiclesActivityExit).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["Activity"]: {VehiclesActivityExit: this.nData[i]["History.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.VehiclesActivityTotal = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "History.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  this.VehiclesActivityTotal.subscribe(VehiclesActivityTotal => {
    this._VehiclesActivityTotal = VehiclesActivityTotal;
    this.cubejs.load(this._VehiclesActivityTotal).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["Activity"]: {VehiclesActivityTotal: this.nData[i]["History.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.DevicesAlertsOnline = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      },
      {
        "member": "Tasks.metadata",
        "operator": "contains",
        "values": [
          "online"
        ]
      },
      {
        "member": "Tasks.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      }
    ]
  });
  this.DevicesAlertsOnline.subscribe(DevicesAlertsOnline => {
    this._DevicesAlertsOnline = DevicesAlertsOnline;
    this.cubejs.load(this._DevicesAlertsOnline).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["Alerts"]: {DevicesAlertsOnline: this.nData[i]["Tasks.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.DevicesAlertsOffline = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      },
      {
        "member": "Tasks.metadata",
        "operator": "contains",
        "values": [
          "offline"
        ]
      },
      {
        "member": "Tasks.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      }
    ]
  });
  this.DevicesAlertsOffline.subscribe(DevicesAlertsOffline => {
    this._DevicesAlertsOffline = DevicesAlertsOffline;
    this.cubejs.load(this._DevicesAlertsOffline).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["Alerts"]: {DevicesAlertsOffline: this.nData[i]["Tasks.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.DevicesAlertsTotal = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      },
      {
        "member": "Tasks.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      }
    ]
  });
  this.DevicesAlertsTotal.subscribe(DevicesAlertsTotal => {
    this._DevicesAlertsTotal = DevicesAlertsTotal;
    this.cubejs.load(this._DevicesAlertsTotal).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["Alerts"]: {DevicesAlertsTotal: this.nData[i]["Tasks.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.DevicesActivityOnline = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      },
      {
        "member": "History.metadata",
        "operator": "contains",
        "values": [
          "online"
        ]
      },
      {
        "member": "History.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      }
    ]
  });
  this.DevicesActivityOnline.subscribe(DevicesActivityOnline => {
    this._DevicesActivityOnline = DevicesActivityOnline;
    this.cubejs.load(this._DevicesActivityOnline).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["Activity"]: {DevicesActivityOnline: this.nData[i]["History.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.DevicesActivityOffline = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      },
      {
        "member": "History.metadata",
        "operator": "contains",
        "values": [
          "offline"
        ]
      },
      {
        "member": "History.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      }
    ]
  });
  this.DevicesActivityOffline.subscribe(DevicesActivityOffline => {
    this._DevicesActivityOffline = DevicesActivityOffline;
    this.cubejs.load(this._DevicesActivityOffline).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["Activity"]: {DevicesActivityOffline: this.nData[i]["History.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.DevicesActivityTotal = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      },
      {
        "member": "History.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      }
    ]
  });
  this.DevicesActivityTotal.subscribe(DevicesActivityTotal => {
    this._DevicesActivityTotal = DevicesActivityTotal;
    this.cubejs.load(this._DevicesActivityTotal).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["Activity"]: {DevicesActivityTotal: this.nData[i]["History.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.PeopleAlertsEnter = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.peopleid",
        "operator": "set",
        "values": []
      },
      {
        "member": "Tasks.tenantkey",
        "operator": "equals",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "Tasks.sourcetype",
        "operator": "contains",
        "values": [
          "enter"
        ]
      },
      {
        "member": "Tasks.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      }
    ]
  });
  this.PeopleAlertsEnter.subscribe(PeopleAlertsEnter => {
    this._PeopleAlertsEnter = PeopleAlertsEnter;
    this.cubejs.load(this._PeopleAlertsEnter).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["Alerts"]: {PeopleAlertsEnter: this.nData[i]["Tasks.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.PeopleAlertsExit = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.peopleid",
        "operator": "set",
        "values": []
      },
      {
        "member": "Tasks.tenantkey",
        "operator": "equals",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "Tasks.sourcetype",
        "operator": "contains",
        "values": [
          "exit"
        ]
      },
      {
        "member": "Tasks.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      }
    ]
  });
  this.PeopleAlertsExit.subscribe(PeopleAlertsExit => {
    this._PeopleAlertsExit = PeopleAlertsExit;
    this.cubejs.load(this._PeopleAlertsExit).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["Alerts"]: {PeopleAlertsExit: this.nData[i]["Tasks.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.PeopleAlertsTotal = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "dimensions": [
      "Tasks.peopleid"
    ],
    "filters": [
      {
        "member": "Tasks.peopleid",
        "operator": "set",
        "values": []
      },
      {
        "member": "Tasks.tenantkey",
        "operator": "equals",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "Tasks.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      }
    ]
  });
  this.PeopleAlertsTotal.subscribe(PeopleAlertsTotal => {
    this._PeopleAlertsTotal = PeopleAlertsTotal;
    this.cubejs.load(this._PeopleAlertsTotal).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["Alerts"]: {PeopleAlertsTotal: this.nData[i]["Tasks.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.PeopleActivityEnter = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "History.peopleid",
        "operator": "notContains",
        "values": [
          "0000"
        ]
      },
      {
        "member": "History.triggercondition",
        "operator": "contains",
        "values": [
          "enter"
        ]
      },
      {
        "member": "History.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      }
    ]
  });
  this.PeopleActivityEnter.subscribe(PeopleActivityEnter => {
    this._PeopleActivityEnter = PeopleActivityEnter;
    this.cubejs.load(this._PeopleActivityEnter).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["Activity"]: {PeopleActivityEnter: this.nData[i]["History.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.PeopleActivityExit = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "History.peopleid",
        "operator": "notContains",
        "values": [
          "0000"
        ]
      },
      {
        "member": "History.triggercondition",
        "operator": "contains",
        "values": [
          "exit"
        ]
      },
      {
        "member": "History.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      }
    ]
  });
  this.PeopleActivityExit.subscribe(PeopleActivityExit => {
    this._PeopleActivityExit = PeopleActivityExit;
    this.cubejs.load(this._PeopleActivityExit).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["Activity"]: {PeopleActivityExit: this.nData[i]["History.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.PeopleActivityTotal = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "History.peopleid",
        "operator": "notContains",
        "values": [
          "0000"
        ]
      },
      {
        "member": "History.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      }
    ]
  });
  this.PeopleActivityTotal.subscribe(PeopleActivityTotal => {
    this._PeopleActivityTotal = PeopleActivityTotal;
    this.cubejs.load(this._PeopleActivityTotal).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["Activity"]: {PeopleActivityTotal: this.nData[i]["History.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.SystemTotalSMS = new BehaviorSubject({
    "measures": [
      "Alerts.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Alerts.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Alerts.tenantkey",
        "operator": "contains",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "Alerts.type",
        "operator": "contains",
        "values": [
          "2",
          "8"
        ]
      }
    ]
  });
  this.SystemTotalSMS.subscribe(SystemTotalSMS => {
    this._SystemTotalSMS = SystemTotalSMS;
    this.cubejs.load(this._SystemTotalSMS).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["System"]: {SystemTotalSMS: this.nData[i]["Alerts.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.SystemTotalPhoneCall = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "Alerts.type",
        "operator": "equals",
        "values": [
          "3"
        ]
      },
      {
        "member": "Tasks.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      }
    ]
  });
  this.SystemTotalPhoneCall.subscribe(SystemTotalPhoneCall => {
    this._SystemTotalPhoneCall = SystemTotalPhoneCall;
    this.cubejs.load(this._SystemTotalPhoneCall).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["System"]: {SystemTotalPhoneCall: this.nData[i]["Tasks.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.SystemTotalEmail = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "Alerts.type",
        "operator": "equals",
        "values": [
          "1"
        ]
      },
      {
        "member": "Tasks.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      }
    ]
  });
  this.SystemTotalEmail.subscribe(SystemTotalEmail => {
    this._SystemTotalEmail = SystemTotalEmail;
    this.cubejs.load(this._SystemTotalEmail).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["System"]: {SystemTotalEmail: this.nData[i]["Tasks.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.SystemTotalActions = new BehaviorSubject({
    "measures": [
      "Actions.count"
    ],
    "timeDimensions": [{
        "dimension": "Actions.createdat"
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "Tasks.site",
        "operator": "equals",
        "values": [
          LayersName
        ]
      }
    ]
  });
  this.SystemTotalActions.subscribe(SystemTotalActions => {
    this._SystemTotalActions = SystemTotalActions;
    this.cubejs.load(this._SystemTotalActions).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["System"]: {SystemTotalActions: this.nData[i]["Actions.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.SystemTotalUsers = new BehaviorSubject({
    "measures": [
      "OrganisationUsers.count"
    ],
    "timeDimensions": [
      {
        "dimension": "OrganisationUsers.createddate"
      }
    ],
    "order": {},
    "filters": [
      {
        "member": "Organisations.tenantkey",
        "operator": "equals",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "OrganisationUsers.isdeleted",
        "operator": "equals",
        "values": [
          "0"
        ]
      },
      {
        "member": "Organisations.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ]
  });
  this.SystemTotalUsers.subscribe(SystemTotalUsers => {
    this._SystemTotalUsers = SystemTotalUsers;
    this.newCubeJs.load(this._SystemTotalUsers).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["System"]: {SystemTotalUsers: this.nData[i]["OrganisationUsers.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.SystemTotalPeople = new BehaviorSubject({
    "measures": [
      "Peoples.count"
    ],
    "timeDimensions": [{
      "dimension": "Peoples.createddate"
    }],
    "order": {},
    "filters": [
      {
        "member": "Peoples.tenantkey",
        "operator": "equals",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "Peoples.isdeleted",
        "operator": "equals",
        "values": [
          "0"
        ]
      },
      {
        "member": "Peoples.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ]
  });
  this.SystemTotalPeople.subscribe(SystemTotalPeople => {
    this._SystemTotalPeople = SystemTotalPeople;
    this.newCubeJs.load(this._SystemTotalPeople).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["System"]: {SystemTotalPeople: this.nData[i]["Peoples.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.SystemTotalSite = new BehaviorSubject({
    "measures": [
      "Layers.count"
    ],
    "timeDimensions": [],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Layers.category",
        "operator": "equals",
        "values": [
          "1"
        ]
      },
      {
        "member": "Layers.tenantkey",
        "operator": "equals",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "Layers.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ]
  });
  this.SystemTotalSite.subscribe(SystemTotalSite => {
    this._SystemTotalSite = SystemTotalSite;
    this.newCubeJs.load(this._SystemTotalSite).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["System"]: {SystemTotalSite: this.nData[i]["Layers.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.SystemTotalArea = new BehaviorSubject({
    "measures": [
      "Layers.count"
    ],
    "timeDimensions": [],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Layers.category",
        "operator": "equals",
        "values": [
          "2"
        ]
      },
      {
        "member": "Layers.tenantkey",
        "operator": "equals",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "Layers.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ]
  });
  this.SystemTotalArea.subscribe(SystemTotalArea => {
    this._SystemTotalArea = SystemTotalArea;
    this.newCubeJs.load(this._SystemTotalArea).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["System"]: {SystemTotalArea: this.nData[i]["Layers.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.SystemTotalZone = new BehaviorSubject({
    "measures": [
      "Layers.count"
    ],
    "timeDimensions": [],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Layers.category",
        "operator": "equals",
        "values": [
          "3"
        ]
      },
      {
        "member": "Layers.tenantkey",
        "operator": "equals",
        "values": [
          Tenantkey
        ]
      },
      {
        "member": "Layers.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ]
  });
  this.SystemTotalZone.subscribe(SystemTotalZone => {
    this._SystemTotalZone = SystemTotalZone;
    this.newCubeJs.load(this._SystemTotalZone).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          this.siteObject[LayersName].push(Object.assign({}, {["System"]: {SystemTotalZone: this.nData[i]["Layers.count"]}}));
        }
      },
      err => console.log("HTTP Error", err)
    );
  });
  this.SystemTotalSource = new BehaviorSubject({
    "measures": [
      "Sources.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Sources.createddate"
      }
    ],
    "order": {},
    "filters": [
      {
        "member": "Sources.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      },
      {
        "member": "Sources.tenantkey",
        "operator": "equals",
        "values": [
          this.orgSelected
        ]
      }
    ],
    "dimensions": []
  });
      this.SystemTotalSource.subscribe(SystemTotalSource => {
        this._SystemTotalSource = SystemTotalSource;
        this.newCubeJs.load(this._SystemTotalSource).subscribe(
          resultSet => {
            this.nData = resultSet.rawData();
            for( var i = 0; i < this.nData.length; i++ ){
              this.siteObject[LayersName].push(Object.assign({}, {["System"]: {SystemTotalSource: this.nData[i]["Sources.count"]}}));
            }
          },
          err => console.log("HTTP Error", err)
        );
      });
    }
    if( this.siteObject ){
      setTimeout(()=>{
        this.loading = false;
      }, 5500 );
    }
  }
  public dataSource = [];
  nData = [];
  hreadingArray = [ "Vehicles", "Devices", "People" ];
  nextHreadingArray = [ "Enter", "Exit", "Total", "Online", "Offline", "Total", "Enter", "Exit", "Total" ];
  ActivityAlertData = [];
  FinalData = [];
  VehiclesEnterCount=0;
  VehiclesExitCount=0;
  VehiclesTotalCount=0;
  DevicesAlertsOnlineCount = 0;
  DevicesAlertsOfflineCount = 0;
  DevicesAlertsTotalCount = 0;
  PeopleAlertsEnterCount = 0;
  PeopleAlertsExitCount = 0;
  PeopleAlertsTotalCount = 0;
  VehiclesActivityEnterCount = 0;
  VehiclesActivityExitCount = 0;
  VehiclesActivityTotalCount = 0;
  DevicesActivityOnlineCount = 0;
  DevicesActivityOfflineCount = 0;
  DevicesActivityTotalCount = 0;
  PeopleActivityEnterCount = 0;
  PeopleActivityExitCount = 0;
  PeopleActivityTotalCount = 0;
  public _VehiclesEnter = null;
  public _VehiclesExit = null;
  public _VehiclesTotal = null;
  public _DevicesAlertsOnline = null;
  public _DevicesAlertsOffline = null;
  public _DevicesAlertsTotal = null;
  public _PeopleAlertsEnter = null;
  public _PeopleAlertsExit = null;
  public _PeopleAlertsTotal = null;
  public _VehiclesActivityEnter = null;
  public _VehiclesActivityExit = null;
  public _VehiclesActivityTotal = null;
  public _DevicesActivityOnline = null;
  public _DevicesActivityOffline = null;
  public _DevicesActivityTotal = null;
  public _PeopleActivityEnter = null;
  public _PeopleActivityExit = null;
  public _PeopleActivityTotal = null;
  
  ngOnInit(): void {
    if( this.selected == "Today" ){
      this.OrgSelected();
    }
    
    this._querySites.subscribe(querySites => {
      this.querySites = querySites;
      this.newCubeJs.load(this.querySites).subscribe(
        resultSet => {
          this.dataOrgList = resultSet.rawData();
            this.addingDataTOSites();
        },
        err => console.log("HTTP Error", err)
      );
    });
      this.VehiclesEnter.subscribe(VehiclesEnter => {
        this._VehiclesEnter = VehiclesEnter;
        this.cubejs.load(this._VehiclesEnter).subscribe(
          resultSet => {
            this.nData = resultSet.rawData();
            for( var i = 0; i < this.nData.length; i++ ){
              this.VehiclesEnterCount = this.nData[i]["Tasks.count"];
                // this.ActivityAlertData.push( {"History.count": this.nData[i]["Tasks.count"]});
            }
            // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
            //   return e;
            // });
          },
          err => console.log("HTTP Error", err)
        );
      });
    
    this.VehiclesExit.subscribe(VehiclesExit => {
      this._VehiclesExit = VehiclesExit;
      this.cubejs.load(this._VehiclesExit).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.VehiclesExitCount = this.nData[i]["Tasks.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.VehiclesTotal.subscribe(VehiclesTotal => {
      this._VehiclesTotal = VehiclesTotal;
      this.cubejs.load(this._VehiclesTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.VehiclesTotalCount = this.nData[i]["Tasks.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.DevicesAlertsOnline.subscribe(DevicesAlertsOnline => {
      this._DevicesAlertsOnline = DevicesAlertsOnline;
      this.cubejs.load(this._DevicesAlertsOnline).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.DevicesAlertsOnlineCount = this.nData[i]["Tasks.count"];
              // this.ActivityAlertData.push( {"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.DevicesAlertsOffline.subscribe(DevicesAlertsOffline => {
      this._DevicesAlertsOffline = DevicesAlertsOffline;
      this.cubejs.load(this._DevicesAlertsOffline).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.DevicesAlertsOfflineCount = this.nData[i]["Tasks.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.DevicesAlertsTotal.subscribe(DevicesAlertsTotal => {
      this._DevicesAlertsTotal = DevicesAlertsTotal;
      this.cubejs.load(this._DevicesAlertsTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
              this.DevicesAlertsTotalCount = this.nData[i]["Tasks.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.PeopleAlertsEnter.subscribe(PeopleAlertsEnter => {
      this._PeopleAlertsEnter = PeopleAlertsEnter;
      this.cubejs.load(this._PeopleAlertsEnter).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.PeopleAlertsEnterCount = this.nData[i]["Tasks.count"];
              // this.ActivityAlertData.push( {"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.PeopleAlertsExit.subscribe(PeopleAlertsExit => {
      this._PeopleAlertsExit = PeopleAlertsExit;
      this.cubejs.load(this._PeopleAlertsExit).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.PeopleAlertsExitCount = this.nData[i]["Tasks.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.PeopleAlertsTotal.subscribe(PeopleAlertsTotal => {
      this._PeopleAlertsTotal = PeopleAlertsTotal;
      this.cubejs.load(this._PeopleAlertsTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.PeopleAlertsTotalCount = this.nData[i]["Tasks.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.VehiclesActivityEnter.subscribe(VehiclesActivityEnter => {
      this._VehiclesActivityEnter = VehiclesActivityEnter;
      this.cubejs.load(this._VehiclesActivityEnter).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.VehiclesActivityEnterCount = this.nData[i]["History.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.VehiclesActivityExit.subscribe(VehiclesActivityExit => {
      this._VehiclesActivityExit = VehiclesActivityExit;
      this.cubejs.load(this._VehiclesActivityExit).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.VehiclesActivityExitCount = this.nData[i]["History.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.VehiclesActivityTotal.subscribe(VehiclesActivityTotal => {
      this._VehiclesActivityTotal = VehiclesActivityTotal;
      this.cubejs.load(this._VehiclesActivityTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.VehiclesActivityTotalCount = this.nData[i]["History.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.DevicesActivityOnline.subscribe(DevicesActivityOnline => {
      this._DevicesActivityOnline = DevicesActivityOnline;
      this.cubejs.load(this._DevicesActivityOnline).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.DevicesActivityOnlineCount = this.nData[i]["History.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.DevicesActivityOffline.subscribe(DevicesActivityOffline => {
      this._DevicesActivityOffline = DevicesActivityOffline;
      this.cubejs.load(this._DevicesActivityOffline).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.DevicesActivityOfflineCount = this.nData[i]["History.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.DevicesActivityTotal.subscribe(DevicesActivityTotal => {
      this._DevicesActivityTotal = DevicesActivityTotal;
      this.cubejs.load(this._DevicesActivityTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.DevicesActivityTotalCount = this.nData[i]["History.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.PeopleActivityEnter.subscribe(PeopleActivityEnter => {
      this._PeopleActivityEnter = PeopleActivityEnter;
      this.cubejs.load(this._PeopleActivityEnter).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.PeopleActivityEnterCount = this.nData[i]["History.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.PeopleActivityExit.subscribe(PeopleActivityExit => {
      this._PeopleActivityExit = PeopleActivityExit;
      this.cubejs.load(this._PeopleActivityExit).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.PeopleActivityExitCount = this.nData[i]["History.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.PeopleActivityTotal.subscribe(PeopleActivityTotal => {
      this._PeopleActivityTotal = PeopleActivityTotal;
      this.cubejs.load(this._PeopleActivityTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.PeopleActivityTotalCount = this.nData[i]["History.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.SystemTotalSMS.subscribe(SystemTotalSMS => {
      this._SystemTotalSMS = SystemTotalSMS;
      this.cubejs.load(this._SystemTotalSMS).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.SystemTotalSMSCount = this.nData[i]["Alerts.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.SystemTotalPhoneCall.subscribe(SystemTotalPhoneCall => {
      this._SystemTotalPhoneCall = SystemTotalPhoneCall;
      this.cubejs.load(this._SystemTotalPhoneCall).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.SystemTotalPhoneCallCount = this.nData[i]["Tasks.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.SystemTotalEmail.subscribe(SystemTotalEmail => {
      this._SystemTotalEmail = SystemTotalEmail;
      this.cubejs.load(this._SystemTotalEmail).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.SystemTotalEmailCount = this.nData[i]["Tasks.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.SystemTotalActions.subscribe(SystemTotalActions => {
      this._SystemTotalActions = SystemTotalActions;
      this.cubejs.load(this._SystemTotalActions).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.SystemTotalActionsCount = this.nData[i]["Actions.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.SystemTotalUsers.subscribe(SystemTotalUsers => {
      this._SystemTotalUsers = SystemTotalUsers;
      this.newCubeJs.load(this._SystemTotalUsers).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.SystemTotalUsersCount = this.nData[i]["OrganisationUsers.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    
    this.SystemTotalPeople.subscribe(SystemTotalPeople => {
      this._SystemTotalPeople = SystemTotalPeople;
      this.newCubeJs.load(this._SystemTotalPeople).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.SystemTotalPeopleCount = this.nData[i]["Peoples.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.SystemTotalSite.subscribe(SystemTotalSite => {
      this._SystemTotalSite = SystemTotalSite;
      this.newCubeJs.load(this._SystemTotalSite).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.SystemTotalSiteCount = this.nData[i]["Layers.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.SystemTotalArea.subscribe(SystemTotalArea => {
      this._SystemTotalArea = SystemTotalArea;
      this.newCubeJs.load(this._SystemTotalArea).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.SystemTotalAreaCount = this.nData[i]["Layers.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.SystemTotalZone.subscribe(SystemTotalZone => {
      this._SystemTotalZone = SystemTotalZone;
      this.newCubeJs.load(this._SystemTotalZone).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.SystemTotalZoneCount = this.nData[i]["Layers.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.SystemTotalSource.subscribe(SystemTotalSource => {
      this._SystemTotalSource = SystemTotalSource;
      this.newCubeJs.load(this._SystemTotalSource).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.SystemTotalSourceCount = this.nData[i]["Sources.count"];
              // this.ActivityAlertData.push({"History.count": this.nData[i]["Tasks.count"]});
          }
          // this.ActivityAlertData = this.ActivityAlertData.map(function(e) {
          //   return e;
          // });
        },
        err => console.log("HTTP Error", err)
      );
    });
    
    if( this.orgSelected == this.href ){
      this._queryOrganisation.subscribe(querySite => {
        this.queryOrganisation = querySite;
        this.newCubeJs.load(this.queryOrganisation).subscribe(
          resultSet => {
            this.Organisation = resultSet.rawData();
            
            // this.dataSource = [ {"Layers.name": "All", "Layers.tenantkey": this.href}];
            // if( this.siteSelected != "All" )
            //   this.setSites();
          },
          err => console.log("HTTP Error", err)
        );
      });
    }
  }
  Organisation = [];
  SystemTotalSMSCount = 0;
  SystemTotalPhoneCallCount = 0;
  SystemTotalEmailCount = 0;
  SystemTotalActionsCount = 0;
  SystemTotalUsersCount = 0;
  SystemTotalPeopleCount = 0;
  SystemTotalSiteCount = 0;
  SystemTotalAreaCount = 0;
  SystemTotalZoneCount = 0;
  SystemTotalSourceCount = 0;
  public _SystemTotalSMS = null;
  public _SystemTotalPhoneCall =null;
  public _SystemTotalEmail = null;
  public _SystemTotalActions = null;
  public _SystemTotalUsers = null;
  public _SystemTotalPeople = null;
  public _SystemTotalSite = null;
  public _SystemTotalArea = null;
  public _SystemTotalZone = null;
  public _SystemTotalSource = null;
  loading = true;
  setSites(){
    this._querySites.subscribe(querySite => {
      this.querySites = querySite;
      this.newCubeJs.load(this.querySites).subscribe(
        resultSet => {
          this.dataSource = resultSet.rawData();
          this.dataSource.push( {"Layers.name": "All", "Layers.tenantkey": this.href});
          this.dataSource.sort(this.dynamicSort("Layers.name"));
        },
        err => console.log("HTTP Error", err)
      );
    });
  }
  
  dynamicSort(property) {
    var sortOrder = 1;
    
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }        
    }
}
}

