import { Component, Input, Output } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-call-alerts-cards',
  templateUrl: './call-alerts-cards.component.html',
  styleUrls: ['./call-alerts-cards.component.scss']
})
export class CallAlertsCardsComponent {
  constructor(private cubejs: CubejsClient) {}
  @Input() set query(query: object) {
    this.loading = true;
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = [];
        this.newCount = 0;
        this.normalCount = 0;
        this.highCount = 0;
        this.criticalCount = 0;
        this.resolvedCount = 0;
        let normalData = false;
        let highData = false;
        let criticalData = false;
        this.dataSource = resultSet.tablePivot();
        this.newDataSource = [
          {"Tasks.priority": "Total", "Tasks.count": 0},
          {"Tasks.priority": "New", "Tasks.count": 0},
          {"Tasks.priority": "Resolved", "Tasks.count": 0}
        ];
    
        for (let index in this.dataSource) { 
          if (this.dataSource[index]['Tasks.priority'] == 200) normalData = true;
          if (this.dataSource[index]['Tasks.priority'] == 300) highData = true;
          if (this.dataSource[index]['Tasks.priority'] == 400) criticalData = true;
        }
    
        if (!normalData) this.newDataSource.push({"Tasks.priority": "Normal", "Tasks.count": 0});
        if (!highData) this.newDataSource.push({"Tasks.priority": "High", "Tasks.count": 0});
        if (!criticalData) this.newDataSource.push({"Tasks.priority": "Critical", "Tasks.count": 0});
    
        for (let i = 0; i < this.dataSource.length; i++) {
          const ndata = this.dataSource[i];
          if (ndata['Tasks.priority'] == "200") {
            this.newDataSource.push({"Tasks.priority": "Normal", "Tasks.status": ndata['Tasks.status'], "Tasks.count": ndata['Tasks.count']});
          }
          if (ndata['Tasks.priority'] == "300") {
            this.newDataSource.push({"Tasks.priority": "High", "Tasks.status": ndata['Tasks.status'], "Tasks.count": ndata['Tasks.count']});
          }
          if (ndata['Tasks.priority'] == "400") {
            const status = ndata['Tasks.status'] || 0;
            this.newDataSource.push({"Tasks.priority": "Critical", "Tasks.status": status, "Tasks.count": ndata['Tasks.count']});
          }
        }
    
        this.newDataSource.forEach(element => {
          switch(element["Tasks.status"]) {
            case "100":
              this.newCount += element['Tasks.count'];
              break;
            case "200":
              this.resolvedCount += element['Tasks.count'];
              break;
          }
    
          switch(element["Tasks.priority"]) {
            case "Normal":
              this.normalCount += element['Tasks.count'];
              element['Tasks.count'] = this.normalCount;
              break;
            case "High":
              this.highCount += element['Tasks.count'];
              break;
            case "Critical":
              this.criticalCount += element['Tasks.count'];
              element['Tasks.count'] = this.criticalCount;
              break;
          }
        });
    
        this.newDataSource.forEach(element => {
          switch(element['Tasks.priority']) {
            case "New":
            case "Total":
              element['Tasks.count'] = this.newCount + this.normalCount + this.highCount + this.criticalCount;
              break;
            case "Resolved":
              element['Tasks.count'] = this.resolvedCount;
              break;
          }
        });
    
        // Sorting to set order: Critical -> High -> Normal
        const priorityOrder = ["Critical", "High", "Normal"];
        this.newDataSource.sort((a, b) => {
          return priorityOrder.indexOf(a['Tasks.priority']) - priorityOrder.indexOf(b['Tasks.priority']);
        });
    
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
    console.log(this.newDataSource);
  };
  @Input() set queryVehicles(queryVehicles: object) {
    this.loading = true;
    this.cubejs.load(queryVehicles).subscribe(
      resultSet => {
        this.dataSource = resultSet.tablePivot();
        for( var i=0; i<this.dataSource.length;i++ ){
          var ndata = this.dataSource[i];
          this.vehiclesCount = ndata['Tasks.count'];
        }
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
  };
  @Input() set queryDevice(query: object) {
    this.loading = true;
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = resultSet.tablePivot();
        for( var i=0; i<this.dataSource.length;i++ ){
          var ndata = this.dataSource[i];
          this.devicesCount = ndata['Tasks.count'];
        }
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
  };
  @Input() set queryPeople(query: object) {
    this.loading = true;
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = resultSet.tablePivot();
        for( var i=0; i<this.dataSource.length;i++ ){
          var ndata = this.dataSource[i];
          this.peopleCount = ndata['Tasks.count'];
        }
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
  };
  @Input() limit: number;
  @Output() pageEvent = new EventEmitter();
  @Output() sortingChanged = new EventEmitter();
  loading = true;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25];
  dataSource = [];
  newCount=0;
  resolvedCount=0;
  normalCount=0;
  highCount=0;
  criticalCount=0;
  vehiclesCount = 0;
  devicesCount = 0;
  peopleCount = 0;
  newDataSource = [];
  displayedColumns = ['Site', 'Count'];
  changeSorting(value) {
    this.sortingChanged.emit(value)
  }
}
