import { Component } from '@angular/core';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public links = [
    // {name: 'Dashboard', href: '/', icon: 'dashboard'},
    {name: 'Activity', href: '/activity', icon: 'assignment'},
    {name: 'Call-Alerts', href: '/call-alerts', icon: 'assignment'},
    // {name: 'Report', href: '/line-chart', icon: 'assignment'}
    ];
  title = 'Angular Material UI Dashboard';
}
