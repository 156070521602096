import { Component, Input, Output, OnInit  } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-vehicles-euro-status',
  templateUrl: './vehicles-euro-status.component.html',
  styleUrls: ['./vehicles-euro-status.component.scss']
})
export class VehiclesEuroStatusComponent {
  constructor(private cubejs: CubejsClient) {}
  @Input() set query(query: object) {
    this.loading = true;
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = resultSet.tablePivot();
        this.newCount = 0;
        this.count = 0;
        
        for( var i=0; i<this.dataSource.length;i++ ){
          var ndata = this.dataSource[i];
          if( ndata['History.vehicleeurostatus'] == null ){
            this.count = ndata['History.count'] + this.count;
            this.unknown = true;
          }else if( ndata['History.vehicleeurostatus'] == "" ){
            this.unknown = true;
            this.count = ndata['History.count'] + this.count;
          }else if( ndata['History.vehicleeurostatus'].toUpperCase().includes( "EURO 6") || ndata['History.vehicleeurostatus'].toUpperCase().includes( "EURO6")){
            this.newCount = ndata['History.count'] + this.newCount;
            this.includeCount = true;
            // this.dataSource = this.dataSource.filter(item => item !== ndata);
              // this.dataSource.splice(i,1);
          }
        }
        

        console.log(this.dataSource);

        this.dataSource.forEach((element,index)=>{
          console.log( this.count );
          if( this.unknown == true ){
            this.unknown = false;
            element['History.vehicleeurostatus'] = "OTHER/UNKNOWN";
            element['History.count'] = this.count;
          }
          
          if( element['History.vehicleeurostatus'].toUpperCase().includes( "EURO 6") || element['History.vehicleeurostatus'].toUpperCase().includes( "EURO6") ){
            this.includeCount = false;
            element['History.vehicleeurostatus'] = "Euro 6";
            element['History.count'] = this.newCount;
            this.dataSource.splice(index,1);
          }
          
       });

       this.dataSource.sort(function(a, b) {
        var textA = a['History.vehicleeurostatus'].toUpperCase();
        var textB = b['History.vehicleeurostatus'].toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

      // console.log( this.dataSource );
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
  };
  @Input() limit: number;
  @Output() pageEvent = new EventEmitter();
  @Output() sortingChanged = new EventEmitter();
  loading = false;
  length = 0;
  pageSize = 10;
  count = 0;
  includeCount = false;
  unknown = false;
  newCount = 0;
  pageSizeOptions: number[] = [5, 10, 25];
  dataSource = [];
  displayedColumns = ['Site', 'Count'];
  changeSorting(value) {
    this.sortingChanged.emit(value)
  }
}
