import { Component, OnInit, Input } from "@angular/core";
import { CubejsClient } from '@cubejs-client/ngx';
import {formatDate, registerLocaleData} from "@angular/common"
import localeEn from '@angular/common/locales/en';
import { Chart } from 'chart.js';
import * as annotations from 'chartjs-plugin-annotation';

registerLocaleData(localeEn);

@Component({
  selector: 'app-euro-fuel-type',
  templateUrl: './euro-fuel-type.component.html',
  styleUrls: ['./euro-fuel-type.component.scss']
})
export class EuroFuelTypeComponent implements OnInit {
  constructor(private cubejs:CubejsClient){}
  dataSource = [];
  euro4Count = 0;
  euro4 = [];
  noneEuro4Count = 0;
  noneEuro4 = [];
  canvas: any;
  ctx: any;
  data;
  myChart;
  id;
  newQuery;
  neweuro4Count = 0;
  newNoneEuro4Count = 0;
  total;
  @Input() set upToEuro4Count(upToEuro4Count: object) {
    this.newNoneEuro4Count = Number(upToEuro4Count);
    this.ngOnInit();
  }

  @Input() set euro4Plus(euro4Plus: object) {
    this.neweuro4Count = Number(euro4Plus);
    this.ngOnInit();
  }

  @Input() set query(query: object) {
    this.query = query;
  }

  ngOnInit() {
        this.euro4Count = 0;
        this.euro4 = [];
        this.noneEuro4 = [];
        this.total = this.neweuro4Count + this.newNoneEuro4Count;
        this.euro4Count = (this.neweuro4Count/this.total)*100;
        this.noneEuro4Count = (this.newNoneEuro4Count/this.total)*100;
        this.euro4.push( this.euro4Count );
        this.noneEuro4.push( this.noneEuro4Count );
        if( this.noneEuro4.length > 0 ){
          this.loading = false;
        }

        this.barChartOptions = {
          responsive: true,
          indexAxis: 'y',
          scales: {
            xAxes: [{
              stacked: true,
              ticks: {
                precision: 0
              }
            }],
            yAxes: [{
              stacked: true,
              display: false,
              ticks: {
                display: false
              }
            }]
          },
          tooltips: {
            enabled: true,
            callbacks: {
              label: (tooltipItem, data) => {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                let dataValue = dataset.data[tooltipItem.index] || 0;
                let label = dataset.label || '';
                
                return `${label}: ${dataValue.toFixed(2)}%`;
              }
            }
          },
          legend: {
            position: 'top',
            align: 'end',
            labels: {
              usePointStyle: true,
              fontColor: 'white'
            }
          },
          title: {
            display: false,
            text: 'Petrol'
          }
        };
        
        this.barChartLabels = [''];
        this.barChartType = 'bar';
        this.barChartLegend = true;
        
        this.barChartData = [
          { data: this.noneEuro4, label: 'Euro 4-', backgroundColor: '#b9c9d6', borderWidth: 1 },
          { data: this.euro4, label: 'Euro 4+', backgroundColor: '#51789a', borderWidth: 1 }
        ];
        
      
  }
  public barChartOptions;
  public barChartData;
  public barChartLabels = [''];
  public barChartType = 'bar';
  public barChartLegend = true;
  loading = true;
}
