<div class="component-header d-flex align-items-center justify-content-between">
    <h4><i class="fas fa-users"></i>Global Stats</h4>
    <div class="date-Picker-wrap d-flex align-items-center ">
      <select class="date-Picker" (change)="selectOption($event.target.value)">
        <option [value]="date.id" *ngFor="let date of dates">{{date.name}}</option>
      </select>
      <select class="date-Picker" (change)="selectOption1($event.target.value)">
        <option value="All">All</option>
        <option value="{{element['Layers.name']}}" [ngValue]="siteSelected" *ngFor="let element of dataSource">{{element['Layers.name']}}</option>
      </select>
    </div>
  </div>
  
    
    
    <div class="table-warp">
      <div class="col-6" style="margin-top: 20px;float: left;">
        <div class="custom-table">
          <div class="table-header-row">
            <div class="cell-wrap flex-fill">Sources</div>
              <div class="cell-wrap text-right" style="width: 80px;">Count</div>
          </div>
          <div class="table-inner-wrapper">
            <div class="table-row-wrapper" *ngFor="let element of sourceListing">
              <div class="table-row">
                <div class="cell-wrap flex-fill">Sources</div>
                <div class="cell-wrap text-right" style="width: 80px;">{{element['Sources.count']}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-table">
            <div class="table-header-row">
              <div class="cell-wrap flex-fill">Manual Events</div>
                <div class="cell-wrap text-right" style="width: 80px;">Count</div>
            </div>
            <div class="table-inner-wrapper">
              <div class="table-row-wrapper" *ngFor="let element of manualEventsListing">
                <div class="table-row">
                  <div class="cell-wrap flex-fill">{{element['History.identifiertype']}}</div>
                  <div class="cell-wrap text-right" style="width: 80px;">{{element['History.count']}}</div>
                </div>
              </div>
            </div>
        </div>
        <div class="custom-table">
            <div class="table-header-row">
              <div class="cell-wrap flex-fill">DVLA</div>
                <div class="cell-wrap text-right" style="width: 80px;">Count</div>
            </div>
            <div class="table-inner-wrapper">
              <div class="table-row-wrapper" *ngFor="let element of dvlaListing">
                <div class="table-row">
                  <div class="cell-wrap flex-fill">DVLA</div>
                  <div class="cell-wrap text-right" style="width: 80px;">{{element['History.count']}}</div>
                </div>
              </div>
            </div>
        </div>
        <div class="custom-table">
            <div class="table-header-row">
                <div class="cell-wrap flex-fill">Event Hour</div>
                <div class="cell-wrap flex-fill">Event Date</div>
                <div class="cell-wrap text-right" style="width: 80px;">Count</div>
            </div>
            <div class="table-inner-wrapper">
              <div class="table-row-wrapper" *ngFor="let element of perHourEventsListing">
                <div class="table-row">
                  <div class="cell-wrap flex-fill">{{element['History.createdat.hour'] | date:'shortTime'}}</div>
                  <div class="cell-wrap flex-fill" style="width: 80px;">{{element['History.createdat'] | date}}</div>
                  <div class="cell-wrap text-right" style="width: 80px;">{{element['History.count']}}</div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <!-- <app-people-messaging  [href]="href" [dateRange]="selected" [selectedSite]="siteSelected"></app-people-messaging> -->
    <div class="col-6" style="margin-top: 20px;float: left;">
        <div class="custom-table">
          <div class="table-header-row">
            <div class="cell-wrap flex-fill">Site</div>
            <div class="cell-wrap flex-fill">Priority</div>
              <div class="cell-wrap text-right" style="width: 80px;">Count</div>
          </div>
          <div class="table-inner-wrapper">
            <div class="table-row-wrapper" *ngFor="let element of taskSiteListing">
              <div class="table-row">
                <div class="cell-wrap flex-fill">{{element['Tasks.site']}}</div>
                <div class="cell-wrap flex-fill">{{element['Tasks.priority']}}</div>
                <div class="cell-wrap text-right" style="width: 80px;">{{element['Tasks.count']}}</div>
              </div>
            </div>
          </div>
        </div>
    </div>
    </div>
    