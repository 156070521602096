  <div class="table-warp">
    <div class="row narrow-row">
      <div class="col-12">
        <app-table-page *ngIf="currentUrl == 'activity'"></app-table-page>
        <app-call-alerts *ngIf="currentUrl == 'alerts'"></app-call-alerts>
        <app-vehicles *ngIf="currentUrl == 'vehicles'"></app-vehicles>
        <app-devices *ngIf="currentUrl == 'devices'"></app-devices>
        <app-people *ngIf="currentUrl == 'people'"></app-people>
        <app-bespoke *ngIf="currentUrl == 'bespoke'"></app-bespoke>
      </div>
    </div>
  </div>
  