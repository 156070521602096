<div class="example-loading-shade" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<div class="custom-table">
  <div class="table-header-row">
    <div class="cell-wrap flex-fill">People</div>
    <div class="cell-wrap text-right" style="width: 80px;">Count</div>
  </div>
  <div class="table-inner-wrapper">
    <div class="table-row-wrapper" *ngFor="let element of dataSource">
      <div class="table-row">
        <div class="cell-wrap flex-fill">{{element['History.identifiertype']}}</div>
        <div class="cell-wrap text-right" style="width: 80px;">{{element['History.count']}}</div>
      </div>
    </div>
  </div>
</div>
