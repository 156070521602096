<div>
  <canvas baseChart
          height="320"
          width="100%"
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [legend]="barChartLegend"
          [chartType]="barChartType">
  </canvas>
</div>
