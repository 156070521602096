<div class="table-warp mobile-nav mobile-view-table" style="background-color: #051f2c;padding: 0px 10px 0 10px;">
    <div class="row narrow-row">
      <div class="col-12">
        <div class="insights-cards-wrapper">
          <div class="row narrow-row">
            <div class="col-2">
              <a [routerLink]="[redirectLinkActivity, href]">
                <div class="insights-card"> <i class="fas fa-exchange"></i>
                  <!-- <h5>Activity</h5> -->
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[redirectLinkAlerts, href]">
                <div class="insights-card"> <i class="fas fa-bell"></i>
                  <!-- <h5>Alerts</h5> -->
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[redirectLinkVehicles, href]">
                <div class="insights-card"> <i class="fas fa-car"></i>
                  <!-- <h5>Vehicles</h5> -->
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[redirectLinkDevices, href]">
                <div class="insights-card"> <i class="fas fa-laptop"></i>
                  <!-- <h5>Devices</h5> -->
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[redirectLinkPeople, href]">
                <div class="insights-card"> <i class="fas fa-user"></i>
                  <!-- <h5>People</h5> -->
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[acessBespoke, href]">
                <div class="insights-card active"> <i class="fas fa-star"></i>
                  <!-- <h5>People</h5> -->
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<div class="component-header align-items-center justify-content-between" *ngIf="access" style="padding: 0px 0 10px 0;margin: 0 10px;margin-top: 55px;">
    <!-- <h4><i class="fas fa-bell"></i>Alerts Dashboard</h4> -->
    
    <div class="date-Picker-wrap align-items-center mobile-view-select">
      <select class="date-Picker" (change)="selectOption($event.target.value)" style="width:100%;margin: 10px 0 0 0px;">
        <option [value]="date.id" *ngFor="let date of dates">{{date.name}}</option>
      </select>
      <select class="date-Picker" (change)="selectOption1($event.target.value)" style="width:100%;margin: 10px 0 0 0px;">
        <option value="All">All</option>
        <option value="{{element['Layers.name']}}" [ngValue]="siteSelected" *ngFor="let element of dataset2">{{element['Layers.name']}}</option>
      </select>
    </div>
  </div>
  <div class="table-warp" *ngIf="denied" style="color: #FFF;padding: 0px 0 10px 0;margin: 0 10px;margin-top: 65px;padding: 80px;">
    <div class="row narrow-row">
        <div class="col-12">To enable bespoke reporting email: bespoke@intelico.com</div>
    </div>
</div>
<div class="table-warp mobile-view-table" *ngIf="access">
    <div class="row narrow-row">
        <div class="col-12 padding-l-r0">
            <div>
                <app-euro-type-mobile-cards [hybridElecData]="ElecHybridGasData" [volumnCount]="volumnCount" [upToEuro4Count]="upToEuro4Count" [euro4Plus]="euro4PlusCount" [upToEuro6Count]="upToEuro6Count" [euro6Plus]="euro6PlusCount" [dataSource]="dataSource"></app-euro-type-mobile-cards>
            </div>
        </div>

        <div class="col-12 fuel-type-main">
            <div class="fuel-type-g-main">
                <div class="fuel-type-g-title">Petrol Euro Standard</div>
                <app-euro-fuel-type [upToEuro4Count]="upToEuro4Count" [euro4Plus]="euro4PlusCount"></app-euro-fuel-type>
            </div>
        </div>
        <div class="col-12 fuel-type-d-main">
            <div class="fuel-type-d-g-main">
                <div class="fuel-type-d-g-title">Diesel Euro Standard</div>
                <app-euro-fuel-type6 [upToEuro6Count]="upToEuro6Count" [euro6Plus]="euro6PlusCount"></app-euro-fuel-type6>
            </div>
        </div>
        <div class="col-12 padding-l-r0 m-t-20">
            <app-euro-fuel-euro4-count class="col-12 padding-l-r0 display-block" [volumnCount]="volumnCount" [dataValue]="dataSource"  [query]="queryVehicelsFuelTypeList"></app-euro-fuel-euro4-count>
        </div>
        <div class="col-12 padding-l-r0 m-t-10">
            <app-euro-fuel-euro5-count class="col-12 padding-l-r0 display-block" [volumnCount]="volumnCount" [dataValue]="dataSource"  [query]="queryVehicelsFuelTypeEuro5List"></app-euro-fuel-euro5-count>
        </div>
        <div class="col-12 padding-l-r0 m-t-10">
            <app-euro-fuel-euro6-count class="col-12 padding-l-r0 display-block" [volumnCount]="volumnCount" [dataValue]="dataSource" [query]="queryVehicelsFuelTypeEuro6List"></app-euro-fuel-euro6-count>
        </div>
        <div class="col-12 padding-l-r0 m-t-10">
            <app-vahicles-make-mobile-graph class="col-12 padding-l-r0 display-block" [dataValue]="dataSource1" [query]="makePercentage"></app-vahicles-make-mobile-graph>
        </div>
    </div>
</div>
<div style="padding-bottom: 200px;"></div>