import { Component, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { CubejsClient } from "@cubejs-client/ngx";

@Component({
  selector: 'app-vehicles-mobile',
  templateUrl: './vehicles-mobile.component.html',
  styleUrls: ['./vehicles-mobile.component.scss']
})
export class VehiclesMobileComponent implements OnInit {
  cubeQuery = new BehaviorSubject(null);
  chartType = new BehaviorSubject(null);
  pivotConfig = new BehaviorSubject(null);
  constructor(private meta: Meta, private title: Title, private router: Router, private cubejs: CubejsClient) {
    document.body.style.backgroundColor = "#051F2C";
    this.title.setTitle('Angular Dashboard with Material');
    this.meta.addTag({ name: 'description', content: 'How to build Angular Material Data Table with Cube.js' });
    this.meta.addTag({ name: 'keywords', content: 'Angular, Cube.js, Data Table, Table, Material UI' });
  }
  public href: string = this.router.url.replace( "/", "" ).split("/")[1];
  public currentHref: string = this.router.url.replace( "/", "" ).split("/")[0];
  public limit = 10;
  public limit1 = 10;
  public limit2 = 10;
  public limit3 = 10;
  public limit4 = 10;
  public limit5 = 10;
  public limit6 = 10;
  public page = 0;
  public page1 = 0;
  public queryVehicelsFuelType = null;
  public queryVehicelsFuelTypePetrol = null;
  public queryVehicelsFuelTypeDiesel = null;
  public queryVehicelsFuelTypeHybrid = null;
  public queryVehicelsFuelTypeElectric = null;
  public queryVehicelsFuelTypeOther = null;
  public queryVehicelsFuelTypeTaxStatus = null;
  public queryVehicelsFuelTypeTaxed = null;
  public queryVehicelsFuelTypeUnTaxed = null;
  public queryVehicelsFuelTypeSorn = null;
  public queryVehicelsFuelTypeunknown = null;
  public queryVehicelsFuelTypeMot = null;
  public queryVehicelsFuelTypeValid = null;
  public queryVehicelsFuelTypeNotValid = null;
  public queryVehicelsFuelTypeMotUnknown = null;
  public queryVehicelsManufacturer = null;
  public queryVehicelsEuroStatus = null;
  public queryVehicelsType = null;
  public queryVehicelsTrafficFlow = null;
  public queryVehicelsColour = null;
  public queryMake = null;
  public querySites = null;
  public sorting = ['History.createdat', 'desc'];
  public startDate = "01/1/2019";
  public finishDate = "01/1/2022";
  reloadIframe(){
    window.parent.location.reload();
    window.opener.document.location.reload ()
  }
  dates: any[] = [
    { id: 'Today', name: 'Today' },
    { id: 'Yesterday', name: 'Yesterday' },
    { id: 'This week', name: 'This Week' },
    { id: 'Last week', name: 'Last Week' },
    { id: 'This month', name: 'This Month' },
    { id: 'Last month', name: 'Last Month' },
    { id: 'This Year', name: 'This Year' },
  ];
  selected: string = "Today";
  selectOption(id: string) {
    this.selected = id;
    this._queryMake = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.Make"
      ],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        }
      ]
    });
  this._queryVehicelsFuelType = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [
      "History.FuelType"
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  this._queryVehicelsFuelTypeTaxStatus = new BehaviorSubject({
    measures: ["History.count"],
    timeDimensions: [
      {
        dimension: "History.createdat",
        dateRange: this.selected
      }
    ],
    order: {},
    dimensions: ["History.Tax_Status"],
    filters: [
      {
        member: "History.tenantkey",
        operator: "contains",
        values: [this.href]
      },
      {
        member: "History.identifiertype",
        operator: "equals",
        values: ["vehicle"]
      }
    ]
  });
  this._queryVehicelsFuelTypeMot = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [
      "History.MOT_Status"
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  this._queryVehicelsType = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [
      "History.TypeApproval"
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  this._queryVehicelsManufacturer = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [
      "History.Make"
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  this._queryVehicelsEuroStatus = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [
      "History.EuroStatus"
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });

  this._queryVehicelsTrafficFlow = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": ["History.triggercondition"],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });

  this._queryVehicelsColour = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [
      "History.Colour"
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
    this.ngOnInit();
  }
  siteSelected: string = "All";
  selectOption1(id: string) {
    this.siteSelected = id;
    if( id == "All" ){
      this.selectOption(this.selected);
    }else{
      this._queryVehicelsFuelType = new BehaviorSubject({
          "measures": [
            "History.count"
          ],
          "timeDimensions": [
            {
              "dimension": "History.createdat",
              "dateRange": this.selected
            }
          ],
          "order": {},
          "dimensions": [
            "History.FuelType"
          ],
          "filters": [
            {
              "member": "History.tenantkey",
              "operator": "contains",
              "values": [
                this.href
              ]
            },
            {
              "member": "History.identifiertype",
              "operator": "equals",
              "values": [
                "vehicle"
              ]
            },
            {
              "member": "History.site",
              "operator": "equals",
              "values": [
                id
              ]
            }
          ]
      });
      this._queryMake = new BehaviorSubject({
          "measures": [
            "History.count"
          ],
          "timeDimensions": [
            {
              "dimension": "History.createdat",
              "dateRange": this.selected
            }
          ],
          "order": {},
          "dimensions": [
            "History.FuelType"
          ],
          "filters": [
            {
              "member": "History.tenantkey",
              "operator": "contains",
              "values": [
                this.href
              ]
            },
            {
              "member": "History.identifiertype",
              "operator": "equals",
              "values": [
                "vehicle"
              ]
            },
            {
              "member": "History.site",
              "operator": "equals",
              "values": [
                id
              ]
            }
          ]
      });
     
    this._queryVehicelsFuelTypeTaxStatus = new BehaviorSubject({
      measures: ["History.count"],
      timeDimensions: [
        {
          dimension: "History.createdat",
          dateRange: this.selected
        }
      ],
      order: {},
      dimensions: ["History.Tax_Status"],
      filters: [
        {
          member: "History.tenantkey",
          operator: "contains",
          values: [this.href]
        },
        {
          member: "History.identifiertype",
          operator: "equals",
          values: ["vehicle"]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this._queryVehicelsFuelTypeMot = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.MOT_Status"
      ],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this._queryVehicelsType = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.TypeApproval"
      ],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this._queryVehicelsManufacturer = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.Make"
      ],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this._queryVehicelsEuroStatus = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.EuroStatus"
      ],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });

    this._queryVehicelsTrafficFlow = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": ["History.triggercondition"],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this._queryVehicelsColour = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.Colour"
      ],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.ngOnInit();
    
  }
}
  public _queryMake = new BehaviorSubject(this.queryMakeJson());
  public _queryVehicelsFuelType = new BehaviorSubject(this.queryVehicelsFuelTypeQuery());
  public _queryVehicelsFuelTypeTaxStatus = new BehaviorSubject({
    measures: ["History.count"],
    timeDimensions: [
      {
        dimension: "History.createdat",
        dateRange: this.selected
      }
    ],
    order: {},
    dimensions: ["History.Tax_Status"],
    filters: [
      {
        member: "History.tenantkey",
        operator: "contains",
        values: [this.href]
      },
      {
        member: "History.identifiertype",
        operator: "equals",
        values: ["vehicle"]
      }
    ]
  });

  public _queryVehicelsFuelTypeMot = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [
      "History.MOT_Status"
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  
  public _queryVehicelsType = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [
      "History.TypeApproval"
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  public _queryVehicelsManufacturer = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [
      "History.Make"
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });

  public _queryVehicelsEuroStatus = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [
      "History.EuroStatus"
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  public _queryVehicelsTrafficFlow = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": ["History.triggercondition"],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  public _queryVehicelsColour = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [
      "History.Colour"
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  public queryVehicelsFuelTypeQuery(){
    return {
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.FuelType"
      ],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        }
      ]
    }
  }
  public queryMakeJson(){
    return {
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.Make"
      ],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        }
      ]
    }
  }

  public _querySites = new BehaviorSubject(
    {
      "measures": [],
      "timeDimensions": [
        {
          "dimension": "Layers.createddate"
        }
      ],
      "order": {},
      "filters": [
        {
          "member": "Layers.tenantkey",
          "operator": "equals",
          "values": [
            this.href
          ]
        },
        {
          "member": "Layers.category",
          "operator": "equals",
          "values": [
            "1"
          ]
        },
        {
          "member": "Layers.isdeleted",
          "operator": "equals",
          "values": [
            "false"
          ]
        }
      ],
      "dimensions": [
        "Layers.name"
      ]
    }
  );
 

  public sortingChangedVehicelsFuelType(value) {
    if (value === this.sorting[0] && this.sorting[1] === 'desc') {
      this.sorting[0] = value;
      this.sorting[1] = 'asc'
    } else if (value === this.sorting[0] && this.sorting[1] === 'asc') {
      this.sorting[0] = value;
      this.sorting[1] = 'desc'
    } else {
      this.sorting[0] = value;
    }
    this.sorting[0] = value;
    this._queryVehicelsFuelType.next({
      ...this._queryVehicelsFuelType.value,
      "order": {
        "Tasks.count": "desc"
      },
    });
  }

  
  public dateChangedVehicelsFuelType(value) {
    if (value.number === 0) {
      this.startDate = value.date
    }
    if (value.number === 1) {
      this.finishDate = value.date
    }
    this._queryVehicelsFuelType.next({
      ...this._queryVehicelsFuelType.value,
      "order": {
        "History.count": "desc"
      },
    });
  }

  public sortingChangedVehicelsFuelTypeTaxStatus(value) {
    if (value === this.sorting[0] && this.sorting[1] === 'desc') {
      this.sorting[0] = value;
      this.sorting[1] = 'asc'
    } else if (value === this.sorting[0] && this.sorting[1] === 'asc') {
      this.sorting[0] = value;
      this.sorting[1] = 'desc'
    } else {
      this.sorting[0] = value;
    }
    this.sorting[0] = value;
    this._queryVehicelsFuelTypeTaxStatus.next({
      ...this._queryVehicelsFuelTypeTaxStatus.value,
      "order": {
        "History.count": "desc"
      },
    });
  }

  public sortingChangedVehicelsFuelTypeMot(value) {
    if (value === this.sorting[0] && this.sorting[1] === 'desc') {
      this.sorting[0] = value;
      this.sorting[1] = 'asc'
    } else if (value === this.sorting[0] && this.sorting[1] === 'asc') {
      this.sorting[0] = value;
      this.sorting[1] = 'desc'
    } else {
      this.sorting[0] = value;
    }
    this.sorting[0] = value;
    this._queryVehicelsFuelTypeMot.next({
      ...this._queryVehicelsFuelTypeMot.value,
      "order": {
        "History.count": "desc"
      },
    });
  }

  public sortingChangedVehicelsType(value) {
    if (value === this.sorting[0] && this.sorting[1] === 'desc') {
      this.sorting[0] = value;
      this.sorting[1] = 'asc'
    } else if (value === this.sorting[0] && this.sorting[1] === 'asc') {
      this.sorting[0] = value;
      this.sorting[1] = 'desc'
    } else {
      this.sorting[0] = value;
    }
    this.sorting[0] = value;
    this._queryVehicelsType.next({
      ...this._queryVehicelsType.value,
      "order": {
        "History.count": "desc"
      },
    });
  }
  public sortingChangedVehicelsManufacturer(value) {
    if (value === this.sorting[0] && this.sorting[1] === 'desc') {
      this.sorting[0] = value;
      this.sorting[1] = 'asc'
    } else if (value === this.sorting[0] && this.sorting[1] === 'asc') {
      this.sorting[0] = value;
      this.sorting[1] = 'desc'
    } else {
      this.sorting[0] = value;
    }
    this.sorting[0] = value;
    this._queryVehicelsManufacturer.next({
      ...this._queryVehicelsManufacturer.value,
      "order": {
        "History.count": "desc"
      },
    });
  }
  
  public sortingChangedVehicelsEuroStatus(value) {
    if (value === this.sorting[0] && this.sorting[1] === 'desc') {
      this.sorting[0] = value;
      this.sorting[1] = 'asc'
    } else if (value === this.sorting[0] && this.sorting[1] === 'asc') {
      this.sorting[0] = value;
      this.sorting[1] = 'desc'
    } else {
      this.sorting[0] = value;
    }
    this.sorting[0] = value;
    this._queryVehicelsEuroStatus.next({
      ...this._queryVehicelsEuroStatus.value,
      "order": {
        "History.count": "desc"
      },
    });
  }
  
  public sortingChangedVehicelsTrafficFlow(value) {
    if (value === this.sorting[0] && this.sorting[1] === 'desc') {
      this.sorting[0] = value;
      this.sorting[1] = 'asc'
    } else if (value === this.sorting[0] && this.sorting[1] === 'asc') {
      this.sorting[0] = value;
      this.sorting[1] = 'desc'
    } else {
      this.sorting[0] = value;
    }
    this.sorting[0] = value;
    this._queryVehicelsEuroStatus.next({
      ...this._queryVehicelsEuroStatus.value,
      "order": {
        "History.count": "desc"
      },
    });
  }

  public sortingChangedVehicelsColour(value) {
    if (value === this.sorting[0] && this.sorting[1] === 'desc') {
      this.sorting[0] = value;
      this.sorting[1] = 'asc'
    } else if (value === this.sorting[0] && this.sorting[1] === 'asc') {
      this.sorting[0] = value;
      this.sorting[1] = 'desc'
    } else {
      this.sorting[0] = value;
    }
    this.sorting[0] = value;
    this._queryVehicelsEuroStatus.next({
      ...this._queryVehicelsEuroStatus.value,
      "order": {
        "History.count": "desc"
      },
    });
  }
  
  public dataSource = [];
  public newDataSource = [];
  public newDataSourceTax = [];
  name: string;
  public newCubeJs;
  public bespokeLink;
  public urlLink;
  public redirectLinkActivity;
  public redirectLinkAlerts;
  public redirectLinkVehicles;
  public redirectLinkDevices;
  public redirectLinkPeople;
  public redirectLinkBespoke;
  public redirectLinkPartners;
  public currentUrl;
  vehicelsTypeData: Array<{name: string}> = []; 
  ngOnInit(): void {
    this.urlLink = this.router.url;
      if(this.urlLink.includes("-")){
        this.redirectLinkActivity = "/activity-mobile-s";
        this.redirectLinkAlerts = "/alerts-mobile-s";
        this.redirectLinkVehicles = "/vehicles-mobile-s";
        this.redirectLinkDevices = "/devices-mobile-s";
        this.redirectLinkPeople = "/people-mobile-s";
        this.redirectLinkBespoke = "/bespoke-mobile-s";
        this.bespokeLink = "/bespoke-mobile-s";
        // this.currentUrl = "/insights-s";
      }else{
        this.redirectLinkActivity = "/activity-mobile";
        this.redirectLinkAlerts = "/alerts-mobile";
        this.redirectLinkVehicles = "/vehicles-mobile";
        this.redirectLinkDevices = "/devices-mobile";
        this.redirectLinkPeople = "/people-mobile";
        this.redirectLinkBespoke = "/bespoke-mobile";
        this.bespokeLink = "/bespoke-mobile";
        // this.currentUrl = "/insights-dashboard";
      }
    if( this.currentHref.includes("-") ){
      this.bespokeLink = "/bespoke-s";
    }else{
      this.bespokeLink = "/bespoke";
    }
    this.siteSelected = this.siteSelected;
    this.vehicelsTypeData = [];
    this._queryVehicelsFuelType.subscribe(queryVehicelsFuelTypeData => {
      this.queryVehicelsFuelType = queryVehicelsFuelTypeData;
    });
    this._queryMake.subscribe(query => {
      this.queryMake = query;
    });
    this._queryVehicelsFuelTypeTaxStatus.subscribe(query => {
      this.queryVehicelsFuelTypeTaxStatus = query;
    });
    this._queryVehicelsFuelTypeMot.subscribe(query => {
      this.queryVehicelsFuelTypeMot = query;
    });
    this._queryVehicelsType.subscribe(query => {
      this.queryVehicelsType = query;
    });
    this._queryVehicelsManufacturer.subscribe(query => {
      this.queryVehicelsManufacturer = query;
    });
    this._queryVehicelsEuroStatus.subscribe(query => {
      this.queryVehicelsEuroStatus = query;
    });
    this._queryVehicelsTrafficFlow.subscribe(query => {
      this.queryVehicelsTrafficFlow = query;
    });
    this._queryVehicelsColour.subscribe(query => {
      this.queryVehicelsColour = query;
    });
    if( this.siteSelected == "All" ){
      this._querySites.subscribe(querySite => {
        this.newCubeJs = new CubejsClient(
          {
            "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MTY2MDA4NDF9.q8o5JjVALy2YjmE31D_nIxteAm5gFYbM6p9JBJegSLA',
            "options": {
                "apiUrl": 'https://one-stage-m.advancedstudio.co.uk/cubejs-api/v1'
            }
        });
        this.querySites = querySite;
        this.newCubeJs.load(this.querySites).subscribe(
          resultSet => {
            this.dataSource = resultSet.rawData();
            this.dataSource.sort(this.dynamicSort("Layers.name"));
          },
          err => console.log("HTTP Error", err)
        );
      });
    }
  }
  dynamicSort(property) {
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }        
    }
}
}

