import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bespoke-mobile',
  templateUrl: './bespoke-mobile.component.html',
  styleUrls: ['./bespoke-mobile.component.scss']
})
export class BespokeMobileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
