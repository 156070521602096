<!--<div class="table-warp" style="background-color: #242533;padding: 0px 10px 0 10px;position: fixed;top: 0;width: 100%;z-index: 1;">
  <div class="row narrow-row">
    <div class="col-12">
      <div class="insights-cards-wrapper">
        <div class="row narrow-row">
          <div class="col-2">
            <a [routerLink]="[redirectLinkActivity, href]">
              <div class="insights-card"> <i class="fas fa-exchange"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[redirectLinkAlerts, href]">
              <div class="insights-card"> <i class="fas fa-bell"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[redirectLinkVehicles, href]">
              <div class="insights-card"> <i class="fas fa-car"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[redirectLinkDevices, href]">
              <div class="insights-card active"> <i class="fas fa-laptop"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[redirectLinkPeople, href]">
              <div class="insights-card"> <i class="fas fa-user"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[acessBespoke, href]">
              <div class="insights-card"> <i class="fas fa-star"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->
<div class="component-header d-flex align-items-center justify-content-between">
    <h4><i class="fas fa-bell"></i>Counting Dashboard</h4>
    <!-- <div class="date-Picker-wrap d-flex align-items-center ">
      <select class="date-Picker" (change)="selectOption($event.target.value)">
        <option [value]="date.id" *ngFor="let date of dates">{{date.name}}</option>
      </select>
      <select class="date-Picker" (change)="selectOption1($event.target.value)">
        <option value="All">All</option>
        <option value="{{element['Layers.name']}}" [ngValue]="siteSelected" *ngFor="let element of dataSource">{{element['Layers.name']}}</option>
      </select>
    </div> -->
  </div>
  
    
    <div class="table-warp">
        <div class="example-loading-shade"
        *ngIf="loading">
        <mat-spinner></mat-spinner>
        </div>
        <div rowHeight="59">
            <div>
                <div>
                    <div class="kpi-card" style="width: 100%;padding: 5px;">
                        <mat-card class="dashboard-card">
                            <mat-card-header class="dashboard-card__header">
                                <mat-card-title>
                                    <div class="custom-table" style="background-color: #00000000;">
                                        <div class="table-header-row">
                                            <div class="cell-wrap flex-fill">Site Details</div>
                                        </div>
                                        <div class="table-inner-wrapper">
                                            <div class="table-row-wrapper">
                                                <div class="table-row">
                                                    <div class="cell-wrap flex-fill">Site</div>
                                                    <div class="cell-wrap text-right" style="width: 150px;">{{counterName}}</div>
                                                </div>
                                                <div class="table-row">
                                                    <div class="cell-wrap flex-fill">Total</div>
                                                    <div class="cell-wrap text-right" style="width: 150px;">{{total }}</div>
                                                </div>
                                                <div class="table-row">
                                                    <div class="cell-wrap flex-fill">Occupied</div>
                                                    <div class="cell-wrap text-right" style="width: 150px;">{{counterOccupied }}</div>
                                                </div>
                                                <div class="table-row">
                                                    <div class="cell-wrap flex-fill">Free</div>
                                                    <div class="cell-wrap text-right" style="width: 150px;">{{free }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </mat-card-title>
                            </mat-card-header>
                        </mat-card>
                    </div>

                    <div class="kpi-card" style="width: 100%;padding: 5px;margin-top: 10px;">
                        <div style="width: 20%;float: left;">
                            <mat-card class="dashboard-card" style="margin-top:10px;background-color: #005395">
                                    <mat-card-title>
                                        <div class="custom-table" style="margin: 0;padding:0;">
                                            <!-- <div class="table-header-row">
                                                <div class="cell-wrap flex-fill">Level Details</div>
                                            </div> -->
                                            <div class="table-inner-wrapper">
                                                <div class="table-row-wrapper">
                                                    <div class="table-row">
                                                        <div class="cell-wrap flex-fill">Level</div>
                                                        <div class="cell-wrap text-right" style="width: 150px;">{{counterLevelName}}</div>
                                                    </div>
                                                    <div class="table-row">
                                                        <div class="cell-wrap flex-fill">Total</div>
                                                        <div class="cell-wrap text-right" style="width: 150px;">{{Leveltotal }}</div>
                                                    </div>
                                                    <div class="table-row">
                                                        <div class="cell-wrap flex-fill">Occupied</div>
                                                        <div class="cell-wrap text-right" style="width: 150px;">{{counterLevelOccupied }}</div>
                                                    </div>
                                                    <div class="table-row">
                                                        <div class="cell-wrap flex-fill">Free</div>
                                                        <div class="cell-wrap text-right" style="width: 150px;">{{Levelfree }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <h3 class="kpi-title">Level <span style="margin-left: 73px;">{{counterLevelName}}</span></h3>
                                        <h3 class="kpi-title" style="margin-top: 10px;">Total <span style="margin-left: 68px;">{{Leveltotal }}</span></h3>
                                        <h3 class="kpi-title">Occupied <span style="margin-left: 42px;">{{counterLevelOccupied }}</span></h3>
                                        <h3 class="kpi-title">Free <span style="margin-left: 70px;">{{Levelfree }}</span></h3> -->

                                    </mat-card-title>
                            </mat-card>
                        </div>
                        <div style="width: 80%;float: right;">
                            <mat-card class="dashboard-card"  *ngFor="let element of newdataZoneSource"  style="margin-top:10px; margin-left: 10px;float: left;width:20%;">
                                    <mat-card-title>
                                        <div class="custom-table" style="margin: 0;padding:0;">
                                            <!-- <div class="table-header-row">
                                                <div class="cell-wrap flex-fill">Zone Details</div>
                                            </div> -->
                                            <div class="table-inner-wrapper">
                                                <div class="table-row-wrapper">
                                                    <div class="table-row">
                                                        <div class="cell-wrap flex-fill">Zone</div>
                                                        <div class="cell-wrap text-right" style="width: 150px;">{{element['counterName']}}</div>
                                                    </div>
                                                    <div class="table-row">
                                                        <div class="cell-wrap flex-fill">Total</div>
                                                        <div class="cell-wrap text-right" style="width: 150px;">{{element['Zonetotal']}}</div>
                                                    </div>
                                                    <div class="table-row">
                                                        <div class="cell-wrap flex-fill">Occupied</div>
                                                        <div class="cell-wrap text-right" style="width: 150px;">{{element['counterZoneOccupied']}}</div>
                                                    </div>
                                                    <div class="table-row">
                                                        <div class="cell-wrap flex-fill">Free</div>
                                                        <div class="cell-wrap text-right" style="width: 150px;">{{element['Zonefree']}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <h3 class="kpi-title">Zone <span style="margin-left: 10px;"> {{element['counterName']}}</span></h3>
                                        <h3 class="kpi-title" style="margin-top: 10px;">Total <span style="margin-left: 10px;"> {{element['Zonetotal']}}</span></h3>
                                        <h3 class="kpi-title">Occupied <span style="margin-left: 10px;"> {{element['counterZoneOccupied']}}</span></h3>
                                        <h3 class="kpi-title">Free <span style="margin-left: 10px;"> {{element['Zonefree']}}</span></h3> -->

                                    </mat-card-title>
                            </mat-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    