<div class="component-header d-flex align-items-center justify-content-between">
  <h4><i class="fas fa-users"></i>People Dashboard</h4>
  <div class="date-Picker-wrap d-flex align-items-center ">
    <select class="date-Picker" (change)="selectOption($event.target.value)">
      <option [value]="date.id" *ngFor="let date of dates">{{date.name}}</option>
    </select>
    <select class="date-Picker" (change)="selectOption1($event.target.value)">
      <option value="All">All</option>
      <option value="{{element['Layers.name']}}" [ngValue]="siteSelected" *ngFor="let element of dataSource">{{element['Layers.name']}}</option>
    </select>
  </div>
</div>

  
  
  <div class="table-warp">
    <app-people-messaging  [href]="href" [dateRange]="selected" [selectedSite]="siteSelected"></app-people-messaging>
                        
  </div>
  