import { Component, OnInit, Input } from "@angular/core";
import { CubejsClient } from '@cubejs-client/ngx';
import {formatDate, registerLocaleData} from "@angular/common"
import localeEn from '@angular/common/locales/en';
// import { getRelativePosition } from 'chart.js/helpers';
import { Chart } from 'chart.js';
import * as annotations from 'chartjs-plugin-annotation';


@Component({
  selector: 'app-euro-fuel-euro6-percent',
  templateUrl: './euro-fuel-euro6-percent.component.html',
  styleUrls: ['./euro-fuel-euro6-percent.component.scss']
})
export class EuroFuelEuro6PercentComponent implements OnInit {
  constructor(private cubejs:CubejsClient){}
  dataSource = [];
  euro4Count = 0;
  euro4 = [];
  noneEuro4Count = 0;
  noneEuro4 = [];
  canvas: any;
  ctx: any;
  data;
  labels = [];
  percentage;
  value;
  newValue = [];
  newDataSource = [];
  newLSource = [];
  totalCount= 0;
  _query;
  volumnCountTotal;
  @Input() set volumnCount(volumnCount: object) {
    this.volumnCountTotal = volumnCount;
  }
  @Input() set dataValue(dataValue: object) {
    this.value = dataValue;
  }
  @Input() set query(query: object) {
    this._query = query;
    this.loading = true;
    this.cubejs.load(this._query).subscribe(
      resultSet => {
        this.dataSource = [];
        this.newDataSource = [];
        this.newValue = [];
        this.newLSource = [];
        this.euro4Count = 0;
        this.noneEuro4 = [];
        this.percentage = 0;
        this.labels = [];
        this.dataSource = resultSet.tablePivot();
        this.interval = setTimeout(() => { 
          this.buildChart(); 
        }, 1000);
      });
    

  }
  ngOnInit() {
    
  }
  interval;
  buildChart(){
          this.dataSource.sort(function(a, b) {
              var textA = a['History.vehiclefueltype'].toUpperCase();
              var textB = b['History.vehiclefueltype'].toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
          for(var index in this.value)
          { 
            this.newValue.push(this.value[index]['History.vehiclefueltype']);
          }
          if( this.dataSource.length > 0 ){
                for(var index in this.dataSource)
                {
                  this.newDataSource.push(this.dataSource[index]['History.vehiclefueltype']);
                  this.totalCount = this.totalCount + this.dataSource[index]['History.count'];
                }
                for(var index in this.dataSource)
            {
              loop:
              for(var i in this.newValue)
              {
              // if( this.newDataSource.includes(this.newValue[i]) ){
                if( this.newValue[i] == this.dataSource[index]['History.vehiclefueltype'] ){
                  this.noneEuro4Count = 0;
                  if( !this.newLSource.includes( this.newValue[i] ) ){
                          if(this.dataSource[index]['History.vehiclefueltype'] == ""){
                          }else{
                            
                            this.noneEuro4Count = this.dataSource[index]['History.count'];
                            this.noneEuro4.push( this.noneEuro4Count );
                            this.percentage = (this.dataSource[index]['History.count']/this.volumnCountTotal)*100;
                            this.percentage = Number(this.percentage.toFixed(2));
                            this.labels.push( this.percentage + "%" );
                          }
                        }
                        this.newLSource.push( this.dataSource[index]['History.vehiclefueltype'] );
                    break loop;
                  }else{
                    if( !this.newLSource.includes(this.newValue[i]) ){
                    this.noneEuro4.push( 0 );
                    this.percentage = 0*this.dataSource.length/100;
                    this.labels.push( this.percentage + "%" );
                    this.newLSource.push( this.newValue[i] );
                  }
                }
              }
            }
          }else if( this.dataSource.length == 0){
            for(var lm = 0; lm < this.value.length; lm++)
              {
                this.noneEuro4.push( 0 );
                this.percentage = 0;
                this.labels.push( this.percentage + "%" );
              }
          }
          this.euro4.push( this.euro4Count );
          this.loading = false;
      if( this.noneEuro4.length> 0 ){
        
        // clearInterval(this.interval);
    this.data = {
      labels: this.labels,
      datasets: [{
        axis: 'y',
        label: 'Euro 6',
        data: this.noneEuro4,
        fill: false,
        backgroundColor: [
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
        ],
        borderColor: [
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
          '#b9c9d6',
        ],
        borderWidth: 1
      }]
    };
    if( this.myChart ){
      this.myChart.destroy();
    }
    this.canvas = document.getElementById('perentage6');
      this.ctx = this.canvas.getContext('2d');
      this.myChart = new Chart(this.ctx, {
        type: 'bar',
        data: this.data,
        options: {
          // indexAxis: 'y',
          // elements: {
          //   bar: {
          //     borderWidth: 3,
          //   }
          // },
          responsive: true,
          scales: {
            xAxes: [{
              display: false,
            }],
            yAxes: [{
              display: true,
              ticks: {
                backdropColor: 'white'
              },
            }],
          },
          plugins: {
            tooltip: {
              enabled: false
            },
            legend: {
              display:false,
              position: 'right',
              labels: {
                usePointStyle: true,
              },
            },
            title: {
              display: true,
              text: '% of Total'
            }
          }
        },
      });
      this.myChart.update();
    }
  }
  myChart;
  loading = true;
}
