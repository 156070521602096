<mat-card class="dashboard-card">
    <mat-card-header class="dashboard-card__header">
      <mat-card-title>
        <h3 class="kpi-title">{{title}}</h3>
      </mat-card-title>
    </mat-card-header>
    <!--<hr>-->
    <mat-card-content class="dashboard-card-content kpi-result">
      <span>{{prefix}}</span>
      <span [countUp]="result" [options]="{duration: duration}">0</span>
      <span>{{postfix}}</span>
      <p *ngIf="difference" [ngClass]="diffValue <= 10 ? 'kpi-difference--red' : 'kpi-difference--green'" class="kpi-difference"> 
        <span class="kpi-difference__span">Since last month</span>
      </p>
      <mat-progress-bar [color]="'primary'" class="kpi-progress" *ngIf="progress" value="{{result}}"></mat-progress-bar>
    </mat-card-content>
  </mat-card>
