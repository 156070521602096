import { Component, Input,Output,OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';

@Component({
  selector: "app-table-page",
  templateUrl: "./table-page.component.html",
  styleUrls: ["./table-page.component.scss"]
})
export class TablePageComponent implements OnInit {
  constructor(private meta: Meta, private title: Title, private router: Router, private cubejs: CubejsClient) {
    this.title.setTitle('Angular Dashboard with Material');
    this.meta.addTag({ name: 'description', content: 'How to build Angular Material Data Table with Cube.js' });
    this.meta.addTag({ name: 'keywords', content: 'Angular, Cube.js, Data Table, Table, Material UI' });
  }
  public href: string = this.router.url.replace( "/", "" ).split("/")[1];
  public limit = 10;
  public limit1 = 10;
  public limit2 = 10;
  public limit3 = 10;
  public limit4 = 10;
  public page = 0;
  public page1 = 0;
  public page2 = 0;
  public page3 = 0;
  public page4 = 0;
  public query = null;
  public query1 = null;
  public query2 = null;
  public query3 = null;
  public query4 = null;
  public query5 = null;
  public sorting = ['History.createdat', 'desc'];
  public startDate = "01/1/2019";
  public finishDate = "01/1/2022";
  dates: any[] = [
    { id: 'Today', name: 'Today' },
    { id: 'Yesterday', name: 'Yesterday' },
    { id: 'This week', name: 'This Week' },
    { id: 'Last week', name: 'Last Week' },
    { id: 'This month', name: 'This Month' },
    { id: 'Last month', name: 'Last Month' },
    { id: 'This year', name: 'This Year' },
  ];
  selected: string = "Today";
  selectOption(id: string) {
    this.selected = id;
    this._query = new BehaviorSubject({
      "limit": this.limit,
      "offset": this.page * this.limit,
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange":this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.identifiertype"
      ],
      "filters":[
        {
          "member":"History.tenantkey",
          "operator":"equals",
          "values":[
            this.href
          ]
        }
      ]
    });
    this._query1 = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange":this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "limit": 10,
      "dimensions": [
        "History.identifiertype",
        "History.identifier"
      ],
      "filters":[
        {
          "member":"History.tenantkey",
          "operator":"equals",
          "values":[
            this.href
          ]
        }
      ]
    });
    this._query2 = new BehaviorSubject({
      "limit": this.limit2,
      "offset": this.page2 * this.limit2,
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange":this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.source"
      ],
      "filters":[
        {
          "member":"History.tenantkey",
          "operator":"equals",
          "values":[
            this.href
          ]
        }
      ]
    });
   
    this._query3 = new BehaviorSubject({
      "limit": this.limit3,
      "offset": this.page3 * this.limit3,
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange":this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.triggercondition",
        "History.identifiertype"
      ],
      "filters": [
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "Vehicle", this.href
          ]
        }
      ]
    });
  
    this._query4 = new BehaviorSubject({
      "limit": this.limit4,
      "offset": this.page4 * this.limit4,
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange":this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.triggercondition",
        "History.identifiertype"
      ],
      "filters": [
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "Device", this.href
          ]
        }
      ]
    });
    this.ngOnInit();
  }
  public _query = new BehaviorSubject({
    "limit": this.limit,
    "offset": this.page * this.limit,
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange":this.selected
      }
    ],
    "order": {
      "History.count": "desc"
    },
    "dimensions": [
      "History.identifiertype"
    ],
    "filters":[
      {
        "member":"History.tenantkey",
        "operator":"equals",
        "values":[
          this.href
        ]
      }
    ]
  });
  public _query1 = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange":this.selected
      }
    ],
    "order": {
      "History.count": "desc"
    },
    "limit": 10,
    "dimensions": [
      "History.identifiertype",
      "History.identifier"
    ],
    "filters":[
      {
        "member":"History.tenantkey",
        "operator":"equals",
        "values":[
          this.href
        ]
      }
    ]
  });
  public _query2 = new BehaviorSubject({
    "limit": this.limit2,
    "offset": this.page2 * this.limit2,
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange":this.selected
      }
    ],
    "order": {
      "History.count": "desc"
    },
    "dimensions": [
      "History.source"
    ],
    "filters":[
      {
        "member":"History.tenantkey",
        "operator":"equals",
        "values":[
          this.href
        ]
      }
    ]
  });
 
  public _query3 = new BehaviorSubject({
    "limit": this.limit3,
    "offset": this.page3 * this.limit3,
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange":this.selected
      }
    ],
    "order": {
      "History.count": "desc"
    },
    "dimensions": [
      "History.triggercondition",
      "History.identifiertype"
    ],
    "filters": [
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "Vehicle", this.href
        ]
      }
    ]
  });

  public _query4 = new BehaviorSubject({
    "limit": this.limit4,
    "offset": this.page4 * this.limit4,
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange":this.selected
      }
    ],
    "order": {
      "History.count": "desc"
    },
    "dimensions": [
      "History.triggercondition",
      "History.identifiertype"
    ],
    "filters": [
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "Device", this.href
        ]
      }
    ]
  });

  public _query5 = new BehaviorSubject({
    "measures": [],
    "timeDimensions": [
      {
        "dimension": "Layers.createddate"
      }
    ],
    "order": {},
    "filters": [
      {
        "member": "Layers.tenantkey",
        "operator": "equals",
        "values": [
          this.href
        ]
      },
      {
        "member": "Layers.category",
        "operator": "equals",
        "values": [
          "1"
        ]
      },
      {
        "member": "Layers.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ],
    "dimensions": [
      "Layers.name"
    ]
  });
 
  
  siteSelected: string = "All";
  selectOption1(id: string) {
    this.siteSelected = id;
    if( id == "All" ){
      this.selectOption(this.selected);
    }else{
      this._query = new BehaviorSubject({
        "limit": this.limit,
        "offset": this.page * this.limit,
        "measures": [
          "History.count"
        ],
        "timeDimensions": [
          {
            "dimension": "History.createdat",
            "dateRange":this.selected
          }
        ],
        "order": {
          "History.count": "desc"
        },
        "dimensions": [
          "History.identifiertype"
        ],
        "filters":[
          {
            "member":"History.tenantkey",
            "operator":"equals",
            "values":[
              this.href
            ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          }
        ]
      });
      this._query1 = new BehaviorSubject({
        "limit": this.limit1,
        "offset": this.page1 * this.limit1,
        "measures": [
          "History.count"
        ],
        "timeDimensions": [
          {
            "dimension": "History.createdat",
            "dateRange":this.selected
          }
        ],
        "order": {
          "History.count": "desc"
        },
        "dimensions": [
          "History.identifiertype",
          "History.identifier"
        ],
        "filters":[
          {
            "member":"History.tenantkey",
            "operator":"equals",
            "values":[
              this.href
            ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          }
        ]
      });
      this._query2 = new BehaviorSubject({
        "limit": this.limit2,
        "offset": this.page2 * this.limit2,
        "measures": [
          "History.count"
        ],
        "timeDimensions": [
          {
            "dimension": "History.createdat",
            "dateRange":this.selected
          }
        ],
        "order": {
          "History.count": "desc"
        },
        "dimensions": [
          "History.source"
        ],
        "filters":[
          {
            "member":"History.tenantkey",
            "operator":"equals",
            "values":[
              this.href
            ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          }
        ]
      });
     
      this._query3 = new BehaviorSubject({
        "limit": this.limit3,
        "offset": this.page3 * this.limit3,
        "measures": [
          "History.count"
        ],
        "timeDimensions": [
          {
            "dimension": "History.createdat",
            "dateRange":this.selected
          }
        ],
        "order": {
          "History.count": "desc"
        },
        "dimensions": [
          "History.triggercondition",
          "History.identifiertype"
        ],
        "filters": [
          {
            "member": "History.identifiertype",
            "operator": "equals",
            "values": [
              "Vehicle", this.href
            ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          }
        ]
      });
    
      this._query4 = new BehaviorSubject({
        "limit": this.limit4,
        "offset": this.page4 * this.limit4,
        "measures": [
          "History.count"
        ],
        "timeDimensions": [
          {
            "dimension": "History.createdat",
            "dateRange":this.selected
          }
        ],
        "order": {
          "History.count": "desc"
        },
        "dimensions": [
          "History.triggercondition",
          "History.identifiertype"
        ],
        "filters": [
          {
            "member": "History.identifiertype",
            "operator": "equals",
            "values": [
              "Device", this.href
            ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          }
        ]
      });
    }
    this.ngOnInit();
  }

  public bespokeLink;
  public currentHref: string = this.router.url.replace( "/", "" ).split("/")[0];
public newCubeJs;
  ngOnInit(): void {    
    if( this.currentHref.includes("-") ){
      this.bespokeLink = "/bespoke-s";
    }else{
      this.bespokeLink = "/bespoke";
    }
    this._query.subscribe(query => {
      this.query = query;
    });
    
    this._query1.subscribe(query1 => {
      this.query1 = query1;
    });
    this._query2.subscribe(query2 => {
      this.query2 = query2;
    });
    this._query3.subscribe(query3 => {
      this.query3 = query3;
    });
    this._query4.subscribe(query4 => {
      this.query4 = query4;
    });
    if( this.siteSelected == "All" ){
      this._query5.subscribe(_query5 => {
        this.query5 = _query5;
        this.newCubeJs = new CubejsClient(
          {
            "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MTY2MDA4NDF9.q8o5JjVALy2YjmE31D_nIxteAm5gFYbM6p9JBJegSLA',
            "options": {
                "apiUrl": 'https://one-stage-m.advancedstudio.co.uk/cubejs-api/v1'
            }
        });
        this.newCubeJs.load(this.query5).subscribe(
          resultSet => {
            this.dataSource = resultSet.rawData();
          },
          err => console.log("HTTP Error", err)
        );
      });
    }
  }
  dataSource = [];
}
