import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { CubejsClient } from "@cubejs-client/ngx";

@Component({
  selector: 'app-global-stats',
  templateUrl: './global-stats.component.html',
  styleUrls: ['./global-stats.component.scss']
})
export class GlobalStatsComponent implements OnInit {
  cubeQuery = new BehaviorSubject(null);
  chartType = new BehaviorSubject(null);
  pivotConfig = new BehaviorSubject(null);
  constructor(private meta: Meta, private title: Title, private router: Router, private cubejs: CubejsClient) {
    this.title.setTitle('Angular Dashboard with Material');
    this.meta.addTag({ name: 'description', content: 'How to build Angular Material Data Table with Cube.js' });
    this.meta.addTag({ name: 'keywords', content: 'Angular, Cube.js, Data Table, Table, Material UI' });
    // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    //     // some code..
    //   }else{
    //     if ( window.location == window.parent.location ) {
    //       window.location.href = "/#/revoke"
    //     }
    //   }
  }
  public href: string = this.router.url.replace( "/", "" ).split("/")[1];
  public queryVehicelsColour = null;
  public queryMake = null;
  public querySites = null;
  public sorting = ['History.createdat', 'desc'];
  public startDate = "01/1/2019";
  public finishDate = "01/1/2022";
  dates: any[] = [
    { id: 'Today', name: 'Today' },
    { id: 'Yesterday', name: 'Yesterday' },
    { id: 'This week', name: 'This Week' },
    { id: 'Last week', name: 'Last Week' },
    { id: 'This month', name: 'This Month' },
    { id: 'Last month', name: 'Last Month' },
    { id: 'This Year', name: 'This Year' },
  ];
  selected: string = "Today";
  selectOption(id: string) {
    this.selected = id;
    this._querySources = new BehaviorSubject({
      "measures": [
        "Sources.count"
      ],
      "filters": [
        {
          "member": "Sources.tenantkey",
          "operator": "equals",
          "values": [
            this.href
          ]
        },
        {
          "member": "Sources.isdeleted",
          "operator": "equals",
          "values": [
            "false"
          ]
        }
      ],
      "timeDimensions": [{
        "dimension": "Sources.createddate",
        "dateRange": this.selected
      }
    ]
  });

  this._queryManualEvents = new BehaviorSubject({
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertypeid",
        "operator": "equals",
        "values": [
          "322"
        ]
      }
    ],
    "limit": 10,
    "measures": [
      "History.count"
    ],
    "dimensions": [
      "History.identifiertype"
    ],
    "order": {
      "History.count": "desc"
    }
  });
  
  this._queryDvla = new BehaviorSubject({
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.vehiclefueltype",
        "operator": "set",
        "values": []
      }
    ],
    "limit": 10,
    "measures": [
      "History.count"
    ],
    "order": {}
  });
  this._queryPerHourEvents = new BehaviorSubject({
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "granularity": "hour",
        "dateRange": this.selected
      }
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      }
    ],
    "limit": 10,
    "measures": [
      "History.count"
    ]
  });

  this._queryTaskSite = new BehaviorSubject({
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "Tasks.priority",
        "operator": "equals",
        "values": [
          "400"
        ]
      }
    ],
    "limit": 10,
    "measures": [
      "Tasks.count"
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "dimensions": [
      "Tasks.priority",
      "Tasks.site"
    ]
  });
    this.ngOnInit();
  }
  siteSelected: string = "All";
  selectOption1(id: string) {
    this.siteSelected = id;
    if( id == "All" ){
      this.selectOption(this.selected);
    }
}
  public _querySources = new BehaviorSubject({
    "measures": [
      "Sources.count"
    ],
    "filters": [
      {
        "member": "Sources.tenantkey",
        "operator": "equals",
        "values": [
          this.href
        ]
      },
      {
        "member": "Sources.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ],
    "timeDimensions": [
      {
        "dimension": "Sources.createddate",
        "dateRange": this.selected
      }
    ]
  });

  public _queryManualEvents = new BehaviorSubject({
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertypeid",
        "operator": "equals",
        "values": [
          "322"
        ]
      }
    ],
    "limit": 10,
    "measures": [
      "History.count"
    ],
    "dimensions": [
      "History.identifiertype"
    ],
    "order": {
      "History.count": "desc"
    }
  });

  public _queryDvla = new BehaviorSubject({
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.vehiclefueltype",
        "operator": "set",
        "values": []
      }
    ],
    "limit": 10,
    "measures": [
      "History.count"
    ],
    "order": {}
  });

  public _queryPerHourEvents = new BehaviorSubject({
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "granularity": "hour",
        "dateRange": this.selected
      }
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      }
    ],
    "limit": 10,
    "measures": [
      "History.count"
    ]
  });

  public _queryTaskSite = new BehaviorSubject({
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "Tasks.priority",
        "operator": "equals",
        "values": [
          "400"
        ]
      }
    ],
    "limit": 10,
    "measures": [
      "Tasks.count"
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "dimensions": [
      "Tasks.priority",
      "Tasks.site"
    ]
  });

  public _querySites = new BehaviorSubject(
    {
      "measures": [],
      "timeDimensions": [
        {
          "dimension": "Layers.createddate"
        }
      ],
      "order": {},
      "filters": [
        {
          "member": "Layers.tenantkey",
          "operator": "equals",
          "values": [
            this.href
          ]
        },
        {
          "member": "Layers.category",
          "operator": "equals",
          "values": [
            "1"
          ]
        },
        {
          "member": "Layers.isdeleted",
          "operator": "equals",
          "values": [
            "false"
          ]
        }
      ],
      "dimensions": [
        "Layers.name"
      ]
    }
  );
  
  public dataSource = [];
  public newDataSource = [];
  public newDataSourceTax = [];
  name: string;
  public newCubeJs;
  public urlLink;
  public redirectLinkActivity;
  public redirectLinkAlerts;
  public redirectLinkVehicles;
  public redirectLinkDevices;
  public redirectLinkPeople;
  public redirectLinkBespoke;
  public redirectLinkPartners;
  public acessBespoke;
  public currentUrl;
  public bespokeLink;
  public sourceListing;
  public querySources;
  public queryManualEvents;
  public manualEventsListing;
  public queryDvla;
  public dvlaListing;
  public queryPerHourEvents;
  public perHourEventsListing;
  public queryTaskSite;
  public taskSiteListing;
  vehicelsTypeData: Array<{name: string}> = []; 
  ngOnInit(): void {
    this.urlLink = this.router.url;
      
        this.redirectLinkActivity = "/activity";
        this.redirectLinkAlerts = "/alerts";
        this.redirectLinkVehicles = "/vehicles";
        this.redirectLinkDevices = "/devices";
        this.redirectLinkPeople = "/people";
        this.redirectLinkBespoke = "/bespoke";
        this.bespokeLink = "/bespoke";
        this.acessBespoke = "/bespoke-w";
        // this.currentUrl = "/insights-dashboard";

        this._queryManualEvents.subscribe(manualEvents => {
          this.queryManualEvents = manualEvents;
          this.cubejs.load(this.queryManualEvents).subscribe(
            resultSet => {
              this.manualEventsListing = resultSet.rawData();
            },
            err => console.log("HTTP Error", err)
          );
        });

        this._queryDvla.subscribe(manualEvents => {
          this.queryDvla = manualEvents;
          this.cubejs.load(this.queryDvla).subscribe(
            resultSet => {
              this.dvlaListing = resultSet.rawData();
            },
            err => console.log("HTTP Error", err)
          );
        });

        this._queryPerHourEvents.subscribe(queryPerHourEvents => {
          this.queryPerHourEvents = queryPerHourEvents;
          this.cubejs.load(this.queryPerHourEvents).subscribe(
            resultSet => {
              this.perHourEventsListing = resultSet.rawData();
            },
            err => console.log("HTTP Error", err)
          );
        });

        this._queryTaskSite.subscribe(queryTaskSite => {
          this.queryTaskSite = queryTaskSite;
          this.cubejs.load(this.queryTaskSite).subscribe(
            resultSet => {
              this.taskSiteListing = resultSet.rawData();
            },
            err => console.log("HTTP Error", err)
          );
        });

      this._querySources.subscribe(people => {
          this.newCubeJs = new CubejsClient(
            {
              "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDQ4MzY2MDd9.3qF6X7Tjq2tk-McSumVvlO60_YwVeUItGQwRyBdyrVM',
              "options": {
                  "apiUrl": 'https://reporting-management.advancedstudio.co.uk/cubejs-api/v1'
              }
          });
          this.querySources = people;
          this.newCubeJs.load(this.querySources).subscribe(
            resultSet => {
              this.sourceListing = resultSet.rawData();
            },
            err => console.log("HTTP Error", err)
          );
        });

    if( this.siteSelected == "All" ){
      this._querySites.subscribe(querySite => {
        this.newCubeJs = new CubejsClient(
          {
            "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDQ4MzY2MDd9.3qF6X7Tjq2tk-McSumVvlO60_YwVeUItGQwRyBdyrVM',
            "options": {
                "apiUrl": 'https://reporting-management.advancedstudio.co.uk/cubejs-api/v1'
            }
        });
        this.querySites = querySite;
        this.newCubeJs.load(this.querySites).subscribe(
          resultSet => {
            this.dataSource = resultSet.rawData();
            this.dataSource.sort(this.dynamicSort("Layers.name"));
          },
          err => console.log("HTTP Error", err)
        );
      });
    }
  }
  dynamicSort(property) {
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }        
    }
}
}

