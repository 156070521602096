import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partners-alerts-exit',
  templateUrl: './partners-alerts-exit.component.html',
  styleUrls: ['./partners-alerts-exit.component.scss']
})
export class PartnersAlertsExitComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
