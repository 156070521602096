  <div class="table-warp" *ngIf="denied" style="position: absolute;left: 30%;color: #FFF;padding: 0px 0 10px 0;margin: 0 10px;margin-top: 50px;padding: 80px;">
      <div class="row narrow-row">
          <div class="col-12">To enable bespoke reporting email: bespoke@intelico.com</div>
      </div>
  </div>

  <div class="table-warp">
    <div class="row narrow-row">
      <div class="col-12">
        <div class="insights-cards-wrapper">
          <div class="row narrow-row">
            <div class="col-12">
              <div *ngIf="emissions">
                <div class="col-6" style="float: left;">
                    <a [routerLink]="[fuelType, href]">
                    <div class="insights-card" style="height: 150px;"> <i class="fas fa-cars"></i>
                        <h5>Vehicle Emissions</h5>
                    </div>
                    </a>
                </div>
              </div>
              <div *ngIf="report">
                <div class="col-6" style="float: left;">
                  <a [routerLink]="[export_csv, href]">
                  <div class="insights-card" style="height: 150px;"> <i class="fas fa-list"></i>
                      <h5>Export</h5>
                  </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  