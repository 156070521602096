import { Component, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Meta, Title } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { CubejsClient } from "@cubejs-client/ngx";

@Component({
  selector: 'app-vehicles-mobile-graph',
  templateUrl: './vehicles-mobile-graph.component.html',
  styleUrls: ['./vehicles-mobile-graph.component.scss']
})
export class VehiclesMobileGraphComponent implements OnInit {
  cols : number;
  chartCols: number;

  gridByBreakpoint = {
    xl: 4,
    lg: 4,
    md: 2,
    sm: 2,
    xs: 1
  }
  chartGridByBreakpoint = {
    xl: 5,
    lg: 5,
    md: 3,
    sm: 3,
    xs: 3
  }
  doughnutCols = 2;
  constructor(private meta: Meta, private title: Title, private router: Router, private cubejs: CubejsClient) {
    this.title.setTitle('Angular Dashboard with Material');
    this.meta.addTag({ name: 'description', content: 'How to build Angular Material Data Table with Cube.js' });
    this.meta.addTag({ name: 'keywords', content: 'Angular, Cube.js, Data Table, Table, Material UI' });
  }
  public href: string = this.router.url.replace( "/", "" ).split("/")[1];
  private _euro4 = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat"
      }
    ],
    "order": {
      "History.count": "desc"
    },
    "dimensions": [
      "History.vehicleeurostatus"
    ],
    "filters": [
      {
        "member": "History.vehiclefueltype",
        "operator": "equals",
        "values": [
          "petrol"
        ]
      },
      {
        "member": "History.vehicleeurostatus",
        "operator": "contains",
        "values": [
          "euro 1",
          "euro 2",
          "euro 3",
          "euro 4",
          "euro 5",
          "euro 6"
        ]
      },
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      }
    ]
  });
  private _euro6 = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat"
      }
    ],
    "order": {
      "History.count": "desc"
    },
    "dimensions": [
      "History.vehicleeurostatus"
    ],
    "filters": [
      {
        "member": "History.vehiclefueltype",
        "operator": "equals",
        "values": [
          "diesel"
        ]
      },
      {
        "member": "History.vehicleeurostatus",
        "operator": "contains",
        "values": [
          "euro 1",
          "euro 2",
          "euro 3",
          "euro 4",
          "euro 5",
          "euro 6"
        ]
      },
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
    ],
  });

  _queryVehicelsFuelType = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": "This year"
      }
    ],
    "order": {},
    "dimensions": [
      "History.vehiclefueltype"
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });

  _queryVehicelsFuelTypeList = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": "This year"
      }
    ],
    "order": {},
    "dimensions": [
      "History.vehiclefueltype"
    ],
    "filters": [
      {
        "member": "History.vehicleeurostatus",
        "operator": "contains",
        "values": [
          "euro 4"
        ]
      },
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  _queryVehicelsFuelTypeEuro5List = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": "This year"
      }
    ],
    "order": {},
    "dimensions": [
      "History.vehiclefueltype"
    ],
    "filters": [
      {
        "member": "History.vehicleeurostatus",
        "operator": "contains",
        "values": [
          "euro 5"
        ]
      },
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
  _queryVehicelsFuelTypeEuro6List = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": "This year"
      }
    ],
    "order": {},
    "dimensions": [
      "History.vehiclefueltype"
    ],
    "filters": [
      {
        "member": "History.vehicleeurostatus",
        "operator": "contains",
        "values": [
          "euro 6"
        ]
      },
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });


  _makePercentage = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": "This year"
      }
    ],
    "order": {},
    "dimensions": [
      "History.make"
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });

  public query;
  public euro6;
  public dataSource;
  public dataSource1;
  public queryVehicelsFuelType;
  public queryVehicelsFuelTypeList;
  public queryVehicelsFuelTypeEuro5List;
  public queryVehicelsFuelTypeEuro6List;
  public makePercentage;
  ngOnInit() {
    this._euro4.subscribe(query => {
      this.query = query;
    });
    this._euro6.subscribe(query => {
      this.euro6 = query;
    });
    this._queryVehicelsFuelTypeList.subscribe(query => {
      this.queryVehicelsFuelTypeList = query;
    });
    this._queryVehicelsFuelTypeEuro5List.subscribe(query => {
      this.queryVehicelsFuelTypeEuro5List = query;
    });
    this._queryVehicelsFuelTypeEuro6List.subscribe(query => {
      this.queryVehicelsFuelTypeEuro6List = query;

    });
    this._makePercentage.subscribe(query => {
      this.makePercentage = query;
      this.cubejs.load(this.makePercentage).subscribe(
        resultSet => {
          this.dataSource1 = [];
          this.dataSource1 = resultSet.tablePivot();
          for(var index in this.dataSource1)
          { 
              if(this.dataSource1[index]['History.make'] == ""){
                this.dataSource1[index]['History.make'] = "Unknown/Others";
              }
          }
        //   this.dataSource1.sort(function(a, b) {
        //     var textA = a['History.make'].toUpperCase();
        //     var textB = b['History.make'].toUpperCase();
        //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        // });
      });
    });
    
    this._queryVehicelsFuelType.subscribe(query => {
      this.queryVehicelsFuelType = query;
      this.cubejs.load(this.queryVehicelsFuelType).subscribe(
        resultSet => {
          this.dataSource = [];
          this.dataSource = resultSet.tablePivot();
          for(var index in this.dataSource)
          { 
              if(this.dataSource[index]['History.vehiclefueltype'] == ""){
                this.dataSource[index]['History.vehiclefueltype'] = "Unknown/Others";
              }
          }
          this.dataSource.sort(function(a, b) {
            var textA = a['History.vehiclefueltype'].toUpperCase();
            var textB = b['History.vehiclefueltype'].toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      });
    });
  }
}
