import { Component, OnInit, Input } from "@angular/core";
import { CubejsClient } from '@cubejs-client/ngx';
import {formatDate, registerLocaleData} from "@angular/common"
import localeEn from '@angular/common/locales/en';
// import { getRelativePosition } from 'chart.js/helpers';
import { Chart } from 'chart.js';
import * as annotations from 'chartjs-plugin-annotation';

@Component({
  selector: 'app-euro-fuel-euro4-count',
  templateUrl: './euro-fuel-euro4-count.component.html',
  styleUrls: ['./euro-fuel-euro4-count.component.scss']
})
export class EuroFuelEuro4CountComponent implements OnInit {
  constructor(private cubejs:CubejsClient){}
  dataSource = [];
  euro4Count = 0;
  euro4 = [];
  noneEuro4Count = 0;
  noneEuro4 = [];
  canvas: any;
  ctx: any;
  data;
  labels = [];
  percentage;
  value;
  newValue = [];
  newDataSource = [];
  newLSource = [];volumnCountTotal;
  @Input() set volumnCount(volumnCount: object) {
    this.volumnCountTotal = volumnCount;
  }
  @Input() set dataValue(dataValue: object) {
    this.value = dataValue;
  }
  @Input() set query(query: object) {
    this.loading = true;
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = [];
        this.dataSource = resultSet.tablePivot();
        this.interval = setTimeout(() => { 
          this.buildChart(); 
        }, 1000);
      });

  }
  ngOnInit() {
    
  }
  interval;
  count = 0;
  buildChart(){

    this.newDataSource = [];
    this.newValue = [];
    this.newLSource = [];
    this.euro4Count = 0;
    this.noneEuro4 = [];
    this.percentage = 0;
    this.labels = [];
    this.count = 0;
    this.dataSource.sort(function(a, b) {
      var textA = a['History.vehiclefueltype'].toUpperCase();
      var textB = b['History.vehiclefueltype'].toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    if( this.dataSource.length > 0 ){
      this.loading = false;
      for(var index in this.dataSource)
      {
        loop:
        for(var i in this.value)
        { 
            if( this.value[i]['History.vehiclefueltype'] == this.dataSource[index]['History.vehiclefueltype'] ){
              if( !this.newLSource.includes( this.newValue[i] ) ){
                if(this.dataSource[index]['History.vehiclefueltype'] == ""){
                }else{
                  this.count = this.dataSource[index]['History.count'];
                  this.percentage = (this.count/this.volumnCountTotal)*100;
                  this.percentage = Number(this.percentage.toFixed(2));
                  this.labels.push( {"History.vehiclefueltype": this.dataSource[index]['History.vehiclefueltype'], "History.percent": this.percentage, "History.count": this.count });
                }
              }
              this.newLSource.push( this.value[i]['History.vehiclefueltype'] );
              break loop;
            }else{
              if( !this.newLSource.includes(this.value[i]['History.vehiclefueltype']) ){
                  this.count = 0;
                  this.percentage = 0;
                  this.labels.push( {"History.vehiclefueltype": this.value[i]['History.vehiclefueltype'], "History.percent": this.percentage, "History.count": this.count });
                  this.newLSource.push( this.value[i]['History.vehiclefueltype'] );
                }
              }
          }
        }
      }else if( this.dataSource.length == 0){
        for(var lm = 0; lm < this.value.length; lm++)
          {
            this.count = 0;
            this.percentage = 0;
            this.labels.push( {"History.vehiclefueltype": this.value[lm]['History.vehiclefueltype'], "History.percent": this.percentage, "History.count": this.count });
          }
      }
      this.loading = false;
    /*if( this.noneEuro4.length > 0 ){
    
      this.data = {
      labels: this.labels,
      datasets: [{
        axis: 'y',
        label: 'Euro 4',
        data: this.noneEuro4,
        fill: false,
        backgroundColor: [
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
        ],
        borderColor: [
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
          '#51789a',
        ],
        borderWidth: 1
      }]
    };
    if( this.myChart ){
      this.myChart.destroy();
    }
    this.canvas = document.getElementById('perentage1');
      this.ctx = this.canvas.getContext('2d');
      this.myChart = new Chart(this.ctx, {
        type: 'bar',
        data: this.data,
        options: {
          indexAxis: 'y',
          elements: {
            bar: {
              borderWidth: 3,
            }
          },
          responsive: true,
          scales: {
            x: {
              display: false,
            },
            y: {
              display: true,
              ticks: {
                color: 'white',
              }
            },
          },
          plugins: {
            tooltip:{
              enabled: false
            },
            legend: {
              display:false,
              position: 'right',
              labels: {
                usePointStyle: true,
              },
            },
            title: {
              display: true,
              text: 'Record Count'
            }
          }
        },
      }); 
    }   
    if( this.myChart )
      this.myChart.update();
      */
  }
  myChart;
  loading = true;
}
