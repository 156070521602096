import { Component, Input } from '@angular/core';
import { CubejsClient} from '@cubejs-client/ngx';
import { BehaviorSubject } from 'rxjs';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { getDisplayedColumns, flattenColumns } from './utils';


@Component({
  selector: 'app-call-alerts-pie-chart',
  templateUrl: './call-alerts-pie-chart.component.html',
  styleUrls: ['./call-alerts-pie-chart.component.scss'],
})
export class CallAlertsPieChartComponent {
  
  chartData: ChartDataSets[] = [];
  chartLabels: Label[] = [];
  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };
  
  loading = false;

  constructor(private cubejs: CubejsClient) {}
  @Input() set query(query: object) {
    this.loading = true;
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.updateChartData(resultSet);
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
    this.cubejs.load({...query}).subscribe(
      resultSet => {
        this.updateChartData(resultSet);
      },
      err => console.log("HTTP Error", err)
    );
  };
  public barChartLabels = [];
  public barChartType = "line";
  public barChartLegend = true;
  public barChartData = [];

  ngOnInit() {
  }

updateChartData(resultSet) {
this.chartData = resultSet.series().map((item) => {
return {
  label: item.title,
  data: item.series.map(({ value }) => value),
  stack: 'a',
};
});
this.chartLabels = resultSet.chartPivot().map((row) => {
  if( row.x == 400 ){
    row.x = "Critical";
  }else if( row.x == 300 ){
    row.x = "High";
  }else if( row.x == 200 ){
    row.x = "Normal";
  }
  return row.x;
  }
  );
}
}