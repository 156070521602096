import { Component, Input, Output, OnInit } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Component({
  selector: 'app-people-messaging',
  templateUrl: './people-messaging.component.html',
  styleUrls: ['./people-messaging.component.scss']
})
export class PeopleMessagingComponent{
  constructor(private cubejs: CubejsClient) {}
  selected: string;
  @Input() href: string
  nData = [];
  public IfentifierVehicles = null;
  public _IfentifierVehicles = null;
  public IfentifierDevices = null;
  public _IfentifierDevices = null;
  public IfentifierPerson = null;
  public _IfentifierPerson = null;
  public IfentifierOther = null;
  public _IfentifierOther = null;
  public IfentifierTotal = null;
  public _IfentifierTotal = null;
  @Input() set dateRange( dateRange: string ){
    this.selected= dateRange;
    this.loading = false;
    this.dataSource = [];
    this.nonSelectedSiteFunction();
  }
  @Input() set selectedSite( selectedSite: string){
    this.dataSource = [];
    if(selectedSite == "All" && this.selected != "Today"){
      this.nonSelectedSiteFunction();
    }else{
      this.SelectedSiteFunction(selectedSite);
    }
  }
  SelectedSiteFunction(selectedSite){
    let id = selectedSite;
    this.IfentifierVehicles = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Alerts.type",
          "operator": "equals",
          "values": [
            "2"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.IfentifierVehicles.subscribe(querySite => {
      this._IfentifierVehicles = querySite;
      this.cubejs.load(this._IfentifierVehicles).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
              this.dataSource.push({"Tasks.identifiertype":"SMS", "Tasks.count": this.nData[i]["Tasks.count"]});
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.IfentifierDevices = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Alerts.type",
          "operator": "equals",
          "values": [
            "1"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.IfentifierDevices.subscribe(querySite => {
      this._IfentifierDevices = querySite;
      this.cubejs.load(this._IfentifierDevices).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
              this.dataSource.push({"Tasks.identifiertype":"Email", "Tasks.count": this.nData[i]["Tasks.count"]});
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.IfentifierPerson = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Alerts.type",
          "operator": "equals",
          "values": [
            "3"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.IfentifierPerson.subscribe(querySite => {
      this._IfentifierPerson = querySite;
      this.cubejs.load(this._IfentifierPerson).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
              this.dataSource.push({"Tasks.identifiertype":"Phone Call", "Tasks.count": this.nData[i]["Tasks.count"]});
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
  }
  nonSelectedSiteFunction(){
    this.IfentifierVehicles = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Alerts.type",
          "operator": "equals",
          "values": [
            "2"
          ]
        }
      ]
      });
    this.IfentifierVehicles.subscribe(querySite => {
      this._IfentifierVehicles = querySite;
      this.cubejs.load(this._IfentifierVehicles).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
              this.dataSource.push({"Tasks.identifiertype":"SMS", "Tasks.count": this.nData[i]["Tasks.count"]});
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.IfentifierDevices = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Alerts.type",
          "operator": "equals",
          "values": [
            "1"
          ]
        }
      ]
    });
    this.IfentifierDevices.subscribe(querySite => {
      this._IfentifierDevices = querySite;
      this.cubejs.load(this._IfentifierDevices).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
              this.dataSource.push({"Tasks.identifiertype":"Email", "Tasks.count": this.nData[i]["Tasks.count"]});
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.IfentifierPerson = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Alerts.type",
          "operator": "equals",
          "values": [
            "3"
          ]
        }
      ]
    });
    this.IfentifierPerson.subscribe(querySite => {
      this._IfentifierPerson = querySite;
      this.cubejs.load(this._IfentifierPerson).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
              this.dataSource.push({"Tasks.identifiertype":"Phone Call", "Tasks.count": this.nData[i]["Tasks.count"]});
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
  }
  @Input() limit: number;
  @Output() pageEvent = new EventEmitter();
  @Output() sortingChanged = new EventEmitter();
  loading = true;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25];
  dataSource = [];
  displayedColumns = ['Site', 'Count'];
  changeSorting(value) {
    this.sortingChanged.emit(value)
  }
}
