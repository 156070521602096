<div>
          <div style="width: 42px;float: left;">
            <strong class="kpi-difference--green kpi-difference" style="width: 15px;font-size: 20px;">48%</strong>
            <p class="kpi-difference--green kpi-difference" style="width: 15px; color: #f44336;bottom: 25px;position: absolute;">5%</p>
          </div>
          <div style="width: 210px; float: left; padding-left: 28px;">
            <canvas baseChart
                height="100"
                width="210"
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                [legend]="barChartLegend"
                [chartType]="barChartType">
        </canvas>
          </div>
  </div>
  