<style>
  body{
      background-color: #051F2C !important;
  }
</style>
    <div class="table-warp">
    <div class="row narrow-row">
      <div class="col-12">
        <div class="kpi-wrap" style="background-color: unset;">
              <app-alerts-card-mobile
                          [query]="alertsCards" 
                          [queryVehicles]="queryVehicles"
                          [queryDevice]="queryDevice"
                          [queryPeople]="queryPeople"
            ></app-alerts-card-mobile>
        </div>
      </div>
    </div>
  </div>
  