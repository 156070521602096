<mat-grid-list [cols]="3" rowHeight="59">
    <div  *ngFor="let element of newDataSource" [colspan]="1" [rowspan]="1">
        <mat-grid-tile *ngIf="element['Tasks.priority'] != 'Critical' && element['Tasks.priority'] != 'High'  && element['Tasks.priority'] != 'Normal' ">
            <div class="kpi-card" style="width: 100%;padding: 5px;">
                <mat-card class="dashboard-card" style="background-color: #052846;">
                    <mat-card-header class="dashboard-card__header">
                    <mat-card-title>
                        <h3 class="kpi-title">{{element['Tasks.priority']}}</h3>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result">
                    <span>{{element['Tasks.count']}}</span>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="element['Tasks.priority'] == 'Critical' ">
            <div class="kpi-card" style="width: 100%;padding: 5px;">
                <mat-card class="dashboard-card mat-card-critical">
                    <mat-card-header class="dashboard-card__header">
                    <mat-card-title>
                        <h3 class="kpi-title">{{element['Tasks.priority']}}</h3>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result">
                    <span>{{element['Tasks.count']}}</span>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="element['Tasks.priority'] == 'High' ">
            <div class="kpi-card" style="width: 100%;padding: 5px;">
                <mat-card class="dashboard-card mat-card-high">
                    <mat-card-header class="dashboard-card__header">
                    <mat-card-title>
                        <h3 class="kpi-title">{{element['Tasks.priority']}}</h3>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result">
                    <span>{{element['Tasks.count']}}</span>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="element['Tasks.priority'] == 'Normal' ">
            <div class="kpi-card" style="width: 100%;padding: 5px;">
                <mat-card class="dashboard-card mat-card-normal">
                    <mat-card-header class="dashboard-card__header">
                    <mat-card-title>
                        <h3 class="kpi-title">{{element['Tasks.priority']}}</h3>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result">
                    <span>{{element['Tasks.count']}}</span>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
    </div>
    <mat-grid-tile>
        <div class="kpi-card" style="width: 100%;padding: 5px;">
            <mat-card class="dashboard-card" style="background-color: #052846;">
                <mat-card-header class="dashboard-card__header">
                <mat-card-title>
                    <h3 class="kpi-title">Vehicles</h3>
                </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content kpi-result">
                <span>{{vehiclesCount}}</span>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-grid-tile>
    <mat-grid-tile>
        <div class="kpi-card" style="width: 100%;padding: 5px;">
            <mat-card class="dashboard-card" style="background-color: #052846;">
                <mat-card-header class="dashboard-card__header">
                <mat-card-title>
                    <h3 class="kpi-title">Devices</h3>
                </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content kpi-result">
                <span>{{devicesCount}}</span>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-grid-tile>
    <mat-grid-tile>
        <div class="kpi-card" style="width: 100%;padding: 5px;">
            <mat-card class="dashboard-card" style="background-color: #052846;">
                <mat-card-header class="dashboard-card__header">
                <mat-card-title>
                    <h3 class="kpi-title">People</h3>
                </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content kpi-result">
                <span>{{peopleCount}}</span>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-grid-tile>
</mat-grid-list>