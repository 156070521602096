<style>
table .ng-star-inserted{
  text-align: center;
}

</style>

<div class="example-loading-shade"
     *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<div class="example-table-container">
  <table mat-table
         matSort
         [dataSource]="dataSource"
         class="table mat-elevation-z8"
  >

    <ng-container matColumnDef="Site">
      <th matSort mat-header-cell *matHeaderCellDef mat-sort-header (click)="changeSorting('History.Site')"> History Site</th>
      <td mat-cell *matCellDef="let element"> {{element['History.site']}} </td>
    </ng-container>
    <ng-container matColumnDef="Count">
      <th matSort mat-header-cell *matHeaderCellDef mat-sort-header (click)="changeSorting('History.id')"> History Count</th>
      <td mat-cell *matCellDef="let element"> {{element['History.count']}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <!--<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>-->
  </table>
</div>