<div class="custom-table">
    <div class="table-header-row">
      <div class="cell-wrap flex-fill">Make</div>
      <div class="cell-wrap text-right" style="width: 80px;">% Total</div>
      <div class="cell-wrap text-right" style="width: 120px;"># Record Count</div>
    </div>
    <div class="table-inner-wrapper">
      <div class="table-row-wrapper" *ngFor="let element of labels">
        <div class="table-row">
          <div class="cell-wrap flex-fill">{{element['History.make']}}</div>
          <div class="cell-wrap text-right" style="width: 80px;">{{element['History.percent']}}%</div>
          <div class="cell-wrap text-right" style="width: 120px;">{{element['History.count']}}</div>
        </div>
      </div>
    </div>
  </div>