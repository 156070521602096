// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let url = "";
let token = "";

token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDQ4MzY3MTZ9.gOWcjPO4sTHqvlExSBQt52W3uqVMPe4CTvaVxAQC9fI';
url = 'https://reporting-read.advancedstudio.co.uk/cubejs-api/v1';
    

export const environment = {
  production: false,
  apiUrl: url,
  apiToken: token
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
