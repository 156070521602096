<div class="example-loading-shade fuel-loader"
*ngIf="loading" >
<mat-spinner></mat-spinner>
</div>
<div class="custom-table">
    <div class="table-header-row">
      <div class="cell-wrap flex-fill">Euro 4 - Fuel Type</div>
      <div class="cell-wrap text-right" style="width: 80px;">% Total</div>
      <div class="cell-wrap text-right" style="width: 80px;">Total</div>
    </div>
    <div class="table-inner-wrapper">
      <div class="table-row-wrapper" *ngFor="let element of labels">
        <div class="table-row">
          <div class="cell-wrap flex-fill">{{element['History.vehiclefueltype']}}</div>
          <div class="cell-wrap text-right" style="width: 80px;">{{element['History.percent']}}%</div>
          <div class="cell-wrap text-right" style="width: 80px;">{{element['History.count']}}</div>
        </div>
      </div>
    </div>
  </div>