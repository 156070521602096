import { Component, Input, Output } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';
import { Chart } from 'chart.js';


@Component({
  selector: 'app-euro-fuel-type-doughnut',
  templateUrl: './euro-fuel-type-doughnut.component.html',
  styleUrls: ['./euro-fuel-type-doughnut.component.scss']
})
export class EuroFuelTypeDoughnutComponent {
  public doughnutChartLabels = ['Sales Q1', 'Sales Q2', 'Sales Q3', 'Sales Q4'];
  public doughnutChartData = [120, 150, 180, 90];
  public doughnutChartType = 'doughnut';

  constructor(private cubejs: CubejsClient) {}
  dataSource = [];
  euro4Count = 0;
  euro4 = [];
  noneEuro4Count = 0;
  noneEuro4 = [];
  canvas: any;
  ctx: any;
  data;
  @Input() set query(query: object) {
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = [];
        this.dataSource = resultSet.tablePivot();
        for(var index in this.dataSource)
        { 
            if(this.dataSource[index]['History.vehicleeurostatus'].replace(/[^0-9]/g, '') >= "6" ){
              this.euro4Count = this.euro4Count + this.dataSource[index]['History.count'];
            }else{
              this.noneEuro4Count = this.noneEuro4Count + this.dataSource[index]['History.count'];
            }
        }
        this.noneEuro4.push( this.euro4Count, this.noneEuro4Count );
        this.data = {
          labels: ['Euro 6+', '< Euro 6'],
          datasets: [{
          label: 'Euro 6',
          data: this.noneEuro4,
          backgroundColor: ['#81c784', '#e57373'],
          borderWidth: 1
          }
          ]
        }
        this.canvas = document.getElementById('euro6-doughnut');
        this.ctx = this.canvas.getContext('2d');
        const myChart = new Chart(this.ctx, {
          type: 'doughnut',
          data: this.data,
          options: {
            maintainAspectRatio : false,
            plugins: {
              legend: {
                position: 'right',
                labels: {
                  usePointStyle: true,
                },
              },
              title: {
                display: false,
                text: 'Volume% by Fuel Type Diesel'
              }
            }
          },
        });
      });
  }
}
