import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-bespoke-web',
  templateUrl: './bespoke-web.component.html',
  styleUrls: ['./bespoke-web.component.scss']
})
export class BespokeWebComponent implements OnInit {

  public href: string = this.router.url.replace( "/", "" ).split("/")[1];
  public apiUrl = "https://api.advancedstudio.co.uk/features/getAllOrganizations?ID=" + this.href;
  // public apiUrl = "http://feature.advancedstudio.me/features/getAllOrganizations?ID=" + this.href;
  public report;
  public emissions;
  constructor(private router: Router, private httpClient: HttpClient) {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        // some code..
      }else{
        if ( window.location == window.parent.location ) {
          window.location.href = "/#/revoke"
        }
      }

    const xhttp = new XMLHttpRequest();
    var that = this;
    xhttp.onload = function() {
      if(this.status != 200){
        window.location.href = "/#/revoke";
      }
      let response = JSON.parse( this.response);
      if(response.success == 0){
        window.location.href = "/#/revoke";
      }else{
        that.repeat(response.data.emissions, response.data.report);
      }
    }
    console.log(that.report);
    xhttp.open("GET", this.apiUrl);
    xhttp.send();

    xhttp.onerror = function(){
      window.location.href = "/#/revoke";
    }

  }
  repeat(emissions, report){
    this.emissions = emissions;
    this.report = report;
    setInterval(() => {
      this.ngOnInit();
    }, 30000);
  }
   public bespokeLink;
   public currentHref: string = this.router.url.replace( "/", "" ).split("/")[0];
  
   public urlLink;
   public redirectLinkActivity;
   public redirectLinkAlerts;
   public redirectLinkVehicles;
   public redirectLinkDevices;
   public redirectLinkPeople;
   public redirectLinkBespoke;
   public redirectLinkPartners;
   public acessBespoke;
   public fuelType;
   public export_csv;

  ngOnInit(): void {
    this.urlLink = this.router.url;
    
    // if( 
    //   this.urlLink.replace( "/", "" ).split("/")[1] === "1624452064694" || 
    //   this.urlLink.replace( "/", "" ).split("/")[1] === "1505886422215" || 
    //   this.urlLink.replace( "/", "" ).split("/")[1] === "1631618554756" ||
    //   this.urlLink.replace( "/", "" ).split("/")[1] === "1644254145794"
    //   ){
      this.access = true;
    //   this.denied = false;
    // }else{
    //   this.access = false;
      // this.denied = true;
    // }
    
      this.redirectLinkActivity = "/activity";
      this.redirectLinkAlerts = "/alerts";
      this.redirectLinkVehicles = "/vehicles";
      this.redirectLinkDevices = "/devices";
      this.redirectLinkPeople = "/people";
      this.redirectLinkBespoke = "/bespoke";
      this.bespokeLink = "/bespoke";
      this.acessBespoke = "/bespoke-w";
      this.fuelType = "/fuel-type";
      this.export_csv = "/export-csv";
      // this.currentUrl = "/insights-dashboard";
  }
  access = false;
  denied = false;
}

