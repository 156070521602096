import { Component, Input, Output } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';


@Component({
  selector: 'app-call-alerts-resolvedbyuseremail',
  templateUrl: './call-alerts-resolvedbyuseremail.component.html',
  styleUrls: ['./call-alerts-resolvedbyuseremail.component.scss']
})
export class CallAlertsResolvedbyuseremailComponent {
  constructor(private cubejs: CubejsClient) {}
  @Input() set query(query: object) {
    this.loading = true;
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.data =[];
        this.dataSource = resultSet.tablePivot();
        for (let i = 0; i < this.dataSource.length; i++) {
          var nData =  this.dataSource[i];
          if( nData['Tasks.resolvedbyuseremail'] != null ){
            this.data.push( nData );
          }
        }
        this.dataSource = this.data;
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
  };
  @Input() limit: number;
  @Output() pageEvent = new EventEmitter();
  @Output() sortingChanged = new EventEmitter();
  loading = true;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25];
  dataSource = [];
  data = [];
  displayedColumns = ['Site', 'Count'];
  changeSorting(value) {
    this.sortingChanged.emit(value)
  }
}
