<mat-grid-list [cols]="3" rowHeight="59">
    <div>
        <mat-grid-tile>
            <div class="kpi-card">
                <mat-card class="dashboard-card not-normal-card">
                    <mat-card-header class="dashboard-card__header">
                    <mat-card-title>
                        <h3 class="kpi-title">Total Volume</h3>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="mat-card-content dashboard-card-content kpi-result">
                    <span>{{totalVolumn}}</span>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="kpi-card">
                <mat-card class="dashboard-card not-normal-card">
                    <mat-card-header class="dashboard-card__header">
                    <mat-card-title>
                        <h3 class="kpi-title">Petrol</h3>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result">
                    <span>
                        {{euro4Total}}
                    </span>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="kpi-card">
                <mat-card class="dashboard-card not-normal-card">
                    <mat-card-header class="dashboard-card__header">
                    <mat-card-title>
                        <h3 class="kpi-title">Diesel</h3>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result">
                    <span>
                        {{euro6Total}}
                    </span>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
        
        <mat-grid-tile class="other-cards">
            <div class="kpi-card">
                <mat-card class="dashboard-card not-normal-card">
                    <mat-card-header class="dashboard-card__header">
                    <mat-card-title>
                        <h3 class="kpi-title">Electric</h3>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result">
                    <span>{{elctCount}}</span>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
        <mat-grid-tile class="other-cards">
            <div class="kpi-card">
                <mat-card class="dashboard-card not-normal-card">
                    <mat-card-header class="dashboard-card__header">
                    <mat-card-title>
                        <h3 class="kpi-title">Hybrid</h3>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result">
                    <span>{{hybridCount}}</span>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
        <mat-grid-tile class="other-cards">
            <div class="kpi-card">
                <mat-card class="dashboard-card not-normal-card">
                    <mat-card-header class="dashboard-card__header">
                    <mat-card-title>
                        <h3 class="kpi-title">Gas BI-FUEL</h3>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result">
                    <span>{{GasCount}}</span>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
    </div>
</mat-grid-list>