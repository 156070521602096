import { Component, Input, Output, OnInit } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-euro-fuel-type6',
  templateUrl: './euro-fuel-type6.component.html',
  styleUrls: ['./euro-fuel-type6.component.scss']
})
export class EuroFuelType6Component {

  constructor(private cubejs: CubejsClient) {}
  dataSource = [];
  euro4Count = 0;
  euro4 = [];
  noneEuro4Count = 0;
  noneEuro4 = [];
  canvas: any;
  ctx: any;
  data;
  id;
  neweuro6Count = 0;
  newNoneEuro6Count = 0;
  total;
  @Input() set upToEuro6Count(upToEuro6Count: object) {
    this.newNoneEuro6Count = Number(upToEuro6Count);
    this.ngOnInit();
  }

  @Input() set euro6Plus(euro6Plus: object) {
    this.neweuro6Count = Number(euro6Plus);
    this.ngOnInit();
  }

  @Input() set query(query: object) {
    this.query = query;
  }

  ngOnInit() {
        this.euro4Count = 0;
        this.euro4 = [];
        this.noneEuro4 = [];
        this.total = this.neweuro6Count + this.newNoneEuro6Count;
        this.euro4Count = (this.neweuro6Count/this.total)*100;
        this.noneEuro4Count = (this.newNoneEuro6Count/this.total)*100;
        var lables = [this.euro4Count + "%", this.noneEuro4Count + "%"]
        this.euro4.push( this.euro4Count );
        this.noneEuro4.push( this.noneEuro4Count );
        if( this.noneEuro4.length > 0 ){
          this.loading = false;
        }
        
        this.data = {
          labels: [''],
          usePointStyle: true,
          datasets: [
          {
            label: 'Euro 6-',
            data: this.noneEuro4,
            backgroundColor: ['#b9c9d6'],
            borderWidth: 1
            },{
          label: 'Euro 6+',
          data: this.euro4,
          backgroundColor: ['#51789a'],
          borderWidth: 1
          }]
          }

        this.barChartOptions = {
          responsive: true,
          indexAxis: 'y',
          scales: {
            xAxes: [{
              stacked: true,
              ticks: {
                precision: 0
              }
            }],
            yAxes: [{
              stacked: true,
              display: false,
              ticks: {
                display: false
              }
            }]
          },
          tooltips: {
            enabled: true,
            callbacks: {
              label: (tooltipItem, data) => {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                let dataValue = dataset.data[tooltipItem.index] || 0;
                let label = dataset.label || '';
                
                return `${label}: ${dataValue.toFixed(2)}%`;
              }
            }
          },
          legend: {
            position: 'top',
            align: 'end',
            labels: {
              usePointStyle: true,
              fontColor: 'white'
            }
          },
          title: {
            display: false,
            text: 'Diesel'
          }
        };
        
          this.barChartLabels = [''];
          this.barChartType = 'bar';
          this.barChartLegend = true;
        
          this.barChartData = [
            { data: this.noneEuro4, label: 'Euro 6-', backgroundColor: '#b9c9d6', borderWidth: 1 },
            { data: this.euro4, label: 'Euro 6+', backgroundColor: '#51789a', borderWidth: 1 }
          ];
}
  myChart;
  public barChartOptions;
  public barChartData;
  public barChartLabels = [''];
  public barChartType = 'bar';
  public barChartLegend = true;
  loading = true;
  makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  
    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  
    return text;
  }
}
