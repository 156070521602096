<div class="example-loading-shade" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="custom-table">
  <div class="table-header-row">
    <div class="cell-wrap flex-fill">Vehicle</div>
    <div class="cell-wrap text-right" style="width: 80px;">Alert</div>
    <div class="cell-wrap text-right" style="width: 80px;">Activity</div>
  </div>
  <div class="table-inner-wrapper">
    <div class="table-row-wrapper">
      <div class="table-row">
        <div class="cell-wrap flex-fill">Enter</div>
        <div class="cell-wrap text-right" style="width: 80px;">{{VehiclesAlertsTotalCount}}</div>
        <div class="cell-wrap text-right" style="width: 80px;">{{VehiclesActivityTotalCount}}</div>
      </div>
    </div>
    <div class="table-row-wrapper">
      <div class="table-row">
        <div class="cell-wrap flex-fill">Exit</div>
        <div class="cell-wrap text-right" style="width: 80px;">{{VehiclesAlertsExitTotalCount}}</div>
        <div class="cell-wrap text-right" style="width: 80px;">{{VehiclesActivityExitTotalCount}}</div>
      </div>
    </div>
  </div>
</div>
<div class="custom-table">
  <div class="table-header-row">
    <div class="cell-wrap flex-fill">Device</div>
    <div class="cell-wrap text-right" style="width: 80px;">Alert</div>
    <div class="cell-wrap text-right" style="width: 80px;">Activity</div>
  </div>
  <div class="table-inner-wrapper">
    <div class="table-row-wrapper">
      <div class="table-row">
        <div class="cell-wrap flex-fill">Online</div>
        <div class="cell-wrap text-right" style="width: 80px;">{{DeviceAlertsEnterTotalCount}}</div>
        <div class="cell-wrap text-right" style="width: 80px;">{{DeviceActivityEnterTotalCount}}</div>
      </div>
    </div>
    <div class="table-row-wrapper">
      <div class="table-row">
        <div class="cell-wrap flex-fill">Offline</div>
        <div class="cell-wrap text-right" style="width: 80px;">{{DeviceAltersExitTotalCount}}</div>
        <div class="cell-wrap text-right" style="width: 80px;">{{DeviceActivityExitTotalCount}}</div>
      </div>
    </div>
  </div>
</div>
<div class="custom-table">
  <div class="table-header-row">
    <div class="cell-wrap flex-fill">People</div>
    <div class="cell-wrap text-right" style="width: 80px;">Alert</div>
    <div class="cell-wrap text-right" style="width: 80px;">Activity</div>
  </div>
  <div class="table-inner-wrapper">
    <div class="table-row-wrapper">
      <div class="table-row">
        <div class="cell-wrap flex-fill">Enter</div>
        <div class="cell-wrap text-right" style="width: 80px;">{{PeopleAlertsEnterTotalCount}}</div>
        <div class="cell-wrap text-right" style="width: 80px;">{{PeopleActivityEnterTotalCount}}</div>
      </div>
    </div>
    <div class="table-row-wrapper">
      <div class="table-row">
        <div class="cell-wrap flex-fill">Exit</div>
        <div class="cell-wrap text-right" style="width: 80px;">{{PeopleAltersExitTotalCount}}</div>
        <div class="cell-wrap text-right" style="width: 80px;">{{PeopleActivityExitTotalCount}}</div>
      </div>
    </div>
  </div>
</div>
