import { Component, Input,Output,OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { CubejsClient } from "@cubejs-client/ngx";

@Component({
  selector: 'app-insights-dashboard',
  templateUrl: './insights-dashboard.component.html',
  styleUrls: ['./insights-dashboard.component.scss']
})
export class InsightsDashboardComponent implements OnInit {
  constructor(private meta: Meta, private title: Title, private router: Router, private cubejs: CubejsClient) {
    this.title.setTitle('Angular Dashboard with Material');
    this.meta.addTag({ name: 'description', content: 'How to build Angular Material Data Table with Cube.js' });
    this.meta.addTag({ name: 'keywords', content: 'Angular, Cube.js, Data Table, Table, Material UI' });
  }
  public href: string = this.router.url.replace( "/", "" ).split("/")[1];
  public pageHref: string = this.router.url.replace( "/", "" ).split("/")[2];
  public urlLink;
  public thisUrl;
  public redirectLinkActivity;
  public redirectLinkAlerts;
  public redirectLinkVehicles;
  public redirectLinkDevices;
  public redirectLinkPeople;
  public redirectLinkBespoke;
  public redirectLinkPartners;
  public currentUrl;
  public currentMainUrl;
  updatePage(data){
    var merged = data.reduce((current, value, index) => {
      if(index > 0)
          current += '/';
      return current + value;
  }, '');
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([merged]);
  }
  ngOnInit(): void {
    this.urlLink = this.router.url;
    if( this.pageHref.includes("activity") ){
      this.currentUrl = this.thisUrl = "activity";
    }
    if( this.pageHref.includes("alerts") ){
      this.currentUrl = this.thisUrl = "alerts";
    }
    if( this.pageHref.includes("vehicles") ){
      this.currentUrl = this.thisUrl = "vehicles";
    }
    if( this.pageHref.includes("devices") ){
      this.currentUrl = this.thisUrl = "devices";
    }
    if( this.pageHref.includes("people") ){
      this.currentUrl = this.thisUrl = "people";
    }
    if( this.pageHref.includes("bespoke") ){
      this.currentUrl = this.thisUrl = "bespoke";
    }
    if(this.urlLink.includes("-")){
      this.redirectLinkActivity = "activity-s";
      this.redirectLinkAlerts = "alerts-s";
      this.redirectLinkVehicles = "vehicles-s";
      this.redirectLinkDevices = "devices-s";
      this.redirectLinkPeople = "people-s";
      this.redirectLinkBespoke = "bespoke-s";
      this.currentMainUrl = "/insights-dashboard-s";
    }else{
      this.redirectLinkActivity = "activity";
      this.redirectLinkAlerts = "alerts";
      this.redirectLinkVehicles = "vehicles";
      this.redirectLinkDevices = "devices";
      this.redirectLinkPeople = "people";
      this.redirectLinkBespoke = "bespoke";
      this.currentMainUrl = "/insights-dashboard";
    }
  }
  dataSource = [];
}
